import { CSSProperties, ChangeEvent } from "react"
import "../../../styles/input.css"
import { Stack } from "@mui/material"
import Text from "./Text"
import { colors } from "../../../services/config/colors"

const Input = ({
  value,
  onChange,
  placeholder,
  disabled = false,
  error = false,
  errorText,
  style,
  onBlur,
}: {
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  disabled?: boolean
  error?: boolean
  errorText?: string
  style?: CSSProperties
  onBlur?: () => void
}) => {
  return (
    <Stack>
      <input
        className={
          error
            ? "custom-input-error"
            : disabled
            ? "custom-input-disabled"
            : "custom-input"
        }
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        style={{
          width: "100%",
          height: 56,
          paddingInline: 12,
          borderRadius: 12,
          fontSize: 16,
          fontWeight: 500,
          ...style,
        }}
        onBlur={onBlur}
      />
      {error && (
        <Text
          fontSize={12}
          fontWeight={600}
          color={colors.error}
          style={{ marginTop: 6 }}
        >
          {errorText}
        </Text>
      )}
    </Stack>
  )
}

export default Input
