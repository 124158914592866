import { Grid, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import {
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import AppearFromSide from "../animations/AppearFromSide"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import { NewsContext } from "../../controllers/news"
import NewsCard from "./common/NewsCard"
import Appear from "../animations/Appear"

const NewsDesktop = () => {
  const { t } = useTranslation()
  const { windowHeight } = useContext(MainContext)
  const { news } = useContext(NewsContext)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight:
          windowHeight > 1000 ? `calc(100% - ${footerHeight}px)` : 1000,
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[{ label: t("news") }]}
        selectedItem={0}
        style={{ marginTop: 22 }}
      />
      {/* content */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
          }}
        >
          {/* header */}
          <Stack
            style={{
              marginTop: 22,
            }}
          >
            <AppearFromSide fade>
              <Title fontSize={36} lineHeight="46px" component="h1">
                {t("news")}
              </Title>
            </AppearFromSide>
            <AppearFromSide fade delay={0.05}>
              <Text
                fontSize={28}
                lineHeight="46px"
                fontWeight={400}
                style={{ marginTop: 8 }}
              >
                {t("news_description")}
              </Text>
            </AppearFromSide>
          </Stack>
          {/* news */}
          {news.length ? (
            <Grid
              container
              columns={3}
              columnSpacing={`${desktopColumnsGap}px`}
              rowSpacing="40px"
              style={{ marginTop: 0, marginBottom: 40 }}
            >
              {news.map((item, index) => (
                <Grid key={item.id} item xs={1}>
                  <Appear fade delay={0.05 * index}>
                    <NewsCard news={item} />
                  </Appear>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Text fontSize={18} fontWeight={400} style={{ marginTop: 40 }}>
              {t("news_empty_state")}
            </Text>
          )}
        </Stack>
      </PaddingContainerDesktop>
    </Stack>
  )
}

export default NewsDesktop
