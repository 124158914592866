import { Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import AppearFromSide from "../animations/AppearFromSide"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { parseStringDate, scrollWindowToTop } from "../../services/utils/utils"
import {
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import { BodySliceType } from "../../services/config/enum"
import { MainContext } from "../../controllers/main"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"
import News from "../../models/news"
import Text from "../global/common/Text"
import { NewsContext } from "../../controllers/news"
import NewsCard from "./common/NewsCard"
import Appear from "../animations/Appear"

const NewsDetailsDesktop = ({ currentNews }: { currentNews: News }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { windowHeight } = useContext(MainContext)
  const { news } = useContext(NewsContext)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight:
          windowHeight > 1200 ? `calc(100% - ${footerHeight}px)` : 1200,
        position: "relative",
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("news"),
            onClick: () => {
              scrollWindowToTop()
              navigate("/news")
            },
          },
          {
            label: currentNews.document.title,
          },
        ]}
        selectedItem={1}
        style={{ marginTop: 22, zIndex: 1 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
            marginTop: 22,
          }}
        >
          {/* header */}
          <Stack
            gap={2}
            style={{ marginTop: 16 }}
            key={currentNews.id + "header"}
          >
            <AppearFromSide fade>
              <Title component="h1" fontSize={28} lineHeight="46px">
                {currentNews.document.title}
              </Title>
            </AppearFromSide>
            <AppearFromSide fade delay={0.05}>
              <Text
                fontSize={18}
                lineHeight="18px"
                fontWeight={500}
                color={colors.primaryDark}
              >
                {parseStringDate(currentNews.startsAt)}
              </Text>
            </AppearFromSide>
          </Stack>
          {/* content */}
          <Stack
            direction="row"
            style={{
              width: "100%",
              marginTop: 60,
              gap: 120,
            }}
          >
            <AppearFromSide
              fade
              delay={0.1}
              style={{
                width: "57.14%",
                marginBottom: 58,
                position: "relative",
              }}
              key={currentNews.id + "content"}
            >
              <Stack
                style={{
                  width: "100%",
                  height: "auto",
                  gap: 40,
                }}
              >
                <img
                  src={currentNews.document.image}
                  style={{
                    width: "100%",
                    height: 375,
                    borderRadius: 10,
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                {currentNews.document.body && currentNews.document.body.length
                  ? currentNews.document.body.map((item, index) => {
                      if (item.sliceType === BodySliceType.BodyTextSlice) {
                        return (
                          <div
                            className="html"
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: item.text.split("_self").join("_blank"),
                            }}
                            style={{
                              fontSize: 20,
                              fontWeight: 300,
                              lineHeight: "32px",
                              color: colors.text,
                              overflow: "hidden",
                            }}
                          />
                        )
                      } else {
                        return null
                      }
                    })
                  : null}
              </Stack>
            </AppearFromSide>
            {news.filter((item) => item.id !== currentNews.id).length ? (
              <Stack
                style={{
                  width: "calc(42.86% - 120px)",
                  height: 200,
                  gap: 40,
                  position: "relative",
                }}
              >
                <Title
                  component="h2"
                  fontSize={26}
                  lineHeight="46px"
                  style={{ position: "absolute", top: -87 }}
                >
                  {t("recent_news")}
                </Title>
                {news
                  .filter((item) => item.id !== currentNews.id)
                  .slice(0, 2)
                  .map((item, index) => (
                    <Appear key={index} fade delay={0.05 * index}>
                      <NewsCard news={item} />
                    </Appear>
                  ))}
              </Stack>
            ) : null}
          </Stack>
        </Stack>
      </PaddingContainerDesktop>
      {/* background */}
      <div
        style={{
          width: "100%",
          maxWidth: 1920,
          height: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      ></div>
    </Stack>
  )
}

export default NewsDetailsDesktop
