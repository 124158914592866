import { Grid, Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import {
  desktopMaxWidth,
  footerHeight,
  maximumActionsPerTime,
  navBarHeight,
} from "../../services/config/constants"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { colors } from "../../services/config/colors"
import ActionDetailsBackgroundDesktop from "../../assets/svgComponents/ActionDetailsBackgroundDesktop"
import AppearFromSide from "../animations/AppearFromSide"
import Title from "../global/common/Title"
import CategoryFilterBadge from "./common/CategoryFilterBadge"
import Action from "../../models/action"
import { TagsContext } from "../../controllers/tags"
import ActionCardHorizontal from "./common/ActionCardHorizontal"
import FadeFromTop from "../animations/FadeFromTop"
import Button from "../global/common/Button"
import { ActionsContext } from "../../controllers/actions"
import Alert from "../global/common/Alert"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { Trans, useTranslation } from "react-i18next"
import { MissionPeriod, MissionType } from "../../services/config/enum"
import PointsBadge from "../global/common/PointsBadge"
import actMissionIconWhite from "../../assets/icons/missions/missionsTypes/act-white.svg"
import AlertInfo from "../global/common/AlertInfo"
import Text from "../global/common/Text"
import ActionsHelpCardDesktop from "./desktop/ActionsHelpCardDesktop"
import ActionLoggedFeedback from "../feedbacks/common/ActionLoggedFeedback"
import MissionCompletedFeedback from "../feedbacks/common/MissionCompletedFeedback"

const ActionsExploreDesktop = ({
  selectedCategory,
  filteredActionsList,
  noActionsAlertOpen,
  setNoActionsAlertOpen,
  hideFilters,
  logFeedbackOpen,
  missionFeedbackOpen,
}: {
  selectedCategory: string
  filteredActionsList: Action[]
  noActionsAlertOpen: boolean
  setNoActionsAlertOpen: Dispatch<SetStateAction<boolean>>
  hideFilters: boolean
  logFeedbackOpen: boolean
  missionFeedbackOpen: boolean
}) => {
  const { t } = useTranslation()
  const { windowHeight, currentMission, updatingMissions } =
    useContext(MainContext)
  const { categories } = useContext(TagsContext)
  const {
    selectedActions,
    logActions,
    setSelectedCategory,
    actionsPerTimeLimitAlertOpen,
    setActionsPerTimeLimitAlertOpen,
  } = useContext(ActionsContext)

  // info alerts
  const [whatAreMissionsAlertOpen, setWhatAreMissionsAlertOpen] =
    useState<boolean>(false)
  const [whyLogAlertOpen, setWhyLogAlertOpen] = useState<boolean>(false)

  return !currentMission ? null : (
    <Stack
      style={{
        width: "100%",
        minHeight:
          windowHeight > 1200 ? `calc(100% - ${footerHeight}px)` : 1200,
        position: "relative",
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("act_mission"),
          },
        ]}
        selectedItem={0}
        style={{ marginTop: 22, zIndex: 1 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
          }}
        >
          {/* header */}
          <AppearFromSide fade style={{ marginTop: 22 }}>
            <Stack direction="row" gap={1}>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: 40,
                  borderRadius: 4,
                  paddingInline: 10,
                  backgroundColor: colors.missions.act,
                }}
              >
                <img src={actMissionIconWhite} style={{ width: 23 }} alt="" />
                <Title
                  fontSize={18}
                  color={colors.textWhite}
                  style={{
                    fontVariant: "all-small-caps",
                    marginLeft: 8,
                    marginBottom: 2,
                  }}
                >
                  {t("act")}
                </Title>
                <Title
                  fontSize={18}
                  color={colors.textWhite}
                  style={{
                    fontVariant: "all-small-caps",
                    marginLeft: 18,
                    marginBottom: 2,
                  }}
                >
                  {currentMission.current}/{currentMission.target}
                </Title>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: 40,
                  borderRadius: 4,
                  paddingInline: 10,
                  border: "1.5px solid " + colors.tertiary,
                  backgroundColor: colors.backgroundWhite,
                }}
              >
                <Title fontSize={18} color={colors.tertiary}>
                  {currentMission.period === MissionPeriod.DAILY
                    ? t("today")
                    : currentMission.period === MissionPeriod.WEEKLY
                    ? t("week")
                    : t("special_singular")}
                </Title>
              </Stack>
              <PointsBadge points={currentMission.points} />
            </Stack>
          </AppearFromSide>
          <AppearFromSide fade delay={0.05} style={{ marginTop: 16 }}>
            <Title fontSize={28}>{currentMission.title}</Title>
          </AppearFromSide>
          {/* category filters */}
          {!hideFilters && (
            <Stack
              role="radiogroup"
              aria-label={t("filters")}
              direction="row"
              flexWrap="wrap"
              style={{
                width: "65.71%",
                maxWidth: "65.71%",
                gap: 12,
                rowGap: 12,
                marginTop: 50,
              }}
            >
              <CategoryFilterBadge
                category={{
                  id: "catDefault",
                  name: t("featured"),
                  backColor: colors.primary,
                  backTagColor: colors.primary,
                  foreColor: colors.text,
                }}
                selected={selectedCategory === "catDefault"}
                onClick={() => {
                  setSelectedCategory("catDefault")
                }}
              />
              {categories.map((category, index) => (
                <CategoryFilterBadge
                  key={category.id}
                  category={category}
                  selected={selectedCategory === category.id}
                  onClick={() => {
                    setSelectedCategory(category.id)
                  }}
                />
              ))}
            </Stack>
          )}
          {/* number of items for accessibility */}
          <Title
            key={selectedCategory}
            fontSize={16}
            lineHeight="22px"
            color={colors.primaryDark}
            style={{
              marginTop: hideFilters ? 50 : 22,
              textDecoration: "underline",
            }}
            ariaLive="polite"
          >
            {`${filteredActionsList.length} ${
              filteredActionsList.length === 1
                ? t("action").toLowerCase()
                : t("actions").toLowerCase()
            }`}
          </Title>
          {/* actions grid */}
          <Stack
            direction="row"
            style={{ width: "100%", position: "relative", marginTop: 22 }}
          >
            <Grid
              container
              columns={2}
              rowGap="16px"
              columnGap="30px"
              style={{ width: "65.71%" }}
            >
              {filteredActionsList.map((action, index) => (
                <Grid
                  item
                  zeroMinWidth
                  xs={1}
                  key={action.id + selectedCategory}
                  style={{ maxWidth: "calc(50% - 15px)" }}
                >
                  <FadeFromTop
                    key={action.id + selectedCategory}
                    delay={0.015 * index}
                  >
                    <ActionCardHorizontal
                      width="100%"
                      action={action}
                      showCategoryAndCo2
                    />
                  </FadeFromTop>
                </Grid>
              ))}
            </Grid>
            {/* help card */}
            <ActionsHelpCardDesktop
              setWhatAreMissionsAlertOpen={setWhatAreMissionsAlertOpen}
              setWhyLogAlertOpen={setWhyLogAlertOpen}
            />
          </Stack>
          {/* log button */}
          <FadeFromTop
            delay={0.05}
            style={{
              marginTop: 68,
              marginBottom: 76,
              width: "65.71%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              width="74%"
              loading={updatingMissions}
              onClick={() => {
                if (!selectedActions.length) {
                  setNoActionsAlertOpen(true)
                  return
                }

                logActions()
              }}
              title={
                updatingMissions
                  ? t("log")
                  : selectedActions.length === 0
                  ? t("log")
                  : selectedActions.length === 1
                  ? t("log_one_action")
                  : t("log_count_actions", { count: selectedActions.length })
              }
            >
              {updatingMissions
                ? t("log")
                : selectedActions.length === 0
                ? t("log")
                : selectedActions.length === 1
                ? t("log_one_action")
                : t("log_count_actions", { count: selectedActions.length })}
            </Button>
          </FadeFromTop>
        </Stack>
      </PaddingContainerDesktop>
      {/* background */}
      <div
        style={{
          width: "100%",
          maxWidth: 1920,
          height: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <ActionDetailsBackgroundDesktop
          color={colors.background}
          style={{ position: "absolute", top: 410 }}
        />
      </div>
      {/* alerts */}
      <Alert
        open={noActionsAlertOpen}
        title={t("no_actions_selected")}
        description={t("select_action_to_log")}
        primaryActionLabel={t("ok")}
        primaryActionOnClick={() => {
          setNoActionsAlertOpen(false)
        }}
      />
      <Alert
        open={actionsPerTimeLimitAlertOpen}
        title={t("warning")}
        description={t("actions_limit", { count: maximumActionsPerTime })}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setActionsPerTimeLimitAlertOpen(false)
        }}
      />
      <AlertInfo
        open={whatAreMissionsAlertOpen}
        setOpen={setWhatAreMissionsAlertOpen}
        title={t("what_are_act_missions")}
        description={
          <Text
            fontSize={18}
            lineHeight="25px"
            fontWeight={400}
            textAlign="center"
            style={{ marginTop: 20 }}
          >
            <Trans i18nKey="act_missions_description">
              Migliora il tuo stile di vita,{" "}
              <strong style={{ fontWeight: 500 }}>esplora</strong> le azioni
              suggerite e <strong style={{ fontWeight: 500 }}>registra</strong>{" "}
              quelle che hai fatto.
            </Trans>
          </Text>
        }
        descriptionAlign="center"
      />
      <AlertInfo
        open={whyLogAlertOpen}
        setOpen={setWhyLogAlertOpen}
        title={t("why_log_actions")}
        description={t("why_log_actions_description")}
        descriptionAlign="center"
      />
      <ActionLoggedFeedback open={logFeedbackOpen} plural />
      <MissionCompletedFeedback
        open={missionFeedbackOpen}
        missionType={MissionType.ACT}
      />
    </Stack>
  )
}

export default ActionsExploreDesktop
