import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import { useParams } from "react-router-dom"
import { MissionsContext } from "../../controllers/missions"
import AreaDetailsMobile from "../../components/checkIn/AreaDetailsMobile"
import AreaDetailsDesktop from "../../components/checkIn/AreaDetailsDesktop"
import CheckInMission from "../../models/checkInMission"
import { checkInAreas } from "../../services/config/checkInData"

const AreaDetails = () => {
  const { areaId } = useParams()
  const { isMobile } = useContext(MainContext)
  const { missions, completeCheckIn } = useContext(MissionsContext)

  // current checkin mission
  const checkInMission: CheckInMission = {
    ...missions.find((mission) => mission.missionTypeId === areaId)!,
    qrId: checkInAreas.find((area) => area.id === areaId)!.qrId,
    title: checkInAreas.find((area) => area.id === areaId)!.title,
    subtitle: checkInAreas.find((area) => area.id === areaId)!.subtitle,
    description: checkInAreas.find((area) => area.id === areaId)!.description,
    image: checkInAreas.find((area) => area.id === areaId)!.image,
    district: checkInAreas.find((area) => area.id === areaId)!.district,
  }

  // complete mission loading
  const [loading, setLoading] = useState<boolean>(false)

  // qr scan alert open
  const [qrScanOpen, setQrScanOpen] = useState<boolean>(false)

  // text got from qr code scan
  const [qrText, setQrText] = useState<string>("")

  // wrong checkInId alert
  const [wrongIdAlertOpen, setWrongIdAlertOpen] = useState<boolean>(false)

  // open your device camera alert
  const [cameraInfoAlertOpen, setCameraInfoAlertOpen] = useState<boolean>(false)

  // mission completed alert
  const [missionCompletedAlertOpen, setMissionCompletedAlertOpen] =
    useState<boolean>(false)

  // check qrText
  useEffect(() => {
    const callCompleteCheckIn = async (checkInId: string) => {
      setLoading(true)

      const result = await completeCheckIn(checkInId)

      setLoading(false)
      if (result) {
        setMissionCompletedAlertOpen(true)
      } else {
        setWrongIdAlertOpen(true)
      }
    }

    if (qrText) {
      const checkInId = qrText.includes("checkin_id")
        ? qrText.slice(qrText.indexOf("id=") + 3)
        : qrText.slice(qrText.lastIndexOf("/") + 1)

      if (
        checkInMission.missionTypeId === checkInId ||
        checkInMission.qrId === checkInId
      ) {
        callCompleteCheckIn(checkInMission.missionTypeId)
      } else {
        setWrongIdAlertOpen(true)
      }
    }
  }, [qrText])

  // scan locked alert
  const [scanLockedAlertOpen, setScanLockedAlertOpen] = useState<boolean>(false)

  return isMobile ? (
    <AreaDetailsMobile
      checkInMission={checkInMission}
      loading={loading}
      qrScanOpen={qrScanOpen}
      setQrScanOpen={setQrScanOpen}
      setQrText={setQrText}
      wrongIdAlertOpen={wrongIdAlertOpen}
      setWrongIdAlertOpen={setWrongIdAlertOpen}
      cameraInfoAlertOpen={cameraInfoAlertOpen}
      setCameraInfoAlertOpen={setCameraInfoAlertOpen}
      missionCompletedAlertOpen={missionCompletedAlertOpen}
      setMissionCompletedAlertOpen={setMissionCompletedAlertOpen}
      scanLockedAlertOpen={scanLockedAlertOpen}
      setScanLockedAlertOpen={setScanLockedAlertOpen}
    />
  ) : (
    <AreaDetailsDesktop
      checkInMission={checkInMission}
      loading={loading}
      qrScanOpen={qrScanOpen}
      setQrScanOpen={setQrScanOpen}
      setQrText={setQrText}
      wrongIdAlertOpen={wrongIdAlertOpen}
      setWrongIdAlertOpen={setWrongIdAlertOpen}
      missionCompletedAlertOpen={missionCompletedAlertOpen}
      setMissionCompletedAlertOpen={setMissionCompletedAlertOpen}
      scanLockedAlertOpen={scanLockedAlertOpen}
      setScanLockedAlertOpen={setScanLockedAlertOpen}
    />
  )
}

export default AreaDetails
