import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { deleteMethod, get } from "../services/api/api"
import User from "../models/user"
import { logger } from "../services/utils/utils"
import { MainContext } from "./main"

interface UsersContextInterface {
  leaderboardLoading: boolean
  leaderboardUpdating: boolean
  leaderboardError: boolean
  leaderboard: User[]
  leaderboardNextToken: string | null
  getLeaderboard: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
  deleteUser: () => Promise<boolean>
}

const UsersContext = createContext<UsersContextInterface>({
  leaderboardLoading: true,
  leaderboardUpdating: false,
  leaderboardError: false,
  leaderboard: [],
  leaderboardNextToken: null,
  getLeaderboard: async () => true,
  deleteUser: async () => true,
})

const UsersController = ({ children }: { children: ReactNode }) => {
  const { user } = useContext(MainContext)

  // loadings
  const [leaderboardLoading, setLeaderboardLoading] = useState<boolean>(true)
  const [leaderboardUpdating, setLeaderboardUpdating] = useState<boolean>(true)

  // errors
  const [leaderboardError, setLeaderboardError] = useState<boolean>(false)

  // states
  const [leaderboard, setLeaderboard] = useState<User[]>([])
  const [leaderboardNextToken, setLeaderboardNextToken] = useState<
    string | null
  >(null)

  // get leaderboard
  const getLeaderboard = async (withLoading = true, withNextToken = true) => {
    if (withLoading) {
      setLeaderboardLoading(true)
    }
    setLeaderboardUpdating(true)
    setLeaderboardError(false)

    try {
      const { data } = await get(
        leaderboardNextToken && withNextToken
          ? `/web/mission/point/leaderboard?nextToken=${leaderboardNextToken}`
          : "/web/mission/point/leaderboard"
      )

      if (leaderboardNextToken && withNextToken) {
        logger("leaderboard", [...leaderboard, ...data.items])
        setLeaderboard((current) => [...current, ...data.items])
      } else {
        logger("leaderboard", data.items)
        setLeaderboard(data.items)
      }
      setLeaderboardNextToken(data.nextToken)

      setLeaderboardLoading(false)
      setLeaderboardUpdating(false)

      return true
    } catch (e) {
      logger("leaderboard error", e)
      setLeaderboardLoading(false)
      setLeaderboardUpdating(false)
      // setLeaderboardError(true)

      return false
    }
  }

  // delete user
  const deleteUser = async () => {
    try {
      await deleteMethod("/web/user/delete")

      return true
    } catch (e) {
      logger("user delete error", e)

      return false
    }
  }

  // update user profile image in leaderboard on change
  useEffect(() => {
    if (leaderboard.some((item) => item.sub === user?.sub)) {
      if (
        leaderboard.find((item) => item.sub === user?.sub)?.profileImage !==
        user?.profileImage
      ) {
        leaderboard.find((item) => item.sub === user?.sub)!.profileImage =
          user!.profileImage
        setLeaderboard([...leaderboard])
      }
    }
  }, [user])

  // initial fetch
  useEffect(() => {
    getLeaderboard()
  }, [])

  return (
    <UsersContext.Provider
      value={{
        leaderboardLoading,
        leaderboardUpdating,
        leaderboardError,
        leaderboard,
        leaderboardNextToken,
        getLeaderboard,
        deleteUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}
export { UsersController, UsersContext }
