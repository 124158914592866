import { Stack } from "@mui/material"
import {
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import AppearFromSide from "../animations/AppearFromSide"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { colors } from "../../services/config/colors"
import Title from "../global/common/Title"
import { useTranslation } from "react-i18next"
import measureMissionIconWhite from "../../assets/icons/missions/missionsTypes/measure-white.svg"
import { Widget } from "@typeform/embed-react"
import { useContext, useMemo } from "react"
import { MainContext } from "../../controllers/main"
import { MissionPeriod, MissionType } from "../../services/config/enum"
import PointsBadge from "../global/common/PointsBadge"
import MissionCompletedFeedback from "../feedbacks/common/MissionCompletedFeedback"

const SurveyDesktop = ({
  currentFormId,
  onTypeformSubmit,
  missionFeedbackOpen,
}: {
  currentFormId: string
  onTypeformSubmit: () => void
  missionFeedbackOpen: boolean
}) => {
  const { t } = useTranslation()
  const { user, currentMission } = useContext(MainContext)

  // typeform
  const typeform = useMemo(() => {
    return (
      <div
        style={{
          width: "100%",
          height: 580,
          marginTop: 40,
          marginBottom: 58,
          position: "relative",
        }}
      >
        <Widget
          id={currentFormId}
          style={{
            width: "100%",
            height: "100%",
          }}
          onSubmit={onTypeformSubmit}
          hidden={{
            sub: user!.sub,
          }}
          inlineOnMobile
        />
        <div
          style={{
            width: "100%",
            height: 5,
            backgroundColor: colors.backgroundWhite,
            position: "absolute",
            top: 0,
          }}
        />
      </div>
    )
  }, [currentFormId])

  return !currentMission ? null : (
    <Stack
      style={{ width: "100%", minHeight: `calc(100% - ${footerHeight}px)` }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("survey_mission"),
          },
        ]}
        selectedItem={0}
        style={{ marginTop: 22 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            marginTop: 22,
          }}
        >
          {/* header */}
          <AppearFromSide fade>
            <Stack direction="row" gap={1}>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: 40,
                  borderRadius: 4,
                  paddingInline: 10,
                  backgroundColor: colors.missions.measure,
                }}
              >
                <img
                  src={measureMissionIconWhite}
                  style={{ width: 20 }}
                  alt=""
                />
                <Title
                  fontSize={18}
                  color={colors.textWhite}
                  style={{
                    fontVariant: "all-small-caps",
                    marginLeft: 8,
                    marginBottom: 2,
                  }}
                >
                  {t("survey").toUpperCase()}
                </Title>
                <Title
                  fontSize={18}
                  color={colors.textWhite}
                  style={{
                    fontVariant: "all-small-caps",
                    marginLeft: 18,
                    marginBottom: 2,
                  }}
                >
                  {currentMission.current}/{currentMission.target}
                </Title>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: 40,
                  borderRadius: 4,
                  paddingInline: 10,
                  border: "1.5px solid " + colors.tertiary,
                  backgroundColor: colors.backgroundWhite,
                }}
              >
                <Title fontSize={18} color={colors.tertiary}>
                  {currentMission.period === MissionPeriod.DAILY
                    ? t("today")
                    : currentMission.period === MissionPeriod.WEEKLY
                    ? t("week")
                    : t("special_singular")}
                </Title>
              </Stack>
              <PointsBadge points={currentMission.points} />
            </Stack>
          </AppearFromSide>
          <AppearFromSide fade delay={0.05} style={{ marginTop: 16 }}>
            <Title fontSize={28}>{currentMission.title}</Title>
          </AppearFromSide>
          {/* typeform */}
          {typeform}
        </Stack>
      </PaddingContainerDesktop>
      <MissionCompletedFeedback
        open={missionFeedbackOpen}
        missionType={MissionType.SURVEY}
      />
    </Stack>
  )
}

export default SurveyDesktop
