import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import {
  defaultBoxShadow,
  defaultBoxShadowDarker,
} from "../../../services/config/constants"
import PointsBadge from "../../global/common/PointsBadge"
import Title from "../../global/common/Title"
import { useNavigate } from "react-router-dom"
import { CSSProperties, useContext } from "react"
import CheckInMission from "../../../models/checkInMission"
import { MainContext } from "../../../controllers/main"
import { scrollWindowToTop } from "../../../services/utils/utils"

const AreaCard = ({
  checkInMission,
  variant = "normal",
  completed,
  style,
}: {
  checkInMission: CheckInMission
  variant?: "normal" | "expanded"
  completed?: boolean
  style?: CSSProperties
}) => {
  const navigate = useNavigate()
  const { isMobile } = useContext(MainContext)

  return (
    <ButtonBase
      disabled={variant !== "normal"}
      aria-label={checkInMission.title}
      style={{
        width:
          (variant === "expanded" && completed) || variant === "expanded"
            ? "calc(100% - 32px)"
            : "100%",
        borderRadius: 10,
        ...style,
      }}
      onClick={() => {
        scrollWindowToTop()
        navigate(`/event/${checkInMission.missionTypeId}`)
      }}
    >
      <Stack
        style={{
          width: "100%",
          height: !isMobile
            ? 130
            : variant === "expanded" || completed
            ? 112
            : 130,
          padding: 14,
          paddingBottom: isMobile ? 10 : 14,
          borderRadius: 10,
          backgroundColor: colors.backgroundWhite,
          boxShadow:
            variant === "expanded" ? defaultBoxShadowDarker : defaultBoxShadow,
          position: "relative",
        }}
      >
        <img
          src={checkInMission.image}
          style={{
            width: isMobile ? 32 : 40,
            height: isMobile ? 32 : 40,
            borderRadius: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt=""
        />
        <Title
          fontSize={isMobile ? 16 : 18}
          lineHeight={isMobile ? "22px" : "25px"}
          style={{
            marginTop: isMobile ? 8 : 6,
            height: isMobile ? 44 : 50,
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {checkInMission.subtitle}
        </Title>
        <PointsBadge
          points={checkInMission.points}
          style={{ position: "absolute", top: 14, right: 14 }}
        />
      </Stack>
    </ButtonBase>
  )
}

export default AreaCard
