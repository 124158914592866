import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import partnerLogo from "../../assets/images/partner-logo.png"
import awLogoVertical from "../../assets/images/aw-logo-vertical.png"
import { Dispatch, SetStateAction, useContext, useRef, useState } from "react"
import { MainContext } from "../../controllers/main"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import Button from "../global/common/Button"
import { Trans } from "react-i18next"
import Alert from "../global/common/Alert"
import FadeFromTop from "../animations/FadeFromTop"
import AlertFullScreen from "../global/common/AlertFullScreen"
import {
  privacyPolicyIT,
  privacyPolicyEN,
} from "../../services/data/privacyPolicy"
import { focusElement, isEmailValid } from "../../services/utils/utils"
import { onboardingElementsMaxWidth } from "../../services/config/constants"
import i18next, { t } from "i18next"
import Input from "../global/common/Input"
import ConsentCard from "./common/ConsentCard"
import OTPInput from "react-otp-input"
import {
  contestRulesIT,
  contestRulesEN,
} from "../../services/data/contestRules"
import { useSearchParams } from "react-router-dom"
import MetaPixelEvent from "../global/common/MetaPixelEvent"

const AmplifySignInMobile = ({
  loading,
  setLoading,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  emailError,
  setEmailError,
  consents,
  setConsents,
  code,
  setCode,
  codeErrorAlertOpen,
  setCodeErrorAlertOpen,
  callConfirmCode,
  signInAlertOpen,
  height,
  continueButtonWidth,
}: {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  firstName: string
  setFirstName: Dispatch<SetStateAction<string>>
  lastName: string
  setLastName: Dispatch<SetStateAction<string>>
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  emailError: boolean
  setEmailError: Dispatch<SetStateAction<boolean>>
  consents: boolean[]
  setConsents: Dispatch<SetStateAction<boolean[]>>
  code: string
  setCode: Dispatch<SetStateAction<string>>
  codeErrorAlertOpen: boolean
  setCodeErrorAlertOpen: Dispatch<SetStateAction<boolean>>
  callConfirmCode: () => void
  signInAlertOpen: boolean
  height?: number | string
  continueButtonWidth?: number
}) => {
  const [searchParams] = useSearchParams()
  const {
    windowHeight,
    isMobile,
    sendCode,
    setViewOnboarding,
    user,
    fromApp,
    appSignIn,
    getUserInfo,
    setViewAmplifySignIn,
  } = useContext(MainContext)

  // current page
  const [currentPage, setCurrentPage] = useState<number>(0)

  // can click continue button
  const canClick = useRef<boolean>(true)

  // something is missing alert
  const [missingAlertOpen, setMissingAlertOpen] = useState<boolean>(false)

  // privacy and contest alerts
  const [privacyAlertOpen, setPrivacyAlertOpen] = useState<boolean>(false)
  const [contestAlertOpen, setContestAlertOpen] = useState<boolean>(false)

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  return (
    <Stack
      alignItems="center"
      className="hide-scrollbars"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundColor: colors.backgroundWhite,
        position: "relative",
        overflowY: "scroll",
      }}
    >
      {/* content */}
      <Stack
        id="onboarding-container"
        direction="row"
        style={{
          width: "100%",
          maxWidth: "100%",
          height: fromApp ? 750 : currentPage === 0 ? 832 : 570,
          minHeight: fromApp ? 750 : currentPage === 0 ? 832 : 570,
          overflow: "hidden",
          paddingTop: 26,
        }}
        tabIndex={0}
      >
        {/* first page */}
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: 16,
            position: "relative",
          }}
          alignItems="center"
          aria-hidden={currentPage === 0 ? "false" : "true"}
        >
          {/* header */}
          <LogosHeader />
          <FadeFromTop style={{ marginTop: 28 }}>
            <Title
              fontSize={isMobile ? 26 : 28}
              textAlign="center"
              component="h1"
              style={{ maxWidth: 300 }}
            >
              {t("sign_in")}
            </Title>
          </FadeFromTop>
          <FadeFromTop delay={0.05} style={{ marginTop: 12 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              textAlign="center"
              fontWeight={500}
              component="h2"
            >
              {t("insert_your_details")}
            </Text>
          </FadeFromTop>
          <FadeFromTop
            delay={0.05}
            style={{
              width: "100%",
              maxWidth: onboardingElementsMaxWidth,
              marginTop: 35,
            }}
          >
            <Title fontSize={14} lineHeight="18px">
              *{t("mandatory")}
            </Title>
          </FadeFromTop>
          <FadeFromTop
            delay={0.1}
            style={{
              width: "100%",
              maxWidth: onboardingElementsMaxWidth,
              marginTop: 20,
            }}
          >
            <Stack
              style={{
                width: "100%",
                gap: 14,
              }}
            >
              {!fromApp && (
                <Stack
                  style={{
                    width: "100%",
                    gap: 14,
                  }}
                >
                  <Input
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value)
                    }}
                    placeholder={t("first_name") + "*"}
                    disabled={loading}
                  />
                  <Input
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value)
                    }}
                    placeholder={t("last_name") + "*"}
                    disabled={loading}
                  />
                  <Input
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)

                      // error check
                      if (isEmailValid(e.target.value) || !e.target.value) {
                        setEmailError(false)
                      }
                    }}
                    placeholder={t("email") + "*"}
                    disabled={loading}
                    error={emailError}
                    errorText={t("insert_a_valid_email")}
                    onBlur={() => {
                      if (isEmailValid(email) || !email) {
                        setEmailError(false)
                      } else {
                        setEmailError(true)
                      }
                    }}
                  />
                </Stack>
              )}
              <ConsentCard
                title={t("authorization") + "*"}
                description={
                  <Trans i18nKey="at_least_18">
                    Dichiaro di avere{" "}
                    <strong
                      style={{
                        color: colors.primaryDark,
                        textDecoration: "underline",
                      }}
                    >
                      almeno 18 anni
                    </strong>
                  </Trans>
                }
                checked={consents[0]}
                onChange={() => {
                  consents[0] = !consents[0]
                  setConsents([...consents])
                }}
                disabled={loading}
                style={{ marginTop: fromApp ? 0 : 12 }}
              />
              <ConsentCard
                title={t("privacy_policy") + "*"}
                description={
                  <Trans i18nKey="privacy_policy_description">
                    Dichiaro di aver letto e compreso l’
                    <strong
                      style={{
                        color: colors.primaryDark,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      role="button"
                      onClick={() => {
                        setPrivacyAlertOpen(true)
                      }}
                    >
                      informativa privacy
                    </strong>
                  </Trans>
                }
                checked={consents[1]}
                onChange={() => {
                  consents[1] = !consents[1]
                  setConsents([...consents])
                }}
                disabled={loading}
              />
              {/* <ConsentCard
                title={t("contest_rules") + "*"}
                description={
                  <Trans i18nKey="contest_rules_description">
                    Dichiaro di aver letto e compreso il{" "}
                    <strong
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setContestAlertOpen(true)
                      }}
                      style={{
                        color: colors.primaryDark,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      regolamento del concorso
                    </strong>{" "}
                    e che la partecipazione allo stesso prevede la creazione di
                    un account AWorld
                  </Trans>
                }
                checked={consents[2]}
                onChange={() => {
                  consents[2] = !consents[2]
                  setConsents([...consents])
                }}
                disabled={loading}
              /> */}
            </Stack>
          </FadeFromTop>
        </Stack>
        {/* second page */}
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: 16,
            position: "relative",
          }}
          alignItems="center"
          aria-hidden={currentPage === 1 ? "false" : "true"}
        >
          <MetaPixelEvent lead />
          {/* header */}
          <LogosHeader />
          <FadeFromTop style={{ marginTop: 28 }}>
            <Title
              fontSize={isMobile ? 26 : 28}
              textAlign="center"
              component="h1"
              style={{ maxWidth: 300 }}
            >
              {t("check_your_email")}
            </Title>
          </FadeFromTop>
          <FadeFromTop delay={0.05} style={{ marginTop: 12 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              textAlign="center"
              fontWeight={500}
              component="h2"
            >
              {t("insert_code", { email: email })}
            </Text>
          </FadeFromTop>
          <FadeFromTop delay={0.1} style={{ width: "100%", marginTop: 60 }}>
            <Stack alignItems="center">
              <Text fontSize={16} fontWeight={400}>
                {t("verification_code")}
              </Text>
              <div
                style={{
                  width: "100%",
                  marginTop: 24,
                  maxWidth: onboardingElementsMaxWidth - 100,
                }}
              >
                <OTPInput
                  value={code}
                  onChange={setCode}
                  numInputs={6}
                  renderSeparator={<span style={{ minWidth: 16 }} />}
                  renderInput={(props) => (
                    <input
                      {...props}
                      disabled={loading}
                      style={{
                        textAlign: "center",
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    />
                  )}
                  inputType="number"
                  shouldAutoFocus={currentPage === 1}
                  inputStyle={loading ? "otp-input-disabled" : "otp-input"}
                  containerStyle={{
                    width: "100%",
                  }}
                />
              </div>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                style={{ marginTop: 25 }}
              >
                <Text fontSize={16} fontWeight={400}>
                  {t("did_not_receive_the_code")}
                </Text>
                <ButtonBase
                  aria-label={t("send_again")}
                  disableRipple
                  disabled={loading}
                  onClick={async () => {
                    setLoading(true)

                    await sendCode(firstName, lastName, email)

                    setLoading(false)
                  }}
                >
                  <Text
                    fontSize={16}
                    fontWeight={700}
                    color={colors.primaryDark}
                    style={{ opacity: loading ? 0.4 : 1, transition: "200ms" }}
                  >
                    {t("send_again")}
                  </Text>
                </ButtonBase>
              </Stack>
            </Stack>
          </FadeFromTop>
        </Stack>
      </Stack>
      {/* continue and go back buttons */}
      <Button
        loading={loading}
        title={t("continue")}
        style={{
          width: continueButtonWidth ?? "calc(100% - 32px)",
          position: "fixed",
          bottom: 90,
          display: "flex",
          justifyContent: "center",
          transition: "bottom 200ms",
        }}
        onClick={async () => {
          if (canClick.current) {
            if (
              currentPage === 0 &&
              fromApp &&
              consents.some((item) => !item)
            ) {
              setMissingAlertOpen(true)
              return
            } else if (
              currentPage === 0 &&
              !fromApp &&
              (consents.some((item) => !item) ||
                !firstName ||
                !lastName ||
                !email ||
                !isEmailValid(email))
            ) {
              setMissingAlertOpen(true)
              return
            } else if (currentPage === 0 && fromApp) {
              setLoading(true)

              const key = searchParams.get("key")!
              const iv = searchParams.get("iv")!

              // app signin
              await appSignIn(key, iv)

              // get current user info
              await getUserInfo()

              setViewAmplifySignIn(false)

              setLoading(false)
            } else if (currentPage === 0) {
              setLoading(true)

              await sendCode(firstName, lastName, email)

              setLoading(false)
            } else if (currentPage === 1) {
              callConfirmCode()
              return
            }

            const container = document.getElementById("onboarding-container")
            container?.scrollTo({
              left: window.innerWidth * (currentPage + 1),
              behavior: "auto",
            })
            setCurrentPage((current) => (current += 1))

            if (screenReaderEnabled.current) {
              focusElement("onboarding-container")
            }

            canClick.current = false
            setTimeout(() => {
              canClick.current = true
            }, 500)
          }
        }}
        onFocusVisible={() => {
          screenReaderEnabled.current = true
        }}
        onBlur={() => {
          screenReaderEnabled.current = false
        }}
      >
        {t("continue")}
      </Button>
      <Button
        disabled={loading}
        title={t("go_back")}
        outlined
        style={{
          width: continueButtonWidth ?? "calc(100% - 32px)",
          position: "fixed",
          bottom: 22,
          display: "flex",
          justifyContent: "center",
          transition: "bottom 200ms",
        }}
        onClick={() => {
          if (canClick.current) {
            if (currentPage === 0) {
              localStorage.clear()
              setViewOnboarding(true)
              return
            }

            const container = document.getElementById("onboarding-container")
            container?.scrollTo({
              left: window.innerWidth * (currentPage - 1),
              behavior: "auto",
            })
            setCurrentPage((current) => (current -= 1))

            if (screenReaderEnabled.current) {
              focusElement("onboarding-container")
            }

            canClick.current = false
            setTimeout(() => {
              canClick.current = true
            }, 500)
          }
        }}
        onFocusVisible={() => {
          screenReaderEnabled.current = true
        }}
        onBlur={() => {
          screenReaderEnabled.current = false
        }}
      >
        {t("go_back")}
      </Button>
      {/* missing alert */}
      <Alert
        open={missingAlertOpen}
        title={t("something_is_missing")}
        description={
          fromApp ? t("consents_warning_short") : t("consents_warning")
        }
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setMissingAlertOpen(false)
        }}
      />
      {/* full screen alerts for privacy policy and contest rules */}
      <AlertFullScreen
        open={privacyAlertOpen}
        setOpen={setPrivacyAlertOpen}
        content={i18next.language === "it" ? privacyPolicyIT : privacyPolicyEN}
      />
      <AlertFullScreen
        open={contestAlertOpen}
        setOpen={setContestAlertOpen}
        content={i18next.language === "it" ? contestRulesIT : contestRulesEN}
      />
      {/* wrong code alert */}
      <Alert
        open={codeErrorAlertOpen}
        title={t("error_title")}
        description={t("wrong_code")}
        primaryActionLabel={t("ok")}
        primaryActionOnClick={() => {
          setCodeErrorAlertOpen(false)
        }}
      />
      {/* signin error alert */}
      <Alert
        open={signInAlertOpen}
        title={t("error_title")}
        description={t("account_does_not_exist")}
        primaryActionLabel={t("go_back")}
        primaryActionOnClick={() => {
          localStorage.clear()
          setViewOnboarding(true)
        }}
      />
    </Stack>
  )
}

const LogosHeader = () => {
  return (
    <Stack direction="row" alignItems="center" gap={3}>
      <img
        src={partnerLogo}
        style={{ height: 42 }}
        alt={t("partner_logo_alt")}
      />
      <img
        src={awLogoVertical}
        style={{ height: 42 }}
        alt={t("aworld_logo_alt")}
      />
    </Stack>
  )
}

export default AmplifySignInMobile
