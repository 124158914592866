import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { MainContext } from "../../controllers/main"
import ActionsExploreMobile from "../../components/actions/ActionsExploreMobile"
import ActionsExploreDesktop from "../../components/actions/ActionsExploreDesktop"
import { ActionsContext } from "../../controllers/actions"
import { TagsContext } from "../../controllers/tags"
import { useNavigate } from "react-router-dom"
import {
  capitalizeFirstCharacter,
  registerPageEvent,
} from "../../services/utils/utils"
import { MissionSubType } from "../../services/config/enum"
import MetaPixelEvent from "../../components/global/common/MetaPixelEvent"

const ActionsExplore = () => {
  const navigate = useNavigate()
  const { isMobile, currentMission } = useContext(MainContext)
  const { actions, selectedCategory, setSelectedCategory } =
    useContext(ActionsContext)
  const { categories } = useContext(TagsContext)

  // should hide or not filters
  const [hideFilters, setHideFilters] = useState<boolean>(false)

  // redirect to landing if currentMission isn't present
  useEffect(() => {
    if (!currentMission) {
      navigate("/")
    } else if (
      currentMission.missionSubType !== MissionSubType.ALL &&
      currentMission.missionSubType !== MissionSubType.FIXED
    ) {
      setHideFilters(true)
      if (currentMission.missionSubType === MissionSubType.SOCIAL) {
        setSelectedCategory("catSociety")
      } else if (currentMission.missionSubType === MissionSubType.ENVIRONMENT) {
        setSelectedCategory("catWellness")
      } else {
        setSelectedCategory(
          "cat" +
            capitalizeFirstCharacter(
              currentMission.missionSubType.toLowerCase()
            )
        )
      }
    } else {
      setSelectedCategory("catDefault")
    }
  }, [])

  // register google analytics event
  useEffect(() => {
    registerPageEvent("actions_explore")
  }, [])

  // filtered actions list by category
  const filteredActionsList = useMemo(() => {
    if (selectedCategory === "catDefault") {
      return actions.slice(0, 8)
    }

    return actions.filter(
      (action) =>
        action.category.id ===
        categories.find((category) => category.id === selectedCategory)!.id
    )
  }, [selectedCategory, actions])

  // no selected actions alert
  const [noActionsAlertOpen, setNoActionsAlertOpen] = useState<boolean>(false)

  // action logged or mission completed feedbacks
  const [logFeedbackOpen, setLogFeedbackOpen] = useState<boolean>(false)
  const [missionFeedbackOpen, setMissionFeedbackOpen] = useState<boolean>(false)

  // check if currentMission changed to show feedback
  const isFirstRender = useRef<boolean>(true)

  useEffect(() => {
    if (!isFirstRender.current && currentMission) {
      if (currentMission.satisfied) {
        setMissionFeedbackOpen(true)
      } else {
        setLogFeedbackOpen(true)
      }
    } else {
      isFirstRender.current = false
    }
  }, [currentMission])

  return isMobile ? (
    <>
      <MetaPixelEvent page="ActionsExplore" />
      <ActionsExploreMobile
        selectedCategory={selectedCategory}
        filteredActionsList={filteredActionsList}
        noActionsAlertOpen={noActionsAlertOpen}
        setNoActionsAlertOpen={setNoActionsAlertOpen}
        hideFilters={hideFilters}
        logFeedbackOpen={logFeedbackOpen}
        missionFeedbackOpen={missionFeedbackOpen}
      />
    </>
  ) : (
    <>
      <MetaPixelEvent page="ActionsExplore" />
      <ActionsExploreDesktop
        selectedCategory={selectedCategory}
        filteredActionsList={filteredActionsList}
        noActionsAlertOpen={noActionsAlertOpen}
        setNoActionsAlertOpen={setNoActionsAlertOpen}
        hideFilters={hideFilters}
        logFeedbackOpen={logFeedbackOpen}
        missionFeedbackOpen={missionFeedbackOpen}
      />
    </>
  )
}

export default ActionsExplore
