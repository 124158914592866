import { ButtonBase } from "@mui/material"
import { useContext } from "react"
import { Textfit } from "react-textfit"
import { JourneysContext } from "../../../../controllers/journeys"
import QuizSlide from "../../../../models/slides/quizSlide"
import {
  numberOfColumns,
  numberOfRows,
  slideHeight,
  slideWidth,
} from "../../../../services/config/constants"
import closeCircleRedIcon from "../../../../assets/icons/close-circle-red.png"
import checkCircleGreenIcon from "../../../../assets/icons/check-circle-green.png"
import { colors } from "../../../../services/config/colors"
import Episode from "../../../../models/episode"
import QuizResult from "../../../../models/quizResult"
import { useTranslation } from "react-i18next"

const Quiz3Layout = ({
  slide,
  currentEpisode,
  isDailyEpisode,
  isLastEpisode,
  quizResult,
}: {
  slide: QuizSlide
  currentEpisode: Episode
  isDailyEpisode: boolean
  isLastEpisode: boolean
  quizResult?: QuizResult
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${slide.background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: slideWidth,
          maxWidth: slideWidth,
          height: slideHeight,
          maxHeight: slideHeight,
          position: "relative",
        }}
      >
        <Box slide={slide} />
        <Option
          currentEpisode={currentEpisode}
          slide={slide}
          option="opt1"
          top={`calc(${slideHeight}px / ${numberOfRows} * 24)`}
          quizResult={quizResult}
          isDailyEpisode={isDailyEpisode}
          isLastEpisode={isLastEpisode}
        />
        <Option
          currentEpisode={currentEpisode}
          slide={slide}
          option="opt2"
          top={`calc(${slideHeight}px / ${numberOfRows} * 28)`}
          quizResult={quizResult}
          isDailyEpisode={isDailyEpisode}
          isLastEpisode={isLastEpisode}
        />
        {slide.opt3 && slide.opt3.text ? (
          <Option
            currentEpisode={currentEpisode}
            slide={slide}
            option="opt3"
            top={`calc(${slideHeight}px / ${numberOfRows} * 32)`}
            quizResult={quizResult}
            isDailyEpisode={isDailyEpisode}
            isLastEpisode={isLastEpisode}
          />
        ) : null}
      </div>
      <Decor slide={slide} />
    </div>
  )
}

const Box = ({ slide }: { slide: QuizSlide }) => {
  return (
    <div
      style={{
        backgroundColor: slide.box ? slide.box.boxBackColor : undefined,
        bottom: `calc(${slideHeight}px / ${numberOfRows} * 20)`,
        position: "absolute",
        top: `calc(${slideHeight}px / ${numberOfRows} * 12)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Textfit
        style={{
          color: slide.textColor,
          lineHeight: 1.3,
          fontWeight: 600,
          paddingLeft: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          paddingRight: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          marginTop: `calc(${slideHeight}px / ${numberOfRows} * 1.5)`,
          height: `calc(${slideHeight}px / ${numberOfRows} * 5)`,
          width: "100%",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
        }}
        min={19}
        max={80}
        mode="multi"
      >
        <div dangerouslySetInnerHTML={{ __html: slide.question }} />
      </Textfit>
    </div>
  )
}

const Option = ({
  currentEpisode,
  slide,
  option,
  top,
  quizResult,
  isDailyEpisode,
  isLastEpisode,
}: {
  currentEpisode: Episode
  slide: QuizSlide
  option: "opt1" | "opt2" | "opt3"
  top: string
  quizResult?: {
    opt1: number
    opt2: number
    opt3: number
    opt4: number
    outcome: string
    slideId: string
  }
  isDailyEpisode: boolean
  isLastEpisode: boolean
}) => {
  const { t } = useTranslation()
  const { submitSurvey } = useContext(JourneysContext)

  return (
    <ButtonBase
      disabled={quizResult !== undefined}
      style={{
        backgroundColor:
          quizResult && slide.correctAnswer === option
            ? colors.success
            : quizResult && quizResult[option] && slide.correctAnswer !== option
            ? colors.error
            : slide.box
            ? slide.box.boxBackColor
            : undefined,
        position: "absolute",
        top: top,
        height: `calc(${slideHeight}px / ${numberOfRows} * 3)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 2,
      }}
      onClick={() => {
        submitSurvey(
          currentEpisode,
          option,
          slide,
          isDailyEpisode,
          isLastEpisode
        )
      }}
      aria-label={
        quizResult && quizResult[option] && slide.correctAnswer === option
          ? t("your_correct_answer") + slide[option]!.text
          : quizResult && quizResult[option]
          ? t("your_incorrect_answer") + slide[option]!.text
          : quizResult && slide.correctAnswer === option
          ? t("correct_answer") + slide[option]!.text
          : slide[option]!.text
      }
      aria-live={quizResult && quizResult[option] ? "assertive" : "off"}
    >
      {quizResult && slide.correctAnswer === option ? (
        <img
          src={checkCircleGreenIcon}
          style={{ position: "absolute", right: 15, width: 20, height: 20 }}
          alt=""
        />
      ) : null}
      {quizResult && quizResult[option] && slide.correctAnswer !== option ? (
        <img
          src={closeCircleRedIcon}
          style={{ position: "absolute", right: 15, width: 20, height: 20 }}
          alt=""
        />
      ) : null}
      <Textfit
        style={{
          color:
            (quizResult && slide.correctAnswer === option) ||
            (quizResult && quizResult[option] && slide.correctAnswer !== option)
              ? colors.textWhite
              : slide.textColor,
          lineHeight: 1.3,
          fontWeight: 400,
          height: `calc(${slideHeight}px / ${numberOfRows} * 2.5)`,
          width: `calc(${slideWidth}px / ${numberOfColumns} * 10)`,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        min={14}
        max={20}
        mode="multi"
      >
        {slide[option]!.text}
      </Textfit>
    </ButtonBase>
  )
}

const Decor = ({ slide }: { slide: QuizSlide }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        position: "absolute",
        top: 0,
        paddingBottom: 300,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          position: "absolute",
          right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          height: `calc(${slideHeight}px / ${numberOfRows} * 6)`,
          backgroundImage: `url(${slide.decor})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </div>
  )
}

export default Quiz3Layout
