export const colors = {
  primary: "#62B0E7", // urban lab color
  primaryDark: "#0E66A8",
  secondary: "#D02963", // same as missions => act
  tertiary: "#DE5571",
  disabled: "#CAE4F7", // urban lab color + 50% white
  background: "#E6EFF6", // urban lab background
  backgroundLight: "#F2F3F4", // Japanese Indigo + 95% white
  backgroundGray: "#EDE9E8", // 100% white
  backgroundWhite: "#FFFFFF", // 100% white
  stroke: "#7D8D96", // Japanese Indigo + 40% white
  text: "#000000", // Japanese Indigo
  textDisabled: "#B3B3B3", // Japanese Indigo
  textSecondary: "#7A8A9C", // Japanese Indigo + 20% white
  textWhite: "#FFFFFF", // 100% white
  metrics: {
    co2: "#759357",
    water: "#5D82CA",
    energy: "#CE9256",
  },
  missions: {
    act: "#14856F",
    learn: "#D02963",
    measure: "#54428E",
    checkin: "#54428E",
    survey: "#54428E",
  },
  success: "#008000",
  error: "#D02929",
}
