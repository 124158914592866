import { useContext, useEffect } from "react"
import PerformanceMobile from "../../components/performance/PerformanceMobile"
import { MainContext } from "../../controllers/main"
import PerformanceDesktop from "../../components/performance/PerformanceDesktop"
import { registerPageEvent } from "../../services/utils/utils"
import MetaPixelEvent from "../../components/global/common/MetaPixelEvent"

const Performance = () => {
  const { isMobile } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("profile")
  }, [])

  return isMobile ? (
    <>
      <MetaPixelEvent page="Profile" />
      <PerformanceMobile />
    </>
  ) : (
    <>
      <MetaPixelEvent page="Profile" />
      <PerformanceDesktop />
    </>
  )
}

export default Performance
