import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { get, post } from "../services/api/api"
import Mission from "../models/mission"
import { parseMissionsListData } from "../services/utils/parseFunctions"
import { UsersContext } from "./users"
import { ChallengeContext } from "./challenge"
import {
  cacheImages,
  deepCopy,
  enumAsArray,
  logger,
} from "../services/utils/utils"
import { MissionPeriod } from "../services/config/enum"
import { MainContext } from "./main"

interface MissionsContextInterface {
  missionsLoading: boolean
  missionsError: boolean
  missionsHistoryLoading: boolean
  missionsHistoryUpdating: boolean
  missionsHistoryError: boolean
  missions: Mission[]
  setMissions: Dispatch<SetStateAction<Mission[]>>
  missionsHistory: Mission[]
  missionsHistoryNextToken: string | null
  currentSlide: number
  setCurrentSlide: Dispatch<SetStateAction<number>>
  updateAll: (withDelay?: boolean) => void
  getMissionsHistory: (withLoading?: boolean) => Promise<boolean>
  selectedTab: number
  setSelectedTab: Dispatch<SetStateAction<number>>
  completedMissionsCountLoading: boolean
  completedMissionsCountError: boolean
  completedMissionsCount: number
  completeCheckIn: (checkinId: string) => Promise<boolean>
}

const MissionsContext = createContext<MissionsContextInterface>({
  missionsLoading: true,
  missionsError: false,
  missionsHistoryLoading: true,
  missionsHistoryUpdating: false,
  missionsHistoryError: false,
  missions: [],
  setMissions: () => {},
  missionsHistory: [],
  missionsHistoryNextToken: null,
  currentSlide: 0,
  setCurrentSlide: () => {},
  updateAll: () => {},
  getMissionsHistory: async () => true,
  selectedTab: 0,
  setSelectedTab: () => {},
  completedMissionsCountLoading: true,
  completedMissionsCountError: false,
  completedMissionsCount: 0,
  completeCheckIn: async () => true,
})

const MissionsController = ({ children }: { children: ReactNode }) => {
  const {
    getUserInfo,
    setUpdatingMissions,
    currentMission,
    setCurrentMission,
    setViewTutorial,
  } = useContext(MainContext)
  const { getLeaderboard } = useContext(UsersContext)
  const { getChallenge, getSavings } = useContext(ChallengeContext)

  // loadings
  const [missionsLoading, setMissionsLoading] = useState<boolean>(true)
  const [missionsHistoryLoading, setMissionsHistoryLoading] =
    useState<boolean>(true)
  const [missionsHistoryUpdating, setMissionsHistoryUpdating] =
    useState<boolean>(false)
  const [completedMissionsCountLoading, setCompletedMissionsCountLoading] =
    useState<boolean>(true)

  // errors
  const [missionsError, setMissionsError] = useState<boolean>(false)
  const [missionsHistoryError, setMissionsHistoryError] =
    useState<boolean>(false)
  const [completedMissionsCountError, setCompletedMissionsCountError] =
    useState<boolean>(false)

  // states
  const [missions, setMissions] = useState<Mission[]>([])
  const [missionsHistory, setMissionsHistory] = useState<Mission[]>([])
  const [missionsHistoryNextToken, setMissionsHistoryNextToken] = useState<
    string | null
  >(null)
  const [completedMissionsCount, setCompletedMissionsCount] =
    useState<number>(0)
  const [currentSlide, setCurrentSlide] = useState<number>(0) // missions carousel current slide
  const [selectedTab, setSelectedTab] = useState<number>(0) // missions tabs current tab

  // get missions list
  const getMissionsList = async () => {
    const { data } = await get("/web/mission/list")

    // hide tutorial if there are no missions (tutorial only works with missions)
    if (!data.items.length) {
      setViewTutorial(false)
    }

    return data.items
  }

  // get user missions list
  const getUserMissionsList = async () => {
    const { data } = await get("/web/mission/user/list")

    return data.items
  }

  // get missions
  const getMissions = async (withLoading = true) => {
    if (withLoading) {
      setMissionsLoading(true)
    }
    setMissionsError(false)

    try {
      const list = await getMissionsList()
      const userList = await getUserMissionsList()

      // parse data
      const unifiedList = parseMissionsListData(list, userList)

      // sort data by MissionPeriod enum (based on the enum order)
      const missionPeriodEnumArray = enumAsArray(MissionPeriod)
      unifiedList.sort(
        (a: Mission, b: Mission) =>
          missionPeriodEnumArray.indexOf(a.period) -
          missionPeriodEnumArray.indexOf(b.period)
      )

      // order change specific for this integration
      const missionToMove = unifiedList.find(
        (mission) =>
          mission.missionId === "b99c4f87-cd48-4425-92a9-ed2cbe052014"
      )
      const missionToMoveIndex = unifiedList.findIndex(
        (mission) =>
          mission.missionId === "b99c4f87-cd48-4425-92a9-ed2cbe052014"
      )
      if (missionToMove) {
        unifiedList.splice(missionToMoveIndex, 1)
        unifiedList.unshift(missionToMove)
      }

      // cache images
      await cacheImages(unifiedList.map((item) => item.image))

      logger("missions list", unifiedList)
      setMissions(unifiedList)

      // update current mission
      if (
        currentMission &&
        unifiedList.some((item) => item.missionId === currentMission.missionId)
      ) {
        const missionToSet = unifiedList.find(
          (item) => item.missionId === currentMission.missionId
        )
        setCurrentMission(deepCopy(missionToSet))
        localStorage.setItem("currentMission", JSON.stringify(missionToSet))
      }

      setMissionsLoading(false)
      setUpdatingMissions(false)
    } catch (e) {
      logger("missions list error", e)
      setMissionsError(true)
    }
  }

  // get missions history
  const getMissionsHistory = async (
    withLoading = true,
    withNextToken = true
  ) => {
    if (withLoading) {
      setMissionsHistoryLoading(true)
    }
    setMissionsHistoryUpdating(true)
    setMissionsHistoryError(false)

    try {
      const { data } = await get(
        missionsHistoryNextToken && withNextToken
          ? `/web/mission/user/history?nextToken=${missionsHistoryNextToken}`
          : "/web/mission/user/history"
      )

      // parse data
      data.items = data.items.filter((item: any) => item.satisfied)

      if (missionsHistoryNextToken && withNextToken) {
        logger("missions history", [...missionsHistory, ...data.items])
        setMissionsHistory((current) => [...current, ...data.items])
      } else {
        logger("missions history", data.items)
        setMissionsHistory(data.items)
      }
      setMissionsHistoryNextToken(data.nextToken)

      setMissionsHistoryLoading(false)
      setMissionsHistoryUpdating(false)

      return true
    } catch (e) {
      logger("missions history error", e)
      setMissionsHistoryError(true)
      setMissionsHistoryUpdating(false)

      return false
    }
  }

  // get completed missions count
  const getCompletedMissionsCount = async (withLoading = true) => {
    if (withLoading) {
      setCompletedMissionsCountLoading(true)
    }

    try {
      const { data } = await get("/web/mission/mission/user")
      logger("completed missions count", data.missions ?? 0)

      if (data.missions) {
        setCompletedMissionsCount(data.missions)
      }

      setCompletedMissionsCountLoading(false)
    } catch (e) {
      logger("completed missions count error", e)
      setCompletedMissionsCountLoading(false)
      setCompletedMissionsCountError(true)
    }
  }

  // complete checkin mission
  const completeCheckIn = async (checkinId: string) => {
    try {
      await post("/web/checkin", { checkinId })

      // update mission locally and then from the server
      missions.find(
        (mission) => mission.missionTypeId === checkinId
      )!.satisfied = true
      missions.find(
        (mission) => mission.missionTypeId === checkinId
      )!.current = 1
      missions.find(
        (mission) => mission.missionTypeId === checkinId
      )!.updatedAt = new Date().toISOString()
      setMissions([...missions])
      updateAll()

      return true
    } catch (e) {
      logger("checkin complete error", e)

      return false
    }
  }

  // update missions list and user info
  const updateAll = async (withDelay = false) => {
    setTimeout(
      () => {
        getMissions(false)
        getMissionsHistory(false, false)
      },
      withDelay ? 2000 : 0
    )
    setTimeout(() => {
      getCompletedMissionsCount(false)
      getUserInfo()
      getChallenge(false)
      getSavings(false)
      getLeaderboard(false, false)
    }, 3000)
    setTimeout(() => {
      getCompletedMissionsCount(false)
      getUserInfo()
    }, 5500)
  }

  // initial fetch
  useEffect(() => {
    getMissions()
    getMissionsHistory()
    getCompletedMissionsCount()
  }, [])

  return (
    <MissionsContext.Provider
      value={{
        missionsLoading,
        missionsError,
        missionsHistoryLoading,
        missionsHistoryUpdating,
        missionsHistoryError,
        missions,
        setMissions,
        missionsHistory,
        missionsHistoryNextToken,
        currentSlide,
        setCurrentSlide,
        updateAll,
        getMissionsHistory,
        selectedTab,
        setSelectedTab,
        completedMissionsCountLoading,
        completedMissionsCountError,
        completedMissionsCount,
        completeCheckIn,
      }}
    >
      {children}
    </MissionsContext.Provider>
  )
}
export { MissionsController, MissionsContext }
