import { Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { ActionsContext } from "../../controllers/actions"
import { colors } from "../../services/config/colors"
import {
  BodySliceType,
  MissionPeriod,
  MissionType,
} from "../../services/config/enum"
import infoIcon from "../../assets/icons/info-primary.svg"
import Text from "../global/common/Text"
import Title from "../global/common/Title"
import SavingCard from "./common/SavingCard"
import BackButtonMobile from "../global/mobile/BackButtonMobile"
import Button from "../global/common/Button"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import AppearFromSide from "../animations/AppearFromSide"
import AlertInfo from "../global/common/AlertInfo"
import Action from "../../models/action"
import { scrollWindowToTop } from "../../services/utils/utils"
import { useTranslation } from "react-i18next"
import ActionLoggedFeedback from "../feedbacks/common/ActionLoggedFeedback"
import MissionCompletedFeedback from "../feedbacks/common/MissionCompletedFeedback"
import { MainContext } from "../../controllers/main"
import actMissionIconWhite from "../../assets/icons/missions/missionsTypes/act-white.svg"
import PointsBadge from "../global/common/PointsBadge"
import { defaultBoxShadow } from "../../services/config/constants"

const ActionDetailsMobile = ({
  action,
  timesLogged,
  repeatableInfoOpen,
  setRepeatableInfoOpen,
  logFeedbackOpen,
  missionFeedbackOpen,
}: {
  action: Action
  timesLogged: number
  repeatableInfoOpen: boolean
  setRepeatableInfoOpen: Dispatch<SetStateAction<boolean>>
  logFeedbackOpen: boolean
  missionFeedbackOpen: boolean
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentMission, updatingMissions } = useContext(MainContext)
  const { logActions, setSelectedActions } = useContext(ActionsContext)

  return !currentMission ? null : (
    <div style={{ width: "100%", minHeight: "100%" }}>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 136,
        }}
      >
        {/* back button */}
        <BackButtonMobile
          style={{ position: "absolute", top: 22, left: 16, zIndex: 1 }}
          onClick={() => {
            navigate(
              currentMission.missionTypeId === action.id ? "/" : "/actions"
            )
          }}
        />
        {/* top background */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: currentMission.missionTypeId === action.id ? 225 : 133,
            backgroundColor: action.category.backColor,
            top: 0,
            borderBottomLeftRadius: 24,
            borderBottomRightRadius: 24,
            display: "flex",
            justifyContent: "center",
          }}
        />
        {/* mission */}
        {currentMission.missionTypeId === action.id && (
          <AppearFromSide
            fade
            style={{
              width: "100%",
              marginTop: 84,
              paddingInline: 16,
              position: "relative",
            }}
          >
            <Stack direction="row" gap={0.75}>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: 32,
                  borderRadius: 4,
                  paddingInline: 8,
                  backgroundColor: colors.missions.act,
                }}
              >
                <img src={actMissionIconWhite} style={{ width: 19 }} alt="" />
                <Title
                  fontSize={14}
                  color={colors.textWhite}
                  style={{
                    fontVariant: "all-small-caps",
                    marginLeft: 8,
                    marginBottom: 2,
                  }}
                >
                  {t("act")}
                </Title>
                <Title
                  fontSize={14}
                  color={colors.textWhite}
                  style={{
                    fontVariant: "all-small-caps",
                    marginLeft: 14,
                    marginBottom: 2,
                  }}
                >
                  {currentMission.current}/{currentMission.target}
                </Title>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: 32,
                  borderRadius: 4,
                  paddingInline: 8,
                  border: "1px solid " + colors.tertiary,
                  backgroundColor: colors.backgroundWhite,
                }}
              >
                <Title fontSize={14} color={colors.tertiary}>
                  {currentMission.period === MissionPeriod.DAILY
                    ? t("today")
                    : currentMission.period === MissionPeriod.WEEKLY
                    ? t("week")
                    : t("special_singular")}
                </Title>
              </Stack>
              <PointsBadge points={currentMission.points} />
            </Stack>
          </AppearFromSide>
        )}
        {currentMission.missionTypeId === action.id && (
          <AppearFromSide
            fade
            delay={0.05}
            style={{
              width: "100%",
              marginTop: 20,
              paddingInline: 16,
              position: "relative",
            }}
          >
            <Title fontSize={20}>{currentMission.title}</Title>
          </AppearFromSide>
        )}
        {/* header */}
        <FadeFromTop
          style={{
            width: "calc(100% - 32px)",
            marginTop: currentMission.missionTypeId === action.id ? 24 : 92,
            marginBottom: 24,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            style={{
              width: "100%",
              height: 80,
              backgroundColor: colors.backgroundWhite,
              boxShadow: defaultBoxShadow,
              borderRadius: 10,
              position: "relative",
              padding: 11,
              gap: 11,
            }}
          >
            <div
              className="center"
              style={{
                width: 58,
                minWidth: 58,
                height: 58,
                backgroundColor: action.category.backColor,
                borderRadius: 9,
              }}
            >
              <img
                src={action.image}
                style={{ width: 50, height: 50 }}
                alt=""
              />
            </div>
            <Text
              fontSize={16}
              lineHeight="18px"
              fontWeight={500}
              style={{
                width: "calc(100% - 150px)",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {action.document.title}
            </Text>
            <div
              className="center"
              style={{
                width: "auto",
                height: 20,
                paddingInline: 6,
                backgroundColor: action.category.backTagColor,
                position: "absolute",
                right: 11,
                borderRadius: 4,
              }}
            >
              <Title fontSize={10} color={action.category.foreColor}>
                {action.category.name}
              </Title>
            </div>
          </Stack>
        </FadeFromTop>
        {/* action body */}
        <AppearFromSide fade style={{ width: "100%" }}>
          {action.document.body && action.document.body.length
            ? action.document.body.map((item, index) => {
                if (item.sliceType === BodySliceType.BodyTextSlice) {
                  return (
                    <div
                      className="html"
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: item.text.replace(/<\/?a[^>]*>/g, ""),
                      }}
                      style={{
                        fontSize: 16,
                        fontWeight: 300,
                        lineHeight: "22px",
                        color: colors.text,
                        overflow: "hidden",
                        paddingInline: 16,
                      }}
                    />
                  )
                } else {
                  return null
                }
              })
            : null}
        </AppearFromSide>
        {/* action savings */}
        {action.metrics.hasSavings ? (
          <AppearFromSide
            style={{
              width: "100%",
              marginTop:
                action.document.body && action.document.body.length ? 16 : 0,
            }}
            delay={0.05}
            fade
          >
            <Title
              textAlign="left"
              fontSize={18}
              lineHeight="25px"
              paddingLeft={16}
              style={{ width: "100%" }}
              component="h2"
            >
              {t("action_savings")}
            </Title>
          </AppearFromSide>
        ) : null}
        {action.metrics.hasSavings ? (
          <AppearFromSide
            style={{ width: "100%", marginTop: 16 }}
            delay={0.1}
            fade
          >
            <Stack
              direction="row"
              gap={1.5}
              style={{
                width: "100%",
                overflowX: "scroll",
                paddingInline: 16,
              }}
            >
              {action.metrics.co2Saving ? (
                <SavingCard metric="co2" amount={action.metrics.co2Saving} />
              ) : null}
              {action.metrics.waterSaving ? (
                <SavingCard
                  metric="water"
                  amount={action.metrics.waterSaving}
                />
              ) : null}
              {action.metrics.energySaving ? (
                <SavingCard
                  metric="energy"
                  amount={action.metrics.energySaving}
                />
              ) : null}
            </Stack>
          </AppearFromSide>
        ) : null}
        {/* assumptions (come lo calcoliamo) */}
        {action.document.assumptions ? (
          <AppearFromSide
            style={{
              width: "100%",
              height: 22,
              marginTop: 16,
              paddingLeft: 16,
            }}
            delay={0.15}
            fade
          >
            <div
              style={{
                height: 22,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "flex-start",
              }}
              onClick={() => {
                scrollWindowToTop()
                navigate(window.location.pathname + "/calculations")
              }}
            >
              <img src={infoIcon} style={{ width: 16, height: 16 }} alt="" />
              <Text
                fontSize={12}
                lineHeight="16px"
                fontWeight={700}
                style={{ marginLeft: 5 }}
                color={colors.primaryDark}
              >
                {t("how_is_calculated")}
              </Text>
            </div>
          </AppearFromSide>
        ) : null}
        {/* log button */}
        <FadeFromBottom style={{ width: "100%", position: "fixed", bottom: 0 }}>
          <div
            style={{
              width: "100%",
              paddingTop: 16,
              paddingBottom: 16,
              boxShadow: "0px 0px 64px rgba(3, 56, 120, 0.12)",
              borderRadius: "10px 10px 0px 0px",
              backgroundColor: colors.backgroundWhite,
            }}
          >
            {currentMission.missionTypeId === action.id &&
            currentMission.target === 1 ? null : action.timesPerDay > 1 ? (
              <Stack
                style={{
                  height: 16,
                  marginLeft: 16,
                  paddingRight: 16,
                  marginBottom: 16,
                  position: "relative",
                }}
                alignItems="center"
                direction="row"
              >
                <img
                  src={infoIcon}
                  style={{ width: 16, height: 16 }}
                  onClick={() => {
                    setRepeatableInfoOpen(true)
                  }}
                  alt=""
                />
                <div
                  onClick={() => {
                    setRepeatableInfoOpen(true)
                  }}
                >
                  <Text
                    fontSize={12}
                    lineHeight="16px"
                    fontWeight={700}
                    style={{ marginLeft: 5 }}
                    color={colors.primaryDark}
                  >
                    {t("log_more_than_once")}
                  </Text>
                </div>
                <Text
                  fontSize={12}
                  lineHeight="16px"
                  fontWeight={700}
                  style={{ position: "absolute", right: 16 }}
                  color={colors.primaryDark}
                >
                  {timesLogged}/{action.timesPerDay}
                </Text>
              </Stack>
            ) : null}
            <Button
              width="calc(100% - 32px)"
              style={{ marginLeft: 16 }}
              disabled={timesLogged === action.timesPerDay}
              loading={updatingMissions}
              onClick={() => {
                setSelectedActions([])
                logActions([action])
              }}
              title={
                updatingMissions
                  ? t("log")
                  : timesLogged === action.timesPerDay
                  ? t("action_logged")
                  : t("log")
              }
            >
              {updatingMissions
                ? t("log")
                : timesLogged === action.timesPerDay
                ? t("action_logged")
                : t("log")}
            </Button>
          </div>
        </FadeFromBottom>
      </div>
      {/* alerts and feedbacks */}
      <AlertInfo
        open={repeatableInfoOpen}
        setOpen={setRepeatableInfoOpen}
        title={t("log_more_than_once")}
        description={t("common_and_frequent_actions")}
        descriptionAlign="center"
      />
      <ActionLoggedFeedback open={logFeedbackOpen} />
      <MissionCompletedFeedback
        open={missionFeedbackOpen}
        missionType={MissionType.ACT}
      />
    </div>
  )
}

export default ActionDetailsMobile
