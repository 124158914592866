import { Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import awLogoVertical from "../../assets/images/aw-logo-vertical.png"
import partnerLogo from "../../assets/images/partner-logo.png"
import { Dispatch, SetStateAction, useContext, useRef, useState } from "react"
import { MainContext } from "../../controllers/main"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import Button from "../global/common/Button"
import { Trans, useTranslation } from "react-i18next"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import { onboardingElementsMaxWidth } from "../../services/config/constants"
import AlertInfo from "../global/common/AlertInfo"
import earthImage from "../../assets/images/onboarding/earth.png"
import partnerBackground from "../../assets/images/partner-background.png"
import PrizesAlert from "./common/PrizesAlert"
import Alert from "../global/common/Alert"

const OnboardingMobile = ({
  footprintAlertOpen,
  setFootprintAlertOpen,
  height,
  continueButtonWidth,
}: {
  footprintAlertOpen: boolean
  setFootprintAlertOpen: Dispatch<SetStateAction<boolean>>
  height?: number | string
  continueButtonWidth?: number
}) => {
  const { t } = useTranslation()
  const { windowHeight, isMobile, setViewOnboarding } = useContext(MainContext)

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  // current visualized page
  const [currentPage, setCurrentPage] = useState<number>(0)

  // prizes alert
  const [prizesAlertOpen, setPrizesAlertOpen] = useState<boolean>(false)

  // challenge alert logic
  const [challengeAlertOpen, setChallengeAlertOpen] = useState<boolean>(
    localStorage.getItem("showChallengeEndAlert") ? false : true
  )

  return (
    <Stack
      alignItems="center"
      className="hide-scrollbars"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundColor: colors.backgroundWhite,
        overflowY: "scroll",
      }}
    >
      {/* content */}
      <Stack
        id="onboarding-container"
        direction="row"
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          minHeight: currentPage ? 850 : 850,
          overflow: "hidden",
          paddingTop: 26,
          position: "relative",
        }}
        tabIndex={0}
      >
        {currentPage === 0 ? (
          <Stack
            style={{
              width: "100%",
              minWidth: "100%",
              paddingInline: isMobile ? 16 : 60,
            }}
            alignItems="center"
          >
            {/* header */}
            <FadeFromTop>
              <Stack direction="row" alignItems="center" gap={3}>
                <img
                  src={partnerLogo}
                  style={{ height: 42 }}
                  alt={t("partner_logo_alt")}
                />
                <img
                  src={awLogoVertical}
                  style={{ height: 42 }}
                  alt={t("aworld_logo_alt")}
                />
              </Stack>
            </FadeFromTop>
            <FadeFromTop delay={0.05} style={{ marginTop: 28 }}>
              <Title
                fontSize={isMobile ? 26 : 28}
                textAlign="center"
                component="h1"
                style={{ maxWidth: 300 }}
              >
                {t("onboarding_welcome")}
              </Title>
            </FadeFromTop>
            <FadeFromTop delay={0.1} style={{ marginTop: 12 }}>
              <Text
                fontSize={isMobile ? 18 : 20}
                textAlign="center"
                fontWeight={500}
                component="h2"
              >
                {t("onboarding_subtitle")}
              </Text>
            </FadeFromTop>
            <FadeFromTop delay={0.1} style={{ marginTop: 36 }}>
              <img src={partnerBackground} style={{ width: 270 }} alt="" />
            </FadeFromTop>
            <FadeFromTop delay={0.15} style={{ marginTop: 36 }}>
              <Text
                fontSize={isMobile ? 18 : 20}
                textAlign="center"
                style={{ maxWidth: onboardingElementsMaxWidth }}
              >
                <Trans i18nKey="onboarding_description">
                  <strong style={{ fontWeight: 500 }}>
                    Voci di Quartiere è un programma di dialogo con i cittadini
                  </strong>{" "}
                  per promuovere una riflessione sulla qualità della vita, degli
                  spazi pubblici e dei servizi nei quartieri torinesi,
                  realizzato da{" "}
                  <strong style={{ fontWeight: 500 }}>
                    Urban Lab e Città di Torino,
                  </strong>{" "}
                  in collaborazione con{" "}
                  <strong style={{ fontWeight: 500 }}>AWorld.</strong>
                </Trans>
              </Text>
            </FadeFromTop>
          </Stack>
        ) : currentPage === 1 ? (
          <Stack
            style={{
              width: "100%",
              minWidth: "100%",
              paddingInline: isMobile ? 16 : 60,
              position: "relative",
            }}
            alignItems="center"
          >
            {/* header */}
            <FadeFromTop>
              <Stack direction="row" alignItems="center" gap={3}>
                <img
                  src={partnerLogo}
                  style={{ height: 42 }}
                  alt={t("partner_logo_alt")}
                />
                <img
                  src={awLogoVertical}
                  style={{ height: 42 }}
                  alt={t("aworld_logo_alt")}
                />
              </Stack>
            </FadeFromTop>
            <FadeFromTop delay={0.05} style={{ marginTop: 28 }} key="title">
              <Title
                fontSize={isMobile ? 26 : 28}
                textAlign="center"
                component="h1"
                style={{ maxWidth: 300 }}
              >
                {t("the_challenge")}
              </Title>
            </FadeFromTop>
            <FadeFromTop
              delay={0.1}
              style={{ maxWidth: 570, marginTop: 40 }}
              key="description"
            >
              <Text fontSize={isMobile ? 18 : 20}>
                <Trans i18nKey="how_does_the_challenge_work">
                  <strong style={{ fontWeight: 500 }}>
                    Partecipa alla challenge di Urban Lab
                  </strong>
                  <br />
                  Completa le missioni e aiutaci a raggiungere insieme
                  l’obiettivo comune per il Pianeta.
                  <br />
                  <br />
                  <strong style={{ fontWeight: 500 }}>
                    Scala la classifica
                  </strong>
                  <br />
                  Ottieni punti per ogni missione completata, sfida i
                  partecipanti nella classifica della challenge.
                  <br />
                  <br />
                  <strong style={{ fontWeight: 500 }}>Prova a vincere</strong>
                  <br />I primi classificati potranno vincere dei premi:
                  completa tutte le missioni!
                </Trans>
              </Text>
            </FadeFromTop>
            <FadeFromTop
              y={0}
              style={{
                width: isMobile ? 300 : 500,
                position: "absolute",
                bottom: isMobile ? -80 : -100,
                right: 0,
              }}
              key="image"
            >
              <img
                src={earthImage}
                style={{
                  width: "100%",
                }}
                alt=""
              />
            </FadeFromTop>
          </Stack>
        ) : currentPage === 2 ? (
          <Stack
            style={{
              width: "100%",
              minWidth: "100%",
              paddingInline: isMobile ? 16 : 60,
              position: "relative",
            }}
            alignItems="center"
          >
            {/* header */}
            <FadeFromTop>
              <Stack direction="row" alignItems="center" gap={3}>
                <img
                  src={partnerLogo}
                  style={{ height: 42 }}
                  alt={t("partner_logo_alt")}
                />
                <img
                  src={awLogoVertical}
                  style={{ height: 42 }}
                  alt={t("aworld_logo_alt")}
                />
              </Stack>
            </FadeFromTop>
            <FadeFromTop delay={0.05} style={{ marginTop: 28 }} key="title-2">
              <Title
                fontSize={isMobile ? 26 : 28}
                textAlign="center"
                component="h1"
                style={{ maxWidth: 300 }}
              >
                {t("how_does_it_work")}
              </Title>
            </FadeFromTop>
            <FadeFromTop
              delay={0.1}
              style={{ maxWidth: 570, marginTop: 40 }}
              key="description-2"
            >
              <Text fontSize={isMobile ? 18 : 20}>
                Le missioni sono semplici attività digitali a tema città e
                sostenibilità: hanno cadenza quotidiana e settimanale. Alcune
                sono “speciali”: durano più a lungo e attribuiscono più punti.
                <br />
                <br />
                Perchè raccogliere i punti? Per aggiudicarsi i{" "}
                <strong
                  style={{
                    color: colors.primaryDark,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  role="button"
                  onClick={() => {
                    setPrizesAlertOpen(true)
                  }}
                >
                  premi in palio
                </strong>
                .
                <br />
                <br />
                Dopo aver effettuato la registrazione, un tutorial ti introduce
                al funzionamento dello spazio digitale, dove quotidianamente ti
                vengono proposte attività da completare.
              </Text>
            </FadeFromTop>
            <FadeFromTop
              y={0}
              style={{
                width: isMobile ? 300 : 500,
                position: "absolute",
                bottom: isMobile ? -80 : -100,
                right: 0,
              }}
              key="image"
            >
              <img
                src={earthImage}
                style={{
                  width: "100%",
                }}
                alt=""
              />
            </FadeFromTop>
          </Stack>
        ) : (
          <Stack
            style={{
              width: "100%",
              minWidth: "100%",
              paddingInline: isMobile ? 16 : 60,
              position: "relative",
            }}
            alignItems="center"
          >
            {/* header */}
            <FadeFromTop>
              <Stack direction="row" alignItems="center" gap={3}>
                <img
                  src={partnerLogo}
                  style={{ height: 42 }}
                  alt={t("partner_logo_alt")}
                />
                <img
                  src={awLogoVertical}
                  style={{ height: 42 }}
                  alt={t("aworld_logo_alt")}
                />
              </Stack>
            </FadeFromTop>
            <FadeFromTop delay={0.05} style={{ marginTop: 28 }} key="title-3">
              <Title
                fontSize={isMobile ? 26 : 28}
                textAlign="center"
                component="h1"
                style={{ maxWidth: 300 }}
              >
                {t("how_does_it_work")}
              </Title>
            </FadeFromTop>
            <FadeFromTop
              delay={0.1}
              style={{ maxWidth: 570, marginTop: 40 }}
              key="description-3"
            >
              <div style={{ fontSize: isMobile ? 18 : 20, fontWeight: 300 }}>
                Le attività sono di tre tipi:
                <ul>
                  <li>
                    <strong style={{ fontWeight: 500 }}>Agisci</strong>:
                    registra attività quotidiane che ti vengono proposte (come
                    spostarsi a piedi o in bicicletta, anziché con l'auto);
                  </li>
                  <li style={{ marginTop: 16 }}>
                    <strong style={{ fontWeight: 500 }}>Impara</strong>: leggi
                    brevi articoli informativi su Torino ma non solo, e completa
                    i quiz;
                  </li>
                  <li style={{ marginTop: 16 }}>
                    <strong style={{ fontWeight: 500 }}>Scansiona</strong>:
                    partecipa agli eventi di Voci di Quartiere e scansiona il QR
                    code della missione Check-In (
                    <strong
                      style={{
                        color: colors.primaryDark,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      role="button"
                      onClick={() => {
                        window.open(
                          "https://www.torinocambia.it/prg/voci-di-quartiere"
                        )
                      }}
                    >
                      qui
                    </strong>{" "}
                    il programma sempre aggiornato).
                  </li>
                </ul>
                <br />
                Che ne dici, cominciamo?
              </div>
            </FadeFromTop>
            <FadeFromTop
              y={0}
              style={{
                width: isMobile ? 300 : 500,
                position: "absolute",
                bottom: isMobile ? -80 : -100,
                right: 0,
              }}
              key="image"
            >
              <img
                src={earthImage}
                style={{
                  width: "100%",
                }}
                alt=""
              />
            </FadeFromTop>
          </Stack>
        )}
      </Stack>
      {/* continue button */}
      <FadeFromBottom
        style={{
          width: continueButtonWidth ?? "calc(100% - 32px)",
          position: "fixed",
          bottom: currentPage ? 22 : -56,
          display: "flex",
          justifyContent: "center",
          transition: "bottom 200ms",
        }}
      >
        <Stack
          style={{
            width: "100%",
            gap: currentPage ? 12 : 22,
            transition: "200ms",
          }}
        >
          <Button
            title={t("continue")}
            width="100%"
            onClick={() => {
              if (currentPage === 3) {
                setViewOnboarding(false)
              } else {
                setCurrentPage((current) => (current += 1))
              }
            }}
            onFocusVisible={() => {
              screenReaderEnabled.current = true
            }}
            onBlur={() => {
              screenReaderEnabled.current = false
            }}
          >
            {t("continue")}
          </Button>
          <Button
            title={t("go_back")}
            width="100%"
            outlined
            onClick={() => {
              setCurrentPage((current) => (current -= 1))
            }}
            onFocusVisible={() => {
              screenReaderEnabled.current = true
            }}
            onBlur={() => {
              screenReaderEnabled.current = false
            }}
          >
            {t("go_back")}
          </Button>
        </Stack>
      </FadeFromBottom>
      {/* alerts */}
      <AlertInfo
        open={footprintAlertOpen}
        setOpen={setFootprintAlertOpen}
        title={t("what_is_footprint")}
        description={t("what_is_footprint_description")}
        descriptionAlign="center"
      />
      <PrizesAlert open={prizesAlertOpen} setOpen={setPrizesAlertOpen} />
      <Alert
        open={challengeAlertOpen}
        title="Challenge conclusa"
        description='La Challenge di Voci di Quartiere si è conclusa. Seguici su <a href="https://www.torinocambia.it/prg/voci-di-quartiere" target="_blank">torinocambia.it</a>'
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setChallengeAlertOpen(false)
          localStorage.setItem("showChallengeEndAlert", "false")
        }}
        isDescriptionHtml
      />
    </Stack>
  )
}

export default OnboardingMobile
