import { Stack } from "@mui/material"
import waterPrimaryIcon from "../../../assets/icons/water-primary.svg"
import co2PrimaryIcon from "../../../assets/icons/co2-primary.svg"
import energyPrimaryIcon from "../../../assets/icons/energy-primary.svg"
import { colors } from "../../../services/config/colors"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { useTranslation } from "react-i18next"
import Title from "../../global/common/Title"
import { roundWithDecimalPlaces } from "../../../services/utils/utils"

const SavingCard = ({
  metric,
  amount,
}: {
  metric: "co2" | "water" | "energy"
  amount: number
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)

  return (
    <Stack
      style={{
        width: 120,
        minWidth: 120,
        height: isMobile ? 76 : 80,
        borderRadius: isMobile ? 16 : 18,
        paddingInline: 12,
        paddingTop: 8,
        paddingBottom: 8,
        border: "1px solid " + colors.primaryDark,
        position: "relative",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        style={{ height: isMobile ? 21 : 23 }}
      >
        <img
          src={
            metric === "co2"
              ? co2PrimaryIcon
              : metric === "energy"
              ? energyPrimaryIcon
              : waterPrimaryIcon
          }
          style={{
            width: isMobile ? 19 : 21,
            height: isMobile ? 15 : 17,
            marginBottom: 2,
            marginLeft: metric !== "co2" ? -5 : 0,
          }}
          alt=""
        />
        <Title
          fontSize={isMobile ? 18 : 20}
          lineHeight={isMobile ? "21px" : "23px"}
          color={colors.primaryDark}
        >
          {roundWithDecimalPlaces(
            amount >= 1000000
              ? amount / 1000000
              : amount >= 1000
              ? amount / 1000
              : amount,
            1
          )
            .toString()
            .replace(".", ",")}
          {amount >= 1000000 ? "M" : amount >= 1000 ? "K" : ""}
        </Title>
      </Stack>
      <Title
        fontSize={isMobile ? 11 : 13}
        lineHeight={isMobile ? "13px" : "15px"}
        color={colors.primaryDark}
        style={{
          position: "absolute",
          bottom: 8,
          width: "calc(100% - 24px)",
        }}
      >
        {metric === "co2"
          ? `Kg ${t("co2")} ${t("saved")}`
          : metric === "water"
          ? `L ${t("water")} ${t("saved")}`
          : `kWh ${t("energy")} ${t("saved")}`}
      </Title>
    </Stack>
  )
}

export default SavingCard
