import { useContext, useEffect, useMemo, useState } from "react"
import { MainContext } from "../../controllers/main"
import EpisodeMobile from "../../components/episode/EpisodeMobile"
import EpisodeDesktop from "../../components/episode/EpisodeDesktop"
import { JourneysContext } from "../../controllers/journeys"
import { useNavigate, useParams } from "react-router-dom"
import { registerPageEvent } from "../../services/utils/utils"
import MetaPixelEvent from "../../components/global/common/MetaPixelEvent"

const Episode = () => {
  const navigate = useNavigate()
  const { episodeId, slideId } = useParams()
  const { isMobile, currentMission } = useContext(MainContext)
  const { journey } = useContext(JourneysContext)

  // redirect to landing if currentMission isn't present
  useEffect(() => {
    if (!currentMission) {
      navigate("/")
    }
  }, [])

  // register google analytics event
  useEffect(() => {
    registerPageEvent("episode")
  }, [])

  // current episode
  const currentEpisode = journey?.episodes.find(
    (episode) => episode.id === episodeId
  )!
  const currentEpisodeIndex = journey?.episodes.findIndex(
    (episode) => episode.id === episodeId
  )!

  // current slide and slides info
  const currentSlideIndex = parseInt(slideId!)
  const currentSlide = currentEpisode?.slides[currentSlideIndex]
    ? currentEpisode?.slides[currentSlideIndex]
    : currentEpisode?.quiz[currentSlideIndex - currentEpisode.slides.length]!
  const currentSlideQuizResult = currentSlide
    ? currentEpisode?.result.quiz.find(
        (item) => item.slideId === currentSlide.id
      )
    : undefined
  const numberOfSlides = currentEpisode!.slides.length
  const numberOfQuiz = currentEpisode!.quiz.length
  const totalSlides = numberOfSlides + numberOfQuiz

  // next episode (the first that has to be completed or the next one if all episodes are completed)
  const nextEpisode = useMemo(() => {
    return journey!.episodes.find((episode) => !episode.result.completed)
      ? journey!.episodes.find((episode) => !episode.result.completed)!
      : journey!.episodes[currentEpisodeIndex + 1]!
  }, [currentEpisodeIndex, journey])

  // mission completed feedback
  const [missionFeedbackOpen, setMissionFeedbackOpen] = useState<boolean>(false)

  // check if currentMission changed to show feedback
  useEffect(() => {
    if (
      currentMission &&
      currentSlideIndex === totalSlides &&
      journey?.result.completed &&
      !journey.episodes[currentEpisodeIndex + 1]
    ) {
      setTimeout(() => {
        setMissionFeedbackOpen(true)
      }, 400)
    }
  }, [currentSlideIndex, totalSlides, journey])

  return isMobile ? (
    <>
      <MetaPixelEvent page="Episode" />
      <EpisodeMobile
        currentSlide={currentSlide}
        currentSlideIndex={currentSlideIndex}
        numberOfSlides={numberOfSlides}
        numberOfQuiz={numberOfQuiz}
        totalSlides={totalSlides}
        currentEpisode={currentEpisode}
        currentEpisodeIndex={currentEpisodeIndex}
        nextEpisode={nextEpisode}
        currentSlideQuizResult={currentSlideQuizResult}
        missionFeedbackOpen={missionFeedbackOpen}
      />
    </>
  ) : (
    <>
      <MetaPixelEvent page="Episode" />
      <EpisodeDesktop
        currentSlide={currentSlide}
        currentSlideIndex={currentSlideIndex}
        currentSlideQuizResult={currentSlideQuizResult}
        numberOfSlides={numberOfSlides}
        numberOfQuiz={numberOfQuiz}
        totalSlides={totalSlides}
        currentEpisode={currentEpisode}
        currentEpisodeIndex={currentEpisodeIndex}
        nextEpisode={nextEpisode}
        missionFeedbackOpen={missionFeedbackOpen}
      />
    </>
  )
}

export default Episode
