import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import AmplifySignInMobile from "../../components/onboarding/AmplifySignInMobile"
import AmplifySignInDesktop from "../../components/onboarding/AmplifySignInDesktop"
import "../../styles/amplifySignIn.css"

const AmplifySignIn = () => {
  const { isMobile, confirmCode } = useContext(MainContext)

  // loading for signup
  const [loading, setLoading] = useState<boolean>(false)

  // states
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [emailError, setEmailError] = useState<boolean>(false)
  const [consents, setConsents] = useState<boolean[]>([false, false])
  const [code, setCode] = useState<string>("")
  const [codeErrorAlertOpen, setCodeErrorAlertOpen] = useState<boolean>(false)

  // signin error alert, if user shouldSignIn but the account doesn't exist
  const [signInAlertOpen, setSignInAlertOpen] = useState<boolean>(false)

  // automatically confirm code when length is === 6
  const callConfirmCode = async () => {
    setLoading(true)

    const result = await confirmCode(code)

    if (result === 0) {
      setSignInAlertOpen(true)
      setLoading(false)
    } else if (!result) {
      setCodeErrorAlertOpen(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (code.length === 6) {
      callConfirmCode()
    }
  }, [code])

  return isMobile ? (
    <AmplifySignInMobile
      loading={loading}
      setLoading={setLoading}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      email={email}
      setEmail={setEmail}
      emailError={emailError}
      setEmailError={setEmailError}
      consents={consents}
      setConsents={setConsents}
      code={code}
      setCode={setCode}
      codeErrorAlertOpen={codeErrorAlertOpen}
      setCodeErrorAlertOpen={setCodeErrorAlertOpen}
      callConfirmCode={callConfirmCode}
      signInAlertOpen={signInAlertOpen}
    />
  ) : (
    <AmplifySignInDesktop
      loading={loading}
      setLoading={setLoading}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      email={email}
      setEmail={setEmail}
      emailError={emailError}
      setEmailError={setEmailError}
      consents={consents}
      setConsents={setConsents}
      code={code}
      setCode={setCode}
      codeErrorAlertOpen={codeErrorAlertOpen}
      setCodeErrorAlertOpen={setCodeErrorAlertOpen}
      callConfirmCode={callConfirmCode}
      signInAlertOpen={signInAlertOpen}
    />
  )
}

export default AmplifySignIn
