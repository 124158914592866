import { Helmet } from "react-helmet"

const MetaPixelEvent = ({
  lead = false,
  page = "",
}: {
  lead?: boolean
  page?: string
}) => {
  return (
    <Helmet>
      {lead ? (
        <script>{`fbq('track', 'Lead');`}</script>
      ) : (
        <script>{`fbq('trackCustom', '${page}');`}</script>
      )}
    </Helmet>
  )
}

export default MetaPixelEvent
