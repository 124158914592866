import { Grid, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import {
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import FadeFromTop from "../animations/FadeFromTop"
import learnMissionIconWhite from "../../assets/icons/missions/missionsTypes/learn-white.svg"
import Title from "../global/common/Title"
import { useContext, useState } from "react"
import { JourneysContext } from "../../controllers/journeys"
import Text from "../global/common/Text"
import EpisodeCardDesktop from "./desktop/EpisodeCardDesktop"
import PointsBadge from "../global/common/PointsBadge"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { Trans, useTranslation } from "react-i18next"
import AppearFromSide from "../animations/AppearFromSide"
import { MainContext } from "../../controllers/main"
import { MissionPeriod } from "../../services/config/enum"
import Appear from "../animations/Appear"
import AvatarInfoButton from "../global/desktop/AvatarInfoButton"
import AlertInfo from "../global/common/AlertInfo"

const JourneyDesktop = () => {
  const { t } = useTranslation()
  const { currentMission } = useContext(MainContext)
  const { journey } = useContext(JourneysContext)

  // loading for episode images caching
  const [loading, setLoading] = useState<boolean>(false)

  // journey info alert
  const [infoAlertOpen, setInfoAlertOpen] = useState<boolean>(false)

  return !currentMission ? null : (
    <Stack
      style={{
        width: "100%",
        minHeight: `calc(100% - ${footerHeight}px)`,
        position: "relative",
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* journey image */}
      <img
        src={journey?.image}
        style={{
          width: "100%",
          height: 337,
          objectFit: "cover",
          position: "absolute",
          top: navBarHeight + 230,
        }}
        alt=""
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[{ label: t("learn_mission") }]}
        selectedItem={0}
        style={{ marginTop: 22, position: "relative" }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            marginTop: 22,
            position: "relative",
          }}
        >
          {/* info */}
          <Appear style={{ position: "absolute", right: 0 }} fade>
            <AvatarInfoButton
              title={t("do_you_need_help")}
              onClick={() => {
                setInfoAlertOpen(true)
              }}
            />
          </Appear>
          {/* header */}
          <AppearFromSide fade>
            <Stack direction="row" gap={1}>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: 40,
                  borderRadius: 4,
                  paddingInline: 10,
                  backgroundColor: colors.missions.learn,
                }}
              >
                <img src={learnMissionIconWhite} style={{ width: 23 }} alt="" />
                <Title
                  fontSize={18}
                  color={colors.textWhite}
                  style={{
                    fontVariant: "all-small-caps",
                    marginLeft: 8,
                    marginBottom: 2,
                  }}
                >
                  {t("learn").toUpperCase()}
                </Title>
                <Title
                  fontSize={18}
                  color={colors.textWhite}
                  style={{
                    fontVariant: "all-small-caps",
                    marginLeft: 18,
                    marginBottom: 2,
                  }}
                >
                  {currentMission.current}/{currentMission.target}
                </Title>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: 40,
                  borderRadius: 4,
                  paddingInline: 10,
                  border: "1.5px solid " + colors.tertiary,
                  backgroundColor: colors.backgroundWhite,
                }}
              >
                <Title fontSize={18} color={colors.tertiary}>
                  {currentMission.period === MissionPeriod.DAILY
                    ? t("today")
                    : currentMission.period === MissionPeriod.WEEKLY
                    ? t("week")
                    : t("special_singular")}
                </Title>
              </Stack>
              <PointsBadge points={currentMission.points} />
            </Stack>
          </AppearFromSide>
          <AppearFromSide fade delay={0.05} style={{ marginTop: 16 }}>
            <Title fontSize={28}>{currentMission.title}</Title>
          </AppearFromSide>
          {/* journey heading */}
          <FadeFromTop delay={0.1} style={{ marginTop: 90 }}>
            <Stack
              style={{
                width: "100%",
                height: 165,
                paddingTop: 38,
                paddingBottom: 38,
                paddingInline: 50,
                backgroundColor: colors.backgroundWhite,
                borderRadius: 16,
                boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
                position: "relative",
              }}
            >
              <Title
                fontSize={36}
                lineHeight="46px"
                style={{
                  maxWidth: "calc(100% - 200px)",
                  height: 92,
                  maxHeight: 92,
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                component="h1"
              >
                {journey?.title}
              </Title>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                style={{
                  width: "auto",
                  height: 40,
                  position: "absolute",
                  right: 50,
                }}
              >
                <div
                  style={{
                    height: "100%",
                    paddingInline: 8,
                    borderRadius: 5,
                    backgroundColor: journey?.category.backTagColor,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text
                    fontSize={18}
                    fontWeight={700}
                    color={journey?.category.foreColor}
                  >
                    {journey?.category.name}
                  </Text>
                </div>
                {journey?.sdg ? (
                  <div
                    style={{
                      height: "100%",
                      paddingInline: 8,
                      borderRadius: 5,
                      backgroundColor: colors.backgroundWhite,
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid " + colors.primaryDark,
                    }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight={700}
                      color={colors.primaryDark}
                    >
                      {t("sdg")} {journey.sdg}
                    </Text>
                  </div>
                ) : null}
              </Stack>
            </Stack>
          </FadeFromTop>
          {/* episodes */}
          <Grid
            container
            columnSpacing={`${desktopColumnsGap}px`}
            rowSpacing={`${desktopColumnsGap}px`}
            style={{
              width: `calc(100% + ${desktopColumnsGap}px)`,
              maxWidth: `calc(${desktopMaxWidth}px + ${desktopColumnsGap}px)`,
              marginTop: 10,
              marginBottom: 58,
            }}
          >
            {journey?.episodes.map((episode, index) => (
              <Grid key={index} item xs={4}>
                <FadeFromTop
                  delay={0.05 * (index + 3)}
                  style={{ width: "100%" }}
                >
                  <EpisodeCardDesktop
                    episode={episode}
                    episodeNumber={index + 1}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </FadeFromTop>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </PaddingContainerDesktop>
      {/* alerts */}
      <AlertInfo
        open={infoAlertOpen}
        setOpen={setInfoAlertOpen}
        title={t("learn")}
        description={
          <Trans i18nKey="learn_missions_description">
            <strong style={{ fontWeight: 500 }}>Leggi gli episodi</strong>{" "}
            consigliati e{" "}
            <strong style={{ fontWeight: 500 }}>completa i quiz</strong> sui
            temi della sostenibilità
          </Trans>
        }
        descriptionAlign="center"
      />
    </Stack>
  )
}

export default JourneyDesktop
