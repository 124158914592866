import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"

const PrizeListItem = ({
  heading,
  title,
  image,
}: {
  heading: string
  title: string
  image?: string
}) => {
  const { isMobile } = useContext(MainContext)

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      style={{
        width: "100%",
        height: "auto",
      }}
    >
      {image ? (
        <img
          src={image}
          style={{
            width: 88,
            minWidth: 88,
            height: isMobile ? 80 : 88,
            objectFit: "contain",
            objectPosition: "center",
            borderRadius: 8,
            backgroundColor: colors.backgroundWhite,
          }}
          alt=""
        />
      ) : null}
      <Stack gap={0.5}>
        <Title
          fontSize={isMobile ? 13 : 16}
          lineHeight={isMobile ? "15px" : "18px"}
          color={colors.textSecondary}
        >
          {heading}
        </Title>
        <Title
          fontSize={isMobile ? 15 : 16}
          lineHeight={isMobile ? "21px" : "22px"}
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxHeight: isMobile ? 63 : 66,
          }}
        >
          {title}
        </Title>
      </Stack>
    </Stack>
  )
}

export default PrizeListItem
