import { Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import {
  checkInMissionsPoints,
  defaultBoxShadow,
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { t } from "i18next"
import { scrollWindowToTop } from "../../services/utils/utils"
import { useNavigate } from "react-router-dom"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import AppearFromSide from "../animations/AppearFromSide"
import ActionDetailsBackgroundDesktop from "../../assets/svgComponents/ActionDetailsBackgroundDesktop"
import Title from "../global/common/Title"
import PointsBadge from "../global/common/PointsBadge"
import AlertInfo from "../global/common/AlertInfo"
import Button from "../global/common/Button"
import Alert from "../global/common/Alert"
import CheckInMissionCompletedFeedback from "../feedbacks/common/CheckInMissionCompletedFeedback"
import CheckInMission from "../../models/checkInMission"
import EventHelpCardDesktop from "./desktop/EventHelpCardDesktop"
import QrScanAlert from "../global/common/QrScanAlert"
import LoadingOverlay from "../global/common/LoadingOverlay"

const AreaDetailsDesktop = ({
  checkInMission,
  loading,
  qrScanOpen,
  setQrScanOpen,
  setQrText,
  wrongIdAlertOpen,
  setWrongIdAlertOpen,
  missionCompletedAlertOpen,
  setMissionCompletedAlertOpen,
  scanLockedAlertOpen,
  setScanLockedAlertOpen,
}: {
  checkInMission: CheckInMission
  loading: boolean
  qrScanOpen: boolean
  setQrScanOpen: Dispatch<SetStateAction<boolean>>
  setQrText: Dispatch<SetStateAction<string>>
  wrongIdAlertOpen: boolean
  setWrongIdAlertOpen: Dispatch<SetStateAction<boolean>>
  missionCompletedAlertOpen: boolean
  setMissionCompletedAlertOpen: Dispatch<SetStateAction<boolean>>
  scanLockedAlertOpen: boolean
  setScanLockedAlertOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const navigate = useNavigate()
  const { windowHeight } = useContext(MainContext)

  // event info alert
  const [eventAlertOpen, setEventAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight:
          windowHeight > 1200 ? `calc(100% - ${footerHeight}px)` : 1200,
        position: "relative",
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("checkin_mission"),
            onClick: () => {
              scrollWindowToTop()
              navigate("/")
            },
          },
        ]}
        selectedItem={0}
        style={{ marginTop: 22, zIndex: 1 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
            marginTop: 22,
          }}
        >
          {/* header */}
          <AppearFromSide fade>
            <Stack direction="row" gap={1}>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: 40,
                  borderRadius: 4,
                  paddingInline: 10,
                  border: "1.5px solid " + colors.tertiary,
                  backgroundColor: colors.backgroundWhite,
                }}
              >
                <Title fontSize={18} color={colors.tertiary}>
                  {t("checkin").toUpperCase()}
                </Title>
              </Stack>
              <PointsBadge points={checkInMissionsPoints} />
            </Stack>
          </AppearFromSide>
          <AppearFromSide
            fade
            delay={0.05}
            style={{ width: "100%", marginTop: 16 }}
          >
            <Title
              fontSize={28}
              lineHeight="33px"
              style={{ width: "57.14%", height: 66 }}
            >
              {checkInMission.title}
            </Title>
          </AppearFromSide>
          {/* content */}
          <Stack style={{ marginTop: 35 }}>
            <AppearFromSide
              fade
              delay={0.1}
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <Stack
                direction="row"
                gap={2}
                style={{
                  width: "57.14%",
                  height: 113,
                  padding: 14,
                  backgroundColor: colors.backgroundWhite,
                  borderRadius: 10,
                  boxShadow: defaultBoxShadow,
                  position: "relative",
                }}
              >
                <img
                  src={checkInMission.image}
                  style={{
                    width: 85,
                    height: 85,
                    borderRadius: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  alt=""
                />
                <Title
                  fontSize={20}
                  lineHeight="28px"
                  style={{
                    maxWidth: "calc(100% - 185px)",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {checkInMission.subtitle}
                </Title>
                <PointsBadge
                  points={checkInMission.points}
                  style={{ position: "absolute", top: 14, right: 14 }}
                />
              </Stack>
            </AppearFromSide>
            {/* help card */}
            <EventHelpCardDesktop setEventAlertOpen={setEventAlertOpen} />
          </Stack>
          <AppearFromSide
            fade
            delay={0.1}
            style={{
              width: "57.14%",
              marginTop: 38,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              width={440}
              title={t("scan_qr_code")}
              disabled={checkInMission.satisfied}
              onClick={() => {
                setQrScanOpen(true)
              }}
            >
              {t("scan_qr_code")}
            </Button>
          </AppearFromSide>
          <AppearFromSide
            fade
            delay={0.15}
            style={{
              width: "57.14%",
              marginTop: 50,
              marginBottom: 58,
              position: "relative",
            }}
          >
            <Stack
              style={{
                width: "100%",
                height: "auto",
                padding: 46,
                paddingTop: 32,
                backgroundColor: colors.backgroundWhite,
                boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
                borderRadius: 10,
              }}
            >
              {checkInMission.description ? (
                <Stack>
                  <Title fontSize={24} lineHeight="46px" component="h2">
                    {t("area_description")}
                  </Title>
                  <div
                    className="area-content"
                    style={{
                      fontSize: 20,
                      lineHeight: "22px",
                      fontWeight: 300,
                      marginTop: 14,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: checkInMission.description,
                    }}
                  />
                </Stack>
              ) : null}
            </Stack>
          </AppearFromSide>
        </Stack>
      </PaddingContainerDesktop>
      {/* background */}
      <div
        style={{
          width: "100%",
          maxWidth: 1920,
          height: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <ActionDetailsBackgroundDesktop
          color={colors.background}
          style={{ position: "absolute", top: 560 }}
        />
      </div>
      {/* alerts */}
      <AlertInfo
        open={eventAlertOpen}
        setOpen={setEventAlertOpen}
        title={t("how_does_it_work")}
        description={t("event_mission_info")}
        descriptionAlign="center"
      />
      <QrScanAlert
        open={qrScanOpen}
        setOpen={setQrScanOpen}
        setQrText={setQrText}
      />
      <Alert
        open={wrongIdAlertOpen}
        title={t("something_is_wrong")}
        description={t("wrong_area")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setWrongIdAlertOpen(false)
        }}
      />
      <Alert
        open={scanLockedAlertOpen}
        title={t("scan_locked_title")}
        description={t("scan_locked_description")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setScanLockedAlertOpen(false)
        }}
      />
      {/* loading overlay */}
      <LoadingOverlay open={loading} />
      {/* feedbacks */}
      <CheckInMissionCompletedFeedback
        open={missionCompletedAlertOpen}
        setOpen={setMissionCompletedAlertOpen}
        areaName={checkInMission.title}
      />
    </Stack>
  )
}

export default AreaDetailsDesktop
