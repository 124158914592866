import { useContext, useEffect, useMemo, useState } from "react"
import { MainContext } from "../../controllers/main"
import ChallengeMobile from "../../components/challenge/ChallengeMobile"
import ChallengeDesktop from "../../components/challenge/ChallengeDesktop"
import { registerPageEvent } from "../../services/utils/utils"
import Prize from "../../models/prize"
import prize1Image from "../../assets/images/prizes/prizes-1.png"
import prize2Image from "../../assets/images/prizes/prizes-2.png"
import prize3Image from "../../assets/images/prizes/prizes-3.png"
import prize4Image from "../../assets/images/prizes/prizes-4.png"
import prize5Image from "../../assets/images/prizes/prizes-5.png"
import MetaPixelEvent from "../../components/global/common/MetaPixelEvent"

const Challenge = () => {
  const { isMobile } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("challenge")
  }, [])

  // prizes info alert
  const [prizesAlertOpen, setPrizesAlertOpen] = useState<boolean>(false)

  // array of prizes
  const prizes: Prize[] = useMemo(
    () => [
      {
        heading: "1° classificato",
        title:
          "20 Ingressi piscine comunali, 8 ingressi Musei, 4 biglietti concerti MITO",
        image: prize1Image,
      },
      {
        heading: "2° classificato",
        title:
          "10 Ingressi piscine comunali, 6 ingressi Musei, 2 biglietti concerti MITO",
        image: prize2Image,
      },
      {
        heading: "3° classificato",
        title:
          "10 Ingressi piscine comunali, 4 ingressi Musei, 2 biglietti concerti MITO",
        image: prize3Image,
      },
      {
        heading: "Dal 4° al 16° classificato",
        title: "2 ingressi Musei, 2 biglietti concerti MITO",
        image: prize4Image,
      },
      {
        heading: "Dal 17° al 30° classificato",
        title: "2 ingressi Musei",
        image: prize5Image,
      },
    ],
    []
  )

  return isMobile ? (
    <>
      <MetaPixelEvent page="Challenge" />
      <ChallengeMobile
        prizesAlertOpen={prizesAlertOpen}
        setPrizesAlertOpen={setPrizesAlertOpen}
        prizes={prizes}
      />
    </>
  ) : (
    <>
      <MetaPixelEvent page="Challenge" />
      <ChallengeDesktop
        prizesAlertOpen={prizesAlertOpen}
        setPrizesAlertOpen={setPrizesAlertOpen}
        prizes={prizes}
      />
    </>
  )
}

export default Challenge
