import { Grow, Modal, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../../controllers/main"
import Button from "../../global/common/Button"
import { t } from "i18next"
import Title from "../../global/common/Title"
import Text from "../../global/common/Text"
import PointsBadge from "../../global/common/PointsBadge"
import { checkInMissionsPoints } from "../../../services/config/constants"
import { useNavigate } from "react-router-dom"
import { scrollWindowToTop } from "../../../services/utils/utils"

const CheckInMissionCompletedFeedback = ({
  open,
  setOpen,
  areaName,
  fromQr = false,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  areaName: string
  fromQr?: boolean
}) => {
  const navigate = useNavigate()
  const { user } = useContext(MainContext)

  return (
    <Modal
      open={open}
      aria-labelledby={t("qr_code_scanned")}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
      }}
    >
      <Grow in={open} mountOnEnter unmountOnExit>
        <div
          style={{
            backgroundColor: colors.backgroundWhite,
            width: "calc(100% - 44px)",
            maxWidth: 375,
            height: "auto",
            borderRadius: 16,
            padding: 24,
            paddingTop: user && user.profileImage ? 72 : 24,
            position: "relative",
          }}
        >
          {user && user.profileImage ? (
            <img
              src={user.profileImage}
              style={{
                width: 78,
                height: 78,
                borderRadius: "100%",
                position: "absolute",
                top: -39,
                left: 0,
                right: 0,
                marginInline: "auto",
              }}
              alt=""
            />
          ) : null}
          <Title
            fontSize={26}
            lineHeight="30.4px"
            paddingLeft={10}
            paddingRight={10}
            color={colors.text}
            textAlign="center"
            id={t("event_mission_completed")}
          >
            {t("event_mission_completed")}
          </Title>
          <Stack alignItems="center" style={{ marginTop: 14 }}>
            <Text fontSize={18} lineHeight="24px" fontWeight={400}>
              {t("you_visited_the_area")}
            </Text>
            <Text
              fontSize={18}
              lineHeight="24px"
              fontWeight={600}
              textAlign="center"
            >
              "{areaName}"
            </Text>
            <Text
              fontSize={12}
              lineHeight="24px"
              fontWeight={400}
              style={{ marginTop: 14 }}
            >
              {t("points_earned").toUpperCase()}
            </Text>
            <PointsBadge
              points={checkInMissionsPoints}
              style={{ marginTop: 4 }}
            />
          </Stack>
          <Button
            width="100%"
            style={{ marginTop: 24 }}
            title={fromQr ? t("continue") : t("back_to_missions")}
            onClick={() => {
              if (fromQr) {
                setOpen(false)
              } else {
                scrollWindowToTop()
                navigate("/")
              }
            }}
          >
            {fromQr ? t("continue") : t("back_to_missions")}
          </Button>
        </div>
      </Grow>
    </Modal>
  )
}

export default CheckInMissionCompletedFeedback
