import { Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Episode from "../../../models/episode"
import Text from "../../global/common/Text"
import Button from "../../global/common/Button"
import playBlackIcon from "../../../assets/icons/play-black.svg"
import checkPrimaryIcon from "../../../assets/icons/check.png"
import closePrimaryIcon from "../../../assets/icons/close-primary.png"
import removePrimaryIcon from "../../../assets/icons/remove-primary.png"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { cacheEpisodeImages } from "../../../services/utils/utils"
import { Dispatch, SetStateAction, useState } from "react"

const EpisodeCardDesktop = ({
  episode,
  episodeNumber,
  loading,
  setLoading,
}: {
  episode: Episode
  episodeNumber: number
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  // loading for episode images caching
  const [cachingImages, setCachingImages] = useState<boolean>(false)

  return (
    <Stack
      style={{
        width: "100%",
        height: 370,
        backgroundColor: colors.backgroundWhite,
        boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.08)",
        borderRadius: 16,
        padding: 5,
        position: "relative",
      }}
    >
      {/* episode image */}
      <img
        src={episode.image}
        style={{
          width: "100%",
          height: 209,
          objectFit: "cover",
          backgroundColor: episode.slides.find(
            (item) => item.slideType === "TitleSlide"
          )!.box
            ? episode.slides.find((item) => item.slideType === "TitleSlide")!
                .box!.boxBackColor
            : colors.backgroundWhite,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        }}
        alt=""
      />
      {/* episode details */}
      <Stack
        style={{
          width: "100%",
          height: 161,
          backgroundColor: colors.backgroundWhite,
          borderRadius: 16,
          position: "absolute",
          bottom: 0,
          left: 0,
          paddingInline: 18,
          paddingTop: 22,
        }}
      >
        <Text
          fontSize={18}
          lineHeight="21px"
          fontWeight={700}
          style={{
            maxWidth: "100%",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
            height: 44,
            wordWrap: "break-word",
          }}
        >
          {episode.title}
        </Text>
        {/* completion badge */}
        <div
          style={{
            width: "auto",
            height: 30,
            borderRadius: 4,
            paddingInline: 10,
            gap: 10,
            backgroundColor: colors.background,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: -43,
            right: 18,
          }}
        >
          <Text fontSize={12} fontWeight={700}>
            {episode.result.completed && episode.result.success
              ? t("completed_m")
              : episode.result.completed && !episode.result.success
              ? t("failed")
              : t("to_be_completed")}
          </Text>
          <div
            style={{
              width: 10,
              height: 7,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 1,
            }}
          >
            {episode.result.completed && episode.result.success ? (
              <img
                src={checkPrimaryIcon}
                style={{ width: 10, height: 7 }}
                alt=""
              />
            ) : episode.result.completed && !episode.result.success ? (
              <img
                src={closePrimaryIcon}
                style={{ width: 7, height: 7 }}
                alt=""
              />
            ) : (
              <img
                src={removePrimaryIcon}
                style={{ width: 7, height: 2 }}
                alt=""
              />
            )}
          </div>
        </div>
        <Button
          loading={cachingImages}
          width="100%"
          outlined={episode.result.completed}
          startIcon={<img src={playBlackIcon} style={{ width: 16 }} alt="" />}
          style={{ marginTop: 20 }}
          onClick={async () => {
            if (!loading) {
              setLoading(true)
              setCachingImages(true)
              await cacheEpisodeImages(episode)
              setLoading(false)
              setCachingImages(false)
              navigate(`/journey/${episode.id}/0`)
            }
          }}
          title={
            episode.result.completed
              ? t("watch_episode_again", { number: episodeNumber })
              : `${t("watch_episode", { number: episodeNumber })}`
          }
        >
          {episode.result.completed
            ? t("watch_episode_again", { number: episodeNumber })
            : `${t("episode")} ${episodeNumber}`}
        </Button>
      </Stack>
    </Stack>
  )
}

export default EpisodeCardDesktop
