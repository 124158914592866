import { ButtonBase, Stack } from "@mui/material"
import Appear from "../../animations/Appear"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import Button from "../../global/common/Button"
import { scrollWindowToTop } from "../../../services/utils/utils"
import { useNavigate } from "react-router-dom"
import infoIconPrimary from "../../../assets/icons/info-primary.svg"
import { CSSProperties, Dispatch, SetStateAction, useContext } from "react"
import { t } from "i18next"
import { MainContext } from "../../../controllers/main"

const EventHelpCardDesktop = ({
  setEventAlertOpen,
  style,
}: {
  setEventAlertOpen: Dispatch<SetStateAction<boolean>>
  style?: CSSProperties
}) => {
  const navigate = useNavigate()
  const { user } = useContext(MainContext)

  return (
    <Appear style={{ position: "absolute", right: 0, ...style }} fade>
      <Stack gap={2}>
        <ButtonBase
          aria-label={t("how_does_it_work")}
          style={{ borderRadius: 15 }}
          onClick={() => {
            setEventAlertOpen(true)
          }}
        >
          <Stack
            style={{
              width: 260,
              height: "auto",
              borderRadius: 15,
              border: "1.5px solid " + colors.primaryDark,
              padding: 14,
              backgroundColor: colors.backgroundWhite,
              position: "relative",
            }}
          >
            <Stack direction="row" alignItems="center" gap={1.5}>
              <img
                src={user?.profileImage}
                style={{
                  width: 76,
                  height: 76,
                  objectFit: "cover",
                  objectPosition: "center",
                  borderRadius: 8,
                }}
                alt=""
              />
              <Title fontSize={20} color={colors.primaryDark}>
                {t("how_does_it_work")}
              </Title>
            </Stack>
            <img
              src={infoIconPrimary}
              style={{
                width: 20,
                height: 20,
                position: "absolute",
                top: 8,
                right: 8,
              }}
              alt=""
            />
          </Stack>
        </ButtonBase>
        <Button
          title={t("back_to_all_missions")}
          outlined
          width="100%"
          onClick={() => {
            scrollWindowToTop()
            navigate("/")
          }}
        >
          {t("back_to_all_missions")}
        </Button>
      </Stack>
    </Appear>
  )
}

export default EventHelpCardDesktop
