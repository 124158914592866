import { useContext, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import JourneyMobile from "../../components/journey/JourneyMobile"
import JourneyDesktop from "../../components/journey/JourneyDesktop"
import { registerPageEvent } from "../../services/utils/utils"
import { useNavigate } from "react-router-dom"
import { JourneysContext } from "../../controllers/journeys"
import MetaPixelEvent from "../../components/global/common/MetaPixelEvent"

const Journey = () => {
  const navigate = useNavigate()
  const { isMobile, currentMission } = useContext(MainContext)
  const { journey, setJourney, journeys } = useContext(JourneysContext)

  // redirect to landing if currentMission isn't present
  useEffect(() => {
    if (!currentMission) {
      navigate("/")
    }
  }, [])

  // register google analytics event
  useEffect(() => {
    registerPageEvent("journey")
  }, [])

  // set journey searching the correct one from the list
  useEffect(() => {
    setJourney(
      journeys.find((item) => item.id === currentMission?.missionTypeId)!
    )
  }, [])

  return !journey ||
    (journey &&
      journey.id !== currentMission?.missionTypeId) ? null : isMobile ? (
    <>
      <MetaPixelEvent page="Journey" />
      <JourneyMobile />
    </>
  ) : (
    <>
      <MetaPixelEvent page="Journey" />
      <JourneyDesktop />
    </>
  )
}

export default Journey
