import { Grow, Modal, Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext, useMemo } from "react"
import { colors } from "../../../services/config/colors"
import { useTranslation } from "react-i18next"
import { MainContext } from "../../../controllers/main"
import Title from "../../global/common/Title"
import Button from "../../global/common/Button"
import PrizeListItem from "../../challenge/common/PrizeListItem"
import Prize from "../../../models/prize"
import prize1Image from "../../../assets/images/prizes/prizes-1.png"
import prize2Image from "../../../assets/images/prizes/prizes-2.png"
import prize3Image from "../../../assets/images/prizes/prizes-3.png"
import prize4Image from "../../../assets/images/prizes/prizes-4.png"
import prize5Image from "../../../assets/images/prizes/prizes-5.png"

const PrizesAlert = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const { user } = useContext(MainContext)

  // array of prizes
  const prizes: Prize[] = useMemo(
    () => [
      {
        heading: "1° classificato",
        title:
          "20 Ingressi piscine comunali, 8 ingressi Musei, 4 biglietti concerti MITO",
        image: prize1Image,
      },
      {
        heading: "2° classificato",
        title:
          "10 Ingressi piscine comunali, 6 ingressi Musei, 2 biglietti concerti MITO",
        image: prize2Image,
      },
      {
        heading: "3° classificato",
        title:
          "10 Ingressi piscine comunali, 4 ingressi Musei, 2 biglietti concerti MITO",
        image: prize3Image,
      },
      {
        heading: "Dal 4° al 16° classificato",
        title: "2 ingressi Musei, 2 biglietti concerti MITO",
        image: prize4Image,
      },
      {
        heading: "Dal 17° al 30° classificato",
        title: "2 ingressi Musei",
        image: prize5Image,
      },
    ],
    []
  )

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      aria-labelledby="title"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
      }}
    >
      <Grow in={open} mountOnEnter unmountOnExit>
        <div
          style={{
            backgroundColor: colors.backgroundWhite,
            width: "calc(100% - 44px)",
            maxWidth: 500,
            height: "auto",
            maxHeight: "90vh",
            overflowY: "scroll",
            borderRadius: 16,
            padding: 24,
            paddingTop: user && user.profileImage ? 72 : 24,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          {user && user.profileImage ? (
            <img
              src={user.profileImage}
              style={{
                width: 78,
                height: 78,
                borderRadius: "100%",
                position: "absolute",
                top: -39,
                left: 0,
                right: 0,
                marginInline: "auto",
                zIndex: 2,
              }}
              alt=""
            />
          ) : null}
          <Title id="title" lineHeight="30.4px" textAlign="center">
            Premi in palio
          </Title>
          <Stack>
            <Stack
              style={{
                width: "100%",
                height: "auto",
                borderRadius: 11,
                marginTop: 16,
                gap: 26,
              }}
            >
              {prizes.map((prize, index) => (
                <PrizeListItem
                  key={index}
                  heading={prize.heading}
                  title={prize.title}
                  image={prize.image}
                />
              ))}
            </Stack>
          </Stack>
          <Button
            width="100%"
            style={{ marginTop: 24, minHeight: 56 }}
            onClick={() => {
              setOpen(false)
            }}
            title={t("i_understand")}
          >
            {t("i_understand")}
          </Button>
        </div>
      </Grow>
    </Modal>
  )
}

export default PrizesAlert
