import { ButtonBase, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import Avatar from "../../../models/avatar"

const AvatarButton = ({
  avatar,
  selected,
  current,
  onClick,
  disabled,
}: {
  avatar: Avatar
  selected: boolean
  current: boolean
  onClick: () => void
  disabled: boolean
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)

  return (
    <Stack
      alignItems="center"
      style={{ width: "100%", height: "auto", gap: 8 }}
    >
      <ButtonBase
        style={{
          width: "100%",
          borderRadius: "100%",
          position: "relative",
        }}
        disabled={disabled}
        onClick={onClick}
        aria-label={
          current
            ? `${t("current_avatar")}: ${t(avatar.name)}`
            : `${t("avatar")}:  ${t(avatar.name)}, ${t("edit_avatar_info", {
                buttonName: t("save_change"),
              })}`
        }
        role="checkbox"
        aria-checked={selected ? "true" : "false"}
        aria-live={current ? "assertive" : "off"}
      >
        <img
          src={avatar.url}
          style={{
            width: "100%",
            borderRadius: "100%",
          }}
          alt=""
        />
        <div
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "100%",
            border:
              !selected && current
                ? "3px solid #90C8D0"
                : "3px solid " + colors.primaryDark,
            position: "absolute",
            opacity: selected || current ? 1 : 0,
            transition: "150ms",
          }}
          aria-hidden={selected || current ? "false" : "true"}
        />
      </ButtonBase>
      <Title
        fontSize={isMobile ? 14 : 16}
        color={colors.primaryDark}
        style={{ opacity: selected ? 1 : 0, transition: "100ms" }}
        ariaHidden
      >
        {current ? t("current") : t("selected")}
      </Title>
    </Stack>
  )
}

export default AvatarButton
