import { ButtonBase, CircularProgress, Stack } from "@mui/material"
import playCircleGrayIcon from "../../../assets/icons/play-circle-gray.svg"
import playCirclePrimaryIcon from "../../../assets/icons/play-circle-primary.svg"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import checkPrimaryIcon from "../../../assets/icons/check.png"
import closePrimaryIcon from "../../../assets/icons/close-primary.png"
import removePrimaryIcon from "../../../assets/icons/remove-primary.png"
import playBlackIcon from "../../../assets/icons/play-black.svg"
import { JourneysContext } from "../../../controllers/journeys"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import Episode from "../../../models/episode"
import {
  cacheEpisodeImages,
  registerEvent,
} from "../../../services/utils/utils"
import Button from "../../global/common/Button"
import AppearFromSide from "../../animations/AppearFromSide"
import FadeFromBottom from "../../animations/FadeFromBottom"
import Text from "../../global/common/Text"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const JourneyCardMobile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { journey } = useContext(JourneysContext)

  // loading for episode items and button
  const [loading, setLoading] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  return (
    <Stack
      style={{
        width: "calc(100% - 32px)",
        marginLeft: 16,
        marginTop: -134,
        borderRadius: 16,
        overflow: "hidden",
        boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.16)",
        position: "relative",
      }}
    >
      {/* card header */}
      <Stack
        style={{
          width: "100%",
          height: 134,
          backgroundColor: colors.backgroundWhite,
          paddingTop: 20,
          position: "relative",
        }}
        alignItems="center"
      >
        <Title
          fontSize={20}
          lineHeight="26px"
          paddingLeft={40}
          paddingRight={40}
          textAlign="center"
          style={{
            width: "100%",
            height: 52,
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
          component="h1"
        >
          {journey?.title}
        </Title>
        <Stack
          direction="row"
          justifyContent="center"
          gap={1}
          style={{ width: "100%", height: 24, marginTop: 16 }}
        >
          <div
            style={{
              width: "auto",
              height: "100%",
              paddingInline: 6,
              display: "flex",
              alignItems: "center",
              borderRadius: 4,
              backgroundColor: journey?.category.backTagColor,
            }}
          >
            <Text
              fontSize={14}
              fontWeight={700}
              color={journey?.category.foreColor}
            >
              {journey?.category.name}
            </Text>
          </div>
          {journey?.sdg ? (
            <div
              style={{
                width: "auto",
                height: "100%",
                paddingInline: 6,
                display: "flex",
                alignItems: "center",
                borderRadius: 4,
                backgroundColor: colors.backgroundWhite,
                border: "1px solid " + colors.primaryDark,
              }}
            >
              <Text fontSize={14} fontWeight={700} color={colors.primaryDark}>
                {t("sdg")} {journey.sdg}
              </Text>
            </div>
          ) : null}
        </Stack>
      </Stack>
      {/* episode items list */}
      <Stack
        style={{
          width: "100%",
          backgroundColor: colors.backgroundWhite,
          paddingBottom: 96,
        }}
        alignItems="center"
      >
        {journey!.episodes.map((episode, index) => (
          <AppearFromSide
            key={episode.id}
            fade
            delay={0.05 * (index + 1)}
            style={{ width: "100%" }}
          >
            <EpisodeListItem
              episode={episode}
              loading={loading}
              setLoading={setLoading}
            />
          </AppearFromSide>
        ))}
      </Stack>
      {/* button */}
      <div
        style={{
          width: "100%",
          minHeight: 44,
          position: "absolute",
          left: 0,
          bottom: 0,
          backgroundColor: colors.backgroundWhite,
          borderBottomRightRadius: 16,
          borderBottomLeftRadius: 16,
        }}
      />
      <div
        style={{
          width: "100%",
          minHeight: 76,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          position: "absolute",
          left: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            width: "calc(100% - 32px)",
            height: 56,
            backgroundColor: colors.backgroundWhite,
            borderRadius: 16,
          }}
        >
          <FadeFromBottom delay={0.05} style={{ width: "100%" }}>
            <Button
              width="100%"
              loading={buttonLoading}
              startIcon={
                <img src={playBlackIcon} style={{ width: 16 }} alt="" />
              }
              onClick={async () => {
                if (loading) {
                  return
                }

                setLoading(true)
                setButtonLoading(true)

                // push first episode of current journey if they are all completed
                if (
                  !journey!.episodes.find(
                    (episode) => !episode.result.completed
                  )
                ) {
                  registerEvent("open_view", {
                    view: "episode",
                    data: journey!.episodes[0].id,
                  })

                  await cacheEpisodeImages(journey!.episodes[0])
                  navigate(`/journey/${journey!.episodes[0].id}/0`)

                  setLoading(false)
                  setButtonLoading(false)
                  return
                }

                // push next episode to complete
                const episodeToPush = journey!.episodes.find(
                  (episode) => !episode.result.completed
                )!

                registerEvent("open_view", {
                  view: "episode",
                  data: episodeToPush.id,
                })

                await cacheEpisodeImages(episodeToPush)
                navigate(`/journey/${episodeToPush.id}/0`)

                setLoading(false)
                setButtonLoading(false)
              }}
              title={
                journey!.episodes.filter((episode) => !episode.result.completed)
                  .length
                  ? `${t("episode")} ` +
                    (journey!.episodes.findIndex(
                      (episode) => !episode.result.completed
                    ) +
                      1)
                  : t("watch_episodes_again")
              }
            >
              {journey!.episodes.filter((episode) => !episode.result.completed)
                .length
                ? `${t("episode")} ` +
                  (journey!.episodes.findIndex(
                    (episode) => !episode.result.completed
                  ) +
                    1)
                : t("watch_episodes_again")}
            </Button>
          </FadeFromBottom>
        </div>
      </div>
    </Stack>
  )
}

const EpisodeListItem = ({
  episode,
  loading,
  setLoading,
}: {
  episode: Episode
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  // loading for caching spinner
  const [cachingImages, setCachingImages] = useState<boolean>(false)

  return (
    <ButtonBase
      disabled={loading}
      style={{ width: "100%" }}
      onClick={async () => {
        setLoading(true)
        setCachingImages(true)

        await cacheEpisodeImages(episode)
        registerEvent("open_view", {
          view: "episode",
          data: episode.id,
        })

        setLoading(false)
        setCachingImages(false)

        navigate(window.location.pathname + `/${episode.id}/0`)
      }}
      aria-label={episode.title}
    >
      <div
        style={{
          width: "100%",
          minHeight: 98,
          display: "flex",
          alignItems: "center",
          position: "relative",
          paddingInline: 18,
        }}
      >
        {cachingImages && (
          <CircularProgress
            style={{
              position: "absolute",
              top: 31,
              left: 26,
              color: episode.result.completed ? "#545454" : colors.primaryDark,
            }}
            size="36px"
          />
        )}
        <Stack
          direction="row"
          style={{ width: "100%", paddingInline: 12, gap: 18 }}
          alignItems="center"
        >
          <img
            src={
              episode.result.completed
                ? playCircleGrayIcon
                : playCirclePrimaryIcon
            }
            style={{ width: 28, height: 28 }}
            id={`play-icon-${episode.id}`}
            alt=""
          />
          <Stack gap={1} style={{ width: "100%" }}>
            <Title
              fontSize={16}
              lineHeight="20px"
              textAlign="left"
              style={{
                minWidth: "100%",
                maxWidth: "100%",
                maxHeight: 40,
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                overflow: "hidden",
                wordWrap: "break-word",
              }}
              color={colors.text}
            >
              {episode.title}
            </Title>
            {/* completion badge */}
            <div
              style={{
                width: "fit-content",
                height: 22,
                borderRadius: 4,
                paddingInline: 6,
                gap: 10,
                backgroundColor: colors.background,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text fontSize={12} fontWeight={700} color={colors.primaryDark}>
                {episode.result.completed && episode.result.success
                  ? t("completed_m")
                  : episode.result.completed && !episode.result.success
                  ? t("failed")
                  : t("to_be_completed")}
              </Text>
              <div
                style={{
                  width: 10,
                  height: 7,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 1,
                }}
              >
                {episode.result.completed && episode.result.success ? (
                  <img
                    src={checkPrimaryIcon}
                    style={{ width: 10, height: 7 }}
                    alt=""
                  />
                ) : episode.result.completed && !episode.result.success ? (
                  <img
                    src={closePrimaryIcon}
                    style={{ width: 7, height: 7 }}
                    alt=""
                  />
                ) : (
                  <img
                    src={removePrimaryIcon}
                    style={{ width: 7, height: 2 }}
                    alt=""
                  />
                )}
              </div>
            </div>
          </Stack>
        </Stack>
        <div
          style={{
            width: "calc(100% - 32px)",
            height: 0.5,
            position: "absolute",
            bottom: 0,
            backgroundColor: colors.stroke,
          }}
        />
      </div>
    </ButtonBase>
  )
}

export default JourneyCardMobile
