import CheckInArea from "../../models/checkInArea"
import camminata1Image from "../../assets/images/checkIn/camminata-1.jpg"
import camminata2Image from "../../assets/images/checkIn/camminata-2.jpg"
import incontro1Image from "../../assets/images/checkIn/incontro-1.jpg"
import incontro2Image from "../../assets/images/checkIn/incontro-2.jpg"
import spettacolo1Image from "../../assets/images/checkIn/spettacolo-1.jpeg"
import spettacolo2Image from "../../assets/images/checkIn/spettacolo-2.jpg"
import spettacolo3Image from "../../assets/images/checkIn/spettacolo-3.jpeg"
import spettacolo4Image from "../../assets/images/checkIn/spettacolo-4.jpeg"
import spettacolo5Image from "../../assets/images/checkIn/spettacolo-5.jpg"
import spettacolo6Image from "../../assets/images/checkIn/spettacolo-6.jpg"

export const checkInAreas: CheckInArea[] = [
  {
    id: "piazza_galimberti_27_05",
    qrId: "piazza_galimberti_27_05",
    title: "Parrocchia Madonna delle Rose",
    subtitle: 'Scansiona il QR Code "Parrocchia Madonna delle Rose"',
    description: `27 maggio ore 17.00, oratorio della Parrocchia Madonna delle Rose, con ingresso da corso Unione Sovietica 221<br />
    GIOCA ALLA FIABA: GIOVANNIN SENZA PAURA<br /><br />
    <strong>Uno spettacolo per divertirsi insieme ai più piccoli, e pensare a una Torino più accogliente e attrattiva per giovani genitori, famiglie, bambini e ragazzi.</strong><br /><br />
    Un viaggio nella fantasia della tradizione popolare, guidato dagli attori della Casa del Teatro, dedicato ai bambini, alle bambine e alle loro famiglie, alla scoperta del comune patrimonio di racconti che unisce le generazioni. Un appuntamento in forma di “festa teatrale” attraverso cui gli attori, accompagnati da musica suonata dal vivo, “giocheranno al teatro” assieme al pubblico.<br /><br />
    Durata: 1 ora<br />
    Progetto a cura della FONDAZIONE TRG<br />
    Con Claudio Dughera, Simone Valentino<br />
    Alla fisarmonica Vanni Pertusio`,
    image: spettacolo1Image,
    district: "",
  },
  {
    id: "via_scipio_slataper_28_05",
    qrId: "via_scipio_slataper_28_05",
    title: "Giardini di via Scipio Slataper",
    subtitle: 'Scansiona il QR Code "Giardini di via Scipio Slataper"',
    description: `28 maggio ore 17.00, giardini di via Scipio Slataper<br />
    GIOCA ALLA FIABA: GIOVANNIN SENZA PAURA<br /><br />
    <strong>Uno spettacolo per divertirsi insieme ai più piccoli, e pensare a una Torino più accogliente e attrattiva per giovani genitori, famiglie, bambini e ragazzi.</strong><br /><br />
    Un viaggio nella fantasia della tradizione popolare, guidato dagli attori della Casa del Teatro, dedicato ai bambini, alle bambine e alle loro famiglie, alla scoperta del comune patrimonio di racconti che unisce le generazioni. Un appuntamento in forma di “festa teatrale” attraverso cui gli attori, accompagnati da musica suonata dal vivo, “giocheranno al teatro” assieme al pubblico.<br /><br />
    Durata: 1 ora<br />
    Progetto a cura della FONDAZIONE TRG<br />
    Con Claudio Dughera, Simone Valentino<br />
    Alla fisarmonica Vanni Pertusio`,
    image: spettacolo2Image,
    district: "",
  },
  {
    id: "beeozanam_29_05",
    qrId: "beeozanam_29_05",
    title: "BeeOzanam",
    subtitle: 'Scansiona il QR Code "BeeOzanam"',
    description: `29 maggio ore 18.00, via Foligno 14<br />
    INCONTRO presso BeeOzanam<br /><br />
    <strong>Un’occasione di incontro e scambio per discutere insieme di offerta di servizi di prossimità, spazi pubblici e spazi verdi, mobilità da e per i quartieri, abitare e accesso alla casa.</strong><br /><br />
    Durata: 2 ore<br />
    <a href="https://www.eventbrite.it/e/voci-di-quartiere-incontro-presso-beeozanam-tickets-905915897837" target="_blank">Prenota un posto</a>`,
    image: incontro1Image,
    district: "",
  },
  {
    id: "giardino_ugo_pecchioli_30_05",
    qrId: "giardino_ugo_pecchioli_30_05",
    title: "Centro Lanzo",
    subtitle: 'Scansiona il QR Code "Centro Lanzo"',
    description: `30 maggio ore 17.00, Centro Lanzo, Via Lanzo 144<br />
    GIOCA ALLA FIABA: IL CONTADINO ASTROLOGO<br /><br />
    <strong>Uno spettacolo per divertirsi insieme ai più piccoli, e pensare a una Torino più accogliente e attrattiva per giovani genitori, famiglie, bambini e ragazzi.</strong><br /><br />
    Un viaggio nella fantasia della tradizione popolare, guidato dagli attori della Casa del Teatro, dedicato ai bambini, alle bambine e alle loro famiglie, alla scoperta del comune patrimonio di racconti che unisce le generazioni. Un appuntamento in forma di “festa teatrale” attraverso cui gli attori, accompagnati da musica suonata dal vivo, “giocheranno al teatro” assieme al pubblico.<br /><br />
    Durata: 2 ore<br />
    Progetto a cura della FONDAZIONE TRG<br />
    Con Simone Valentino, Francesca Alongi<br />
    Alla fisarmonica Vanni Pertusio`,
    image: spettacolo3Image,
    district: "",
  },
  {
    id: "casa_oz_03_06",
    qrId: "casa_oz_03_06",
    title: "Casa Oz",
    subtitle: 'Scansiona il QR Code "Casa Oz"',
    description: `3 giugno ore 17.00, corso Moncalieri 262, Casa Oz<br />
    GIOCA ALLA FIABA: BELMIELE E BELSOLE<br /><br />
    <strong>Uno spettacolo per divertirsi insieme ai più piccoli, e pensare a una Torino più accogliente e attrattiva per giovani genitori, famiglie, bambini e ragazzi.</strong><br /><br />
    Un viaggio nella fantasia della tradizione popolare, guidato dagli attori della Casa del Teatro, dedicato ai bambini, alle bambine e alle loro famiglie, alla scoperta del comune patrimonio di racconti che unisce le generazioni. Un appuntamento in forma di “festa teatrale” attraverso cui gli attori, accompagnati da musica suonata dal vivo, “giocheranno al teatro” assieme al pubblico.<br /><br />
    Durata: 2 ore<br />
    Progetto a cura della FONDAZIONE TRG<br />
    Con Gabriele Filosa, Rossana Peraccio<br />
    Alla fisarmonica Vanni Pertusio`,
    image: spettacolo4Image,
    district: "",
  },
  {
    id: "parco_michelotti_04_06",
    qrId: "parco_michelotti_04_06",
    title: "Area gioco di Parco Michelotti",
    subtitle: 'Scansiona il QR Code "Area gioco di Parco Michelotti"',
    description: `4 giugno ore 17.00, area gioco di Parco Michelotti<br />
    GIOCA ALLA FIABA: GIOVANNIN SENZA PAURA<br /><br />
    <strong>Uno spettacolo per divertirsi insieme ai più piccoli, e pensare a una Torino più accogliente e attrattiva per giovani genitori, famiglie, bambini e ragazzi.</strong><br /><br />
    Un viaggio nella fantasia della tradizione popolare, guidato dagli attori della Casa del Teatro, dedicato ai bambini, alle bambine e alle loro famiglie, alla scoperta del comune patrimonio di racconti che unisce le generazioni. Un appuntamento in forma di “festa teatrale” attraverso cui gli attori, accompagnati da musica suonata dal vivo, “giocheranno al teatro” assieme al pubblico.<br /><br />
    Durata: 1 ora<br />
    Progetto a cura della FONDAZIONE TRG<br />
    Con Claudio Dughera, Simone Valentino<br />
    Alla fisarmonica Vanni Pertusio`,
    image: spettacolo5Image,
    district: "",
  },
  {
    id: "via_beato_cafasso_05_06",
    qrId: "via_beato_cafasso_05_06",
    title: "Giardini di via Beato Cafasso",
    subtitle: 'Scansiona il QR Code "Giardini di via Beato Cafasso"',
    description: `5 giugno ore 17.00, giardini di via Beato Cafasso<br />
    GIOCA ALLA FIABA: BELMIELE E BELSOLE<br /><br />
    <strong>Uno spettacolo per divertirsi insieme ai più piccoli, e pensare a una Torino più accogliente e attrattiva per giovani genitori, famiglie, bambini e ragazzi.</strong><br /><br />
    Un viaggio nella fantasia della tradizione popolare, guidato dagli attori della Casa del Teatro, dedicato ai bambini, alle bambine e alle loro famiglie, alla scoperta del comune patrimonio di racconti che unisce le generazioni. Un appuntamento in forma di “festa teatrale” attraverso cui gli attori, accompagnati da musica suonata dal vivo, “giocheranno al teatro” assieme al pubblico.<br /><br />
    Durata: 1 ora<br />
    Progetto a cura della FONDAZIONE TRG<br />
    Con Gabriele Filosa, Rossana Peraccio<br />
    Alla fisarmonica Vanni Pertusio`,
    image: spettacolo6Image,
    district: "",
  },
  {
    id: "sede_urban_lab_05_06",
    qrId: "sede_urban_lab_05_06",
    title: "Sede di Urban Lab",
    subtitle: 'Scansiona il QR Code "Sede di Urban Lab"',
    description: `<strong>TURNING POINT. Rivelare il futuro</strong><br /><br />
    Visita la mostra dedicata ai due grandi progetti che trasformeranno il Valentino e la Cavallerizza Reale. Due grandi trasformazioni che costituiscono un vero punto di svolta nel più generale processo di cambiamento di Torino, e che toccano il cuore verde e quello barocco della città.<br /><br />
    Ti aspettiamo presso la sede pubblica di Urban Lab, in Piazza Palazzo di città 8f con un percorso di visita che approfondisce i due progetti con dati, immagini, interviste ai protagonisti.<br /><br />
    INGRESSO LIBERO<br />
    DAL MERCOLEDÌ AL VENERDÌ 14 - 19<br />
    SABATO 11 - 19`,
    image: spettacolo6Image,
    district: "",
  },
  {
    id: "piazza_eugenio_montale_06_06",
    qrId: "piazza_eugenio_montale_06_06",
    title: "Piazza Eugenio Montale",
    subtitle: 'Scansiona il QR Code "Piazza Eugenio Montale"',
    description: `6 giugno ore 17.30, piazza Eugenio Montale<br />
    PASSEGGIATA<br /><br />
    <strong>Una camminata nel quartiere Vallette per guardare da vicino gli spazi pubblici, gli spazi verdi e i luoghi di aggregazione, discutere delle problematiche emergenti e parlare del futuro.</strong><br /><br />
    Durata: 2 ore<br />
    <a href="https://www.eventbrite.it/e/voci-di-quartiere-passeggiata-attorno-a-piazza-eugenio-montale-tickets-905919970017?aff=oddtdtcreator" target="_blank">Prenota un posto</a>`,
    image: camminata1Image,
    district: "",
  },
  {
    id: "piazza_chiesa_della_salute_12_06",
    qrId: "piazza_chiesa_della_salute_12_06",
    title: "Piazza Chiesa della Salute",
    subtitle: 'Scansiona il QR Code "Piazza Chiesa della Salute"',
    description: `12 giugno ore 18.00, piazza Chiesa della Salute<br />
    PASSEGGIATA<br /><br />
    <strong>Una camminata nel quartiere di Borgo Vittoria per guardare da vicino gli spazi pubblici, gli spazi verdi e i luoghi di aggregazione, discutere delle problematiche emergenti e parlare del futuro. Partenza da piazza Chiesa della Salute e arrivo a SCIA131, via Sospello 131.</strong><br /><br />
    Durata: 2 ore<br />
    <a href="https://www.eventbrite.it/e/voci-di-quartiere-passeggiata-da-piazza-chiesa-della-salute-a-scia131-tickets-905921394277" target="_blank">Prenota un posto</a>`,
    image: camminata2Image,
    district: "",
  },
  {
    id: "cap10100_13_06",
    qrId: "cap10100_13_06",
    title: "CAP10100",
    subtitle: 'Scansiona il QR Code "CAP10100"',
    description: `13 giugno ore 18.00, corso Moncalieri 18<br />
    INCONTRO presso Cap10100<br /><br />
    <strong>Un’occasione di incontro e scambio per discutere insieme di offerta di servizi di prossimità, spazi pubblici e spazi verdi, mobilità da e per i quartieri, abitare e accesso alla casa.</strong><br /><br />
    Durata: 2 ore<br />
    <a href="https://www.eventbrite.it/e/voci-di-quartiere-incontro-presso-cap-10100-tickets-905918786477?aff=oddtdtcreator" target="_blank">Prenota un posto</a>`,
    image: incontro2Image,
    district: "",
  },
  {
    id: "barriera_di_milano_17_06",
    qrId: "barriera_di_milano_17_06",
    title: "Barriera di Milano",
    subtitle: 'Scansiona il QR Code "Barriera di Milano"',
    description: `17 giugno ore 18.00, Barriera di Milano, via Errico Petrella 28<br />
    PASSEGGIATA<br /><br />
    <strong>Una camminata da Agrobarriera ai Bagni di via Aglie per guardare da vicino gli spazi pubblici, gli spazi verdi e i luoghi di aggregazione, discutere delle problematiche emergenti e parlare del futuro.</strong><br /><br />
    Durata: 2 ore<br />`,
    image: incontro2Image,
    district: "",
  },
  {
    id: "parco_michelotti_19_06",
    qrId: "parco_michelotti_19_06",
    title: "Parco Michelotti",
    subtitle: 'Scansiona il QR Code "Parco Michelotti"',
    description: `Mercoledì 19 giugno ore 17.00, area gioco del parco Michelotti<br />
    GIOCA ALLA FIABA: IL CONTADINO ASTROLOGO<br /><br />
    <strong>Uno spettacolo per divertirsi insieme ai più piccoli, e pensare a una Torino più accogliente e attrattiva per giovani genitori, famiglie, bambini e ragazzi.<br />
    Un viaggio nella fantasia della tradizione popolare, guidato dagli attori della Casa del Teatro,
    dedicato ai bambini, alle bambine e alle loro famiglie, alla scoperta del comune patrimonio di
    racconti che unisce le generazioni. Un appuntamento in forma di “festa teatrale” attraverso cui gli attori, accompagnati da musica suonata dal vivo, “giocheranno al teatro” assieme al pubblico.</strong><br /><br />
    Durata: 1 ora<br />`,
    image: incontro2Image,
    district: "",
  },
]
