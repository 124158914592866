import { useContext, useEffect, useMemo } from "react"
import { MainContext } from "../../controllers/main"
import { registerPageEvent } from "../../services/utils/utils"
import NewsDetailsDesktop from "../../components/news/NewsDetailsDesktop"
import { useParams } from "react-router-dom"
import { NewsContext } from "../../controllers/news"
import NewsDetailsMobile from "../../components/news/NewsDetailsMobile"
import MetaPixelEvent from "../../components/global/common/MetaPixelEvent"

const NewsDetails = () => {
  const { newsId } = useParams()
  const { isMobile } = useContext(MainContext)
  const { news } = useContext(NewsContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("news_details")
  }, [])

  // current news
  const currentNews = useMemo(
    () => news.find((item) => item.id === newsId)!,
    [newsId, news]
  )

  return isMobile ? (
    <>
      <MetaPixelEvent page="NewsDetails" />
      <NewsDetailsMobile currentNews={currentNews} />
    </>
  ) : (
    <>
      <MetaPixelEvent page="NewsDetails" />
      <NewsDetailsDesktop currentNews={currentNews} />
    </>
  )
}

export default NewsDetails
