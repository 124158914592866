import { Dispatch, SetStateAction, useEffect, useState } from "react"
import FeedbackContainer from "../../feedbacks/common/FeedbackContainer"
import { Scanner } from "@yudiel/react-qr-scanner"
import { logger } from "../../../services/utils/utils"

const QrScanAlert = ({
  open,
  setOpen,
  setQrText,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  setQrText: Dispatch<SetStateAction<string>>
}) => {
  // get user video input device id
  const [inputDevice, setInputDevice] = useState<MediaDeviceInfo | null>(null)

  const getMediaDevices = async () => {
    try {
      // request permissions
      await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "environment",
        },
        audio: false,
      })

      // get video devices
      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoInputDevices = devices.filter(
        (device) => device.kind === "videoinput"
      )

      if (
        videoInputDevices.some((device) => device.label.includes("posteriore"))
      ) {
        setInputDevice(
          videoInputDevices.find((device) =>
            device.label.includes("posteriore")
          )!
        )
      } else if (videoInputDevices[1]) {
        setInputDevice(videoInputDevices[1])
      } else {
        setInputDevice(videoInputDevices[0])
      }
    } catch (e) {
      logger("get media devices error", e)
    }
  }

  useEffect(() => {
    if (open) {
      getMediaDevices()
    }
  }, [open])

  return (
    <FeedbackContainer open={open} setOpen={setOpen} withPadding={false}>
      {open && (
        <div
          style={{
            width: "100%",
            maxWidth: 450,
            height: "100%",
            maxHeight: 450,
          }}
        >
          <Scanner
            onResult={(text) => {
              setQrText(text)
              setOpen(false)
            }}
            options={{
              deviceId: inputDevice ? inputDevice.deviceId : "",
            }}
            components={{
              audio: false,
              torch: false,
            }}
          />
        </div>
      )}
    </FeedbackContainer>
  )
}

export default QrScanAlert
