import { Stack } from "@mui/material"
import BackButtonMobile from "../global/mobile/BackButtonMobile"
import Title from "../global/common/Title"
import { t } from "i18next"
import { useNavigate } from "react-router-dom"
import AreaCard from "./common/AreaCard"
import FadeFromBottom from "../animations/FadeFromBottom"
import { colors } from "../../services/config/colors"
import Button from "../global/common/Button"
import Appear from "../animations/Appear"
import AppearFromSide from "../animations/AppearFromSide"
import Alert from "../global/common/Alert"
import CheckInMissionCompletedFeedback from "../feedbacks/common/CheckInMissionCompletedFeedback"
import { Dispatch, SetStateAction } from "react"
import CheckInMission from "../../models/checkInMission"
import QrScanAlert from "../global/common/QrScanAlert"
import LoadingOverlay from "../global/common/LoadingOverlay"

const AreaDetailsMobile = ({
  checkInMission,
  loading,
  qrScanOpen,
  setQrScanOpen,
  setQrText,
  wrongIdAlertOpen,
  setWrongIdAlertOpen,
  cameraInfoAlertOpen,
  setCameraInfoAlertOpen,
  missionCompletedAlertOpen,
  setMissionCompletedAlertOpen,
  scanLockedAlertOpen,
  setScanLockedAlertOpen,
}: {
  checkInMission: CheckInMission
  loading: boolean
  qrScanOpen: boolean
  setQrScanOpen: Dispatch<SetStateAction<boolean>>
  setQrText: Dispatch<SetStateAction<string>>
  wrongIdAlertOpen: boolean
  setWrongIdAlertOpen: Dispatch<SetStateAction<boolean>>
  cameraInfoAlertOpen: boolean
  setCameraInfoAlertOpen: Dispatch<SetStateAction<boolean>>
  missionCompletedAlertOpen: boolean
  setMissionCompletedAlertOpen: Dispatch<SetStateAction<boolean>>
  scanLockedAlertOpen: boolean
  setScanLockedAlertOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const navigate = useNavigate()

  return (
    <Stack
      style={{
        width: "100%",
        maxWidth: "100%",
        minHeight: "100%",
        position: "relative",
        paddingBottom: 110,
        overflowX: "hidden",
      }}
    >
      {/* back button */}
      <BackButtonMobile
        style={{
          position: "absolute",
          top: 22,
          left: 16,
          zIndex: 1,
          boxShadow: "none",
        }}
        onClick={() => {
          navigate("/")
        }}
      />
      {/* title */}
      <Title
        fontSize={20}
        lineHeight="48px"
        textAlign="center"
        ellipsis
        style={{
          maxWidth: "calc(100% - 128px)",
          marginTop: 22,
          alignSelf: "center",
        }}
      >
        {checkInMission.title}
      </Title>
      {/* header */}
      <img
        src={checkInMission.image}
        style={{
          width: "100%",
          height: 177,
          objectFit: "cover",
          objectPosition: "center",
          borderBottomLeftRadius: 24,
          borderBottomRightRadius: 24,
          marginTop: 14,
        }}
      />
      <Appear
        fade
        style={{ width: "100%", position: "absolute", top: 200, left: 16 }}
      >
        <AreaCard
          checkInMission={checkInMission}
          variant="expanded"
          completed={checkInMission.satisfied}
        />
      </Appear>
      {/* description */}
      {checkInMission.description.length ? (
        <AppearFromSide fade delay={0.05} style={{ marginTop: 80 }}>
          <Stack style={{ paddingInline: 16 }}>
            <Title fontSize={16} lineHeight="25px">
              {t("area_description")}
            </Title>
            <div
              className="area-content"
              style={{
                fontSize: 14,
                lineHeight: "22px",
                fontWeight: 400,
                marginTop: 10,
              }}
              dangerouslySetInnerHTML={{ __html: checkInMission.description }}
            />
          </Stack>
        </AppearFromSide>
      ) : null}
      {/* log button */}
      <FadeFromBottom style={{ width: "100%", position: "fixed", bottom: 0 }}>
        <div
          style={{
            width: "100%",
            paddingTop: 16,
            paddingBottom: 16,
            boxShadow: "0px 0px 64px rgba(3, 56, 120, 0.12)",
            borderRadius: "10px 10px 0px 0px",
            backgroundColor: colors.backgroundWhite,
          }}
        >
          <Button
            width="calc(100% - 32px)"
            disabled={checkInMission.satisfied}
            style={{ marginLeft: 16 }}
            title={t("scan_qr_code")}
            onClick={() => {
              setQrScanOpen(true)
            }}
          >
            {t("scan_qr_code")}
          </Button>
        </div>
      </FadeFromBottom>
      {/* alerts */}
      <QrScanAlert
        open={qrScanOpen}
        setOpen={setQrScanOpen}
        setQrText={setQrText}
      />
      <Alert
        open={wrongIdAlertOpen}
        title={t("something_is_wrong")}
        description={t("wrong_area")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setWrongIdAlertOpen(false)
        }}
      />
      <Alert
        open={cameraInfoAlertOpen}
        title={t("scan_qr_code")}
        description={t("use_your_device_camera")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setCameraInfoAlertOpen(false)
        }}
      />
      <Alert
        open={scanLockedAlertOpen}
        title={t("scan_locked_title")}
        description={t("scan_locked_description")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setScanLockedAlertOpen(false)
        }}
      />
      {/* loading overlay */}
      <LoadingOverlay open={loading} />
      {/* feedback */}
      <CheckInMissionCompletedFeedback
        open={missionCompletedAlertOpen}
        setOpen={setMissionCompletedAlertOpen}
        areaName={checkInMission.title}
      />
    </Stack>
  )
}

export default AreaDetailsMobile
