import { useContext, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import { registerPageEvent } from "../../services/utils/utils"
import NewsDesktop from "../../components/news/NewsDesktop"
import NewsMobile from "../../components/news/NewsMobile"
import MetaPixelEvent from "../../components/global/common/MetaPixelEvent"

const News = () => {
  const { isMobile } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("news")
  }, [])

  return isMobile ? (
    <>
      <MetaPixelEvent page="News" />
      <NewsMobile />
    </>
  ) : (
    <>
      <MetaPixelEvent page="News" />
      <NewsDesktop />
    </>
  )
}

export default News
