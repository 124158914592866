import { ButtonBase, Stack } from "@mui/material"
import News from "../../../models/news"
import Text from "../../global/common/Text"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import arrowRightPrimary from "../../../assets/icons/arrow-right.svg"
import { t } from "i18next"
import { useNavigate } from "react-router-dom"
import {
  parseStringDate,
  scrollWindowToTop,
} from "../../../services/utils/utils"

const NewsCard = ({ news }: { news: News }) => {
  const navigate = useNavigate()

  return (
    <ButtonBase
      aria-label={t("go_to_news", { title: news.document.title })}
      disableRipple
      style={{ width: "100%", borderRadius: 10 }}
      onClick={() => {
        scrollWindowToTop()
        navigate(`/news/${news.id}`)
      }}
    >
      <Stack style={{ width: "100%", height: "auto" }}>
        <img
          src={news.document.image}
          style={{
            width: "100%",
            height: 207,
            objectFit: "cover",
            objectPosition: "center",
            borderRadius: 10,
          }}
          alt=""
        />
        <Text
          fontSize={14}
          lineHeight="18px"
          fontWeight={500}
          color={colors.primaryDark}
          style={{ marginTop: 28 }}
        >
          {parseStringDate(news.startsAt)}
        </Text>
        <Title
          fontSize={20}
          lineHeight="28px"
          style={{
            height: 56,
            marginTop: 10,
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {news.document.title}
        </Title>
        <div
          className="center"
          style={{ width: 24, height: 24, marginTop: 20 }}
        >
          <img
            src={arrowRightPrimary}
            style={{ width: 16, height: 16 }}
            alt=""
          />
        </div>
      </Stack>
    </ButtonBase>
  )
}

export default NewsCard
