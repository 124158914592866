import { ButtonBase, Stack } from "@mui/material"
import News from "../../models/news"
import { colors } from "../../services/config/colors"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import { useNavigate } from "react-router-dom"
import { t } from "i18next"
import { defaultBoxShadow } from "../../services/config/constants"
import Text from "../global/common/Text"
import Title from "../global/common/Title"
import { BodySliceType } from "../../services/config/enum"
import { useContext } from "react"
import { NewsContext } from "../../controllers/news"
import AppearFromSide from "../animations/AppearFromSide"
import NewsCardCompact from "./common/NewsCardCompact"
import { parseStringDate } from "../../services/utils/utils"

const NewsDetailsMobile = ({ currentNews }: { currentNews: News }) => {
  const navigate = useNavigate()
  const { news } = useContext(NewsContext)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        overflowX: "hidden",
      }}
    >
      {/* back button */}
      <ButtonBase
        style={{
          width: 48,
          minWidth: 48,
          height: 48,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 12,
          position: "absolute",
          top: 22,
          left: 16,
          backgroundColor: colors.backgroundWhite,
          boxShadow: defaultBoxShadow,
        }}
        onClick={() => {
          navigate("/news")
        }}
        aria-label={t("go_back")}
      >
        <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
      </ButtonBase>
      {/* image */}
      <img
        src={currentNews.document.image}
        style={{
          width: "100%",
          height: 172,
          objectFit: "cover",
          objectPosition: "center",
        }}
        alt=""
      />
      {/* content */}
      <Stack style={{ paddingInline: 16, gap: 10, marginTop: 30 }}>
        <AppearFromSide fade key={currentNews.id + "date"}>
          <Text fontSize={14} fontWeight={500} color={colors.primaryDark}>
            {parseStringDate(currentNews.startsAt)}
          </Text>
        </AppearFromSide>
        <AppearFromSide fade delay={0.05} key={currentNews.id + "title"}>
          <Title
            component="h1"
            fontSize={20}
            lineHeight="29px"
            fontWeight={600}
          >
            {currentNews.document.title}
          </Title>
        </AppearFromSide>
        <AppearFromSide fade delay={0.1} key={currentNews.id + "body"}>
          {currentNews.document.body && currentNews.document.body.length
            ? currentNews.document.body.map((item, index) => {
                if (item.sliceType === BodySliceType.BodyTextSlice) {
                  return (
                    <div
                      className="html"
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: item.text.split("_self").join("_blank"),
                      }}
                      style={{
                        fontSize: 14,
                        fontWeight: 300,
                        lineHeight: "22px",
                        color: colors.text,
                        overflow: "hidden",
                      }}
                    />
                  )
                } else {
                  return null
                }
              })
            : null}
        </AppearFromSide>
      </Stack>
      {/* recent news */}
      {news.filter((item) => item.id !== currentNews.id).length ? (
        <Stack
          style={{
            paddingInline: 16,
            gap: 10,
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <AppearFromSide fade delay={0.15}>
            <Title
              component="h2"
              fontSize={20}
              lineHeight="29px"
              fontWeight={600}
            >
              {t("recent_news")}
            </Title>
          </AppearFromSide>
          <Stack style={{ gap: 20 }}>
            {news
              .filter((item) => item.id !== currentNews.id)
              .slice(0, 3)
              .map((item, index) => (
                <AppearFromSide key={index} fade delay={0.05 * index + 0.15}>
                  <NewsCardCompact news={item} />
                </AppearFromSide>
              ))}
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  )
}

export default NewsDetailsMobile
