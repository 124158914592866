import { Stack } from "@mui/material"
import Text from "./Text"
import { colors } from "../../../services/config/colors"
import { CSSProperties, useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { useTranslation } from "react-i18next"

const PointsBadge = ({
  points,
  upTo = false,
  style,
}: {
  points: number
  upTo?: boolean
  style?: CSSProperties
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)

  return (
    <Stack
      justifyContent="center"
      style={{
        width: "auto",
        height: isMobile ? 32 : 40,
        paddingInline: isMobile ? 8 : 10,
        borderRadius: 4,
        backgroundColor: colors.backgroundWhite,
        border: isMobile
          ? "1px solid " + colors.primaryDark
          : "1.5px solid " + colors.primaryDark,
        ...style,
      }}
    >
      {upTo ? (
        <Text
          fontSize={isMobile ? 14 : 18}
          fontWeight={700}
          color={colors.primaryDark}
        >
          {t("up_to")} {points} {t("points")}
        </Text>
      ) : (
        <Text
          fontSize={isMobile ? 14 : 18}
          fontWeight={700}
          color={colors.primaryDark}
        >
          {points} {t("points")}
        </Text>
      )}
    </Stack>
  )
}

export default PointsBadge
