import { Fade, LinearProgress, Modal } from "@mui/material"
import awLogo from "../../../assets/images/aw-logo.svg"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { t } from "i18next"

const LoadingOverlay = ({ open }: { open: boolean }) => {
  const { windowHeight } = useContext(MainContext)

  return (
    <Modal
      open={open}
      aria-labelledby={t("loading_indicator")}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
      }}
    >
      <Fade in={open} mountOnEnter unmountOnExit>
        <div
          className="center"
          style={{
            width: "100%",
            height: windowHeight,
            flexDirection: "column",
          }}
        >
          <img
            src={awLogo}
            style={{ width: "38%", maxWidth: 165 }}
            alt={t("aworld_logo_alt")}
          />
          <LinearProgress
            style={{
              width: "34%",
              maxWidth: 157,
              marginTop: 16,
              borderRadius: 100,
            }}
            classes={{
              colorPrimary: "linear-progress-background",
              barColorPrimary: "linear-progress-bar",
            }}
          />
        </div>
      </Fade>
    </Modal>
  )
}

export default LoadingOverlay
