import { Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import PointsHistoryListItem from "../common/PointsHistoryListItem"
import { useContext } from "react"
import { MissionsContext } from "../../../controllers/missions"
import Text from "../../global/common/Text"
import { useTranslation } from "react-i18next"

const PointsHistoryCardDesktop = () => {
  const { t } = useTranslation()
  const { missionsHistory } = useContext(MissionsContext)

  return (
    <Stack
      style={{
        width: "100%",
        height: missionsHistory.length ? 612 : "auto",
        marginTop: 30,
        borderRadius: 16,
        backgroundColor: colors.backgroundWhite,
        boxShadow: missionsHistory.length
          ? "0px 0px 35.68513px 0px rgba(17, 67, 97, 0.08)"
          : "none",
        gap: 22,
        paddingTop: missionsHistory.length ? 22 : 0,
      }}
    >
      {!missionsHistory.length ? (
        <Text fontSize={18} fontWeight={400}>
          {t("points_history_empty_state")}.
        </Text>
      ) : (
        missionsHistory.slice(0, 5).map((item, index) => (
          <Stack key={index} style={{ gap: 20 }}>
            {index > 0 && (
              <div
                style={{
                  width: "100%",
                  height: 1.5,
                  backgroundColor: colors.backgroundLight,
                }}
              />
            )}
            <PointsHistoryListItem
              title={item.title}
              time={item.updatedAt!}
              points={item.points}
              type={item.missionType}
            />
          </Stack>
        ))
      )}
    </Stack>
  )
}

export default PointsHistoryCardDesktop
