import { createContext, ReactNode, useEffect, useState } from "react"
import { logger } from "../services/utils/utils"
import News from "../models/news"
import { get } from "../services/api/api"

interface NewsContextInterface {
  newsLoading: boolean
  newsError: boolean
  news: News[]
}

const NewsContext = createContext<NewsContextInterface>({
  newsLoading: true,
  newsError: false,
  news: [],
})

const NewsController = ({ children }: { children: ReactNode }) => {
  // loadings
  const [newsLoading, setNewsLoading] = useState<boolean>(true)

  // errors
  const [newsError, setNewsError] = useState<boolean>(false)

  // states
  const [news, setNews] = useState<News[]>([]) // all news list

  // get news list
  const getNews = async () => {
    setNewsLoading(true)
    setNewsError(false)

    try {
      const { data } = await get("/web/news/list")

      // parse data
      data.items.forEach((item: any) => {
        item.document = item.document.items.find(
          (documentItem: any) => documentItem.default
        )
      })
      logger("news list", data.items)

      setNews(data.items)
      setNewsLoading(false)
    } catch (e) {
      logger("news list error", e)
      setNewsLoading(false)
      setNewsError(true)
    }
  }

  // initial fetch
  useEffect(() => {
    getNews()
  }, [])

  return (
    <NewsContext.Provider
      value={{
        newsLoading,
        newsError,
        news,
      }}
    >
      {children}
    </NewsContext.Provider>
  )
}
export { NewsController, NewsContext }
