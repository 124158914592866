import FadeFromTop from "../../components/animations/FadeFromTop"
import Center from "../../components/global/common/Center"
import Text from "../../components/global/common/Text"
import { colors } from "../../services/config/colors"
import awLogoShort from "../../assets/images/aw-logo-short.svg"
import { Stack } from "@mui/material"
import closeCircleRedIcon from "../../assets/icons/close-circle-red.png"
import checkCircleGreenIcon from "../../assets/icons/check-circle-green.png"
import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import { useTranslation } from "react-i18next"
import { registerPageEvent } from "../../services/utils/utils"
import Button from "../../components/global/common/Button"
import MetaPixelEvent from "../../components/global/common/MetaPixelEvent"

const Error = ({
  modules,
}: {
  modules: { name: string; error: boolean }[]
}) => {
  const { t } = useTranslation()
  const { isMobile, setVisualizingErrorPage } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("error")
  }, [])

  // tell other components this page is being visualized
  useEffect(() => {
    setVisualizingErrorPage(true)

    return () => {
      setVisualizingErrorPage(false)
    }
  }, [])

  // see or not details
  const [showDetails, setShowDetails] = useState<boolean>(false)

  // loading for retry
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <Center
      style={{
        backgroundColor: colors.backgroundWhite,
        paddingInline: 16,
        position: "relative",
      }}
    >
      <MetaPixelEvent page="Error" />
      <FadeFromTop>
        <img
          src={awLogoShort}
          style={{ width: isMobile ? 40 : 48 }}
          alt={t("aworld_logo_alt")}
        />
      </FadeFromTop>
      <FadeFromTop delay={0.025} style={{ marginTop: 36 }}>
        <Text fontSize={isMobile ? 20 : 22} fontWeight={600} textAlign="center">
          {t("error_title")}
        </Text>
      </FadeFromTop>
      <FadeFromTop
        delay={0.05}
        className="center"
        style={{ width: "100%", marginTop: 32 }}
      >
        <Button
          loading={loading}
          title={t("try_again")}
          style={{ maxWidth: 330 }}
          onClick={() => {
            setLoading(true)
            window.location.reload()
          }}
        >
          {t("try_again")}
        </Button>
      </FadeFromTop>
      <FadeFromTop
        delay={0.05}
        className="center"
        style={{ width: "100%", marginTop: 8 }}
      >
        <Button
          disabled={loading}
          outlined
          title={t("see_error_details")}
          onClick={() => {
            setShowDetails(true)
          }}
          style={{ height: 42, borderRadius: 14, maxWidth: 330 }}
        >
          {t("see_error_details")}
        </Button>
      </FadeFromTop>
      <Stack
        gap={1}
        style={{
          position: "absolute",
          bottom: 22,
          opacity: showDetails ? 1 : 0,
          transition: "300ms",
        }}
      >
        {modules
          .filter((module) => module.error)
          .map((module, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              style={{
                minWidth: 160,
                height: isMobile ? 24 : 28,
                backgroundColor: module.error ? colors.error : colors.success,
                borderRadius: 6,
                paddingInline: 8,
                gap: 8,
              }}
            >
              <img
                src={module.error ? closeCircleRedIcon : checkCircleGreenIcon}
                style={{ height: isMobile ? 12 : 14 }}
                alt={t("error")}
              />
              <Text
                fontSize={isMobile ? 14 : 16}
                fontWeight={500}
                color={colors.textWhite}
              >
                {module.name}
              </Text>
            </Stack>
          ))}
      </Stack>
    </Center>
  )
}

export default Error
