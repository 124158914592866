import { ButtonBase, Stack } from "@mui/material"
import News from "../../../models/news"
import { t } from "i18next"
import {
  parseStringDate,
  scrollWindowToTop,
} from "../../../services/utils/utils"
import { useNavigate } from "react-router-dom"
import Text from "../../global/common/Text"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import arrowRightIcon from "../../../assets/icons/arrow-right.svg"

const NewsCardCompact = ({ news }: { news: News }) => {
  const navigate = useNavigate()

  return (
    <ButtonBase
      aria-label={t("go_to_news", { title: news.document.title })}
      disableRipple
      style={{ width: "100%", borderRadius: 10 }}
      onClick={() => {
        scrollWindowToTop()
        navigate(`/news/${news.id}`)
      }}
    >
      <Stack
        direction="row"
        style={{ width: "100%", height: 84, gap: 28, position: "relative" }}
      >
        <img
          src={news.document.image}
          style={{
            width: 84,
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
            borderRadius: 10,
          }}
          alt=""
        />
        <Stack style={{ width: "100%", gap: 10 }}>
          <Text
            fontSize={12}
            lineHeight="18px"
            fontWeight={500}
            color={colors.primaryDark}
          >
            {parseStringDate(news.startsAt)}
          </Text>
          <Title
            fontSize={18}
            lineHeight="24px"
            fontWeight={600}
            style={{
              width: "calc(100% - 52px)",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {news.document.title}
          </Title>
        </Stack>
        <div
          className="center"
          style={{
            width: 24,
            height: 24,
            position: "absolute",
            bottom: 1,
            right: 0,
          }}
        >
          <img src={arrowRightIcon} style={{ width: 16, height: 16 }} alt="" />
        </div>
      </Stack>
    </ButtonBase>
  )
}

export default NewsCardCompact
