import axios from "axios"
import { shoudlShowApiLogs } from "../config/constants"
import jwt_decode from "jwt-decode"
import { logger } from "../utils/utils"

// .env variables
const baseUrl = process.env.REACT_APP_API_URL

// refresh access token
const refreshToken = async () => {
  const decodedAccessToken = jwt_decode(
    localStorage.getItem("accessToken")!
  ) as any
  const username = decodedAccessToken.username

  const config = {
    method: "post",
    url: baseUrl + "/web/refresh",
    data: {
      refreshToken: localStorage.getItem("refreshToken"),
      username: username,
    },
  }

  const { data } = await axios(config)

  return data
}

// check if token is valid and if it's not, refresh it
export const checkToken = async () => {
  const token = localStorage.getItem("accessToken")!

  try {
    const decodedToken: any = jwt_decode(token)

    if (Date.now() >= decodedToken.exp * 1000) {
      const result = await refreshToken()

      if (result && result.AccessToken) {
        localStorage.setItem("accessToken", result.AccessToken)
        return result.AccessToken
      } else {
        return token
      }
    }

    return token
  } catch (e) {
    logger("token refresh error", e)

    // logout user
    localStorage.clear()
    window.location.reload()

    return false
  }
}

// get method
export const get = async (path: string, headers?: object, withAuth = true) => {
  if (shoudlShowApiLogs) {
    logger(`GET ${baseUrl}` + path)
  }

  let token = ""
  if (withAuth) {
    token = await checkToken()
  }

  const config = {
    method: "get",
    url: baseUrl + path,
    headers: {
      Authorization: "Bearer " + token,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  }

  if (headers) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios(config)
}

// post method
export const post = async (
  path: string,
  body?: object,
  headers?: object,
  withAuth = true
) => {
  if (shoudlShowApiLogs) {
    if (body) {
      logger(`POST ${baseUrl}` + path, body)
    } else {
      logger(`POST ${baseUrl}` + path)
    }
  }

  let token = ""
  if (withAuth) {
    token = await checkToken()
  }

  const config = {
    method: "post",
    url: baseUrl + path,
    headers: {
      Authorization: "Bearer " + token,
    },
    data: body,
  }

  if (headers) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios(config)
}

// put method
export const put = async (path: string, body?: object, headers?: object) => {
  if (shoudlShowApiLogs) {
    if (body) {
      logger(`PUT ${baseUrl}` + path, body)
    } else {
      logger(`PUT ${baseUrl}` + path)
    }
  }

  let token = await checkToken()

  const config = {
    method: "put",
    url: baseUrl + path,
    headers: {
      Authorization: "Bearer " + token,
    },
    data: body,
  }

  if (headers) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios(config)
}

// delete method
export const deleteMethod = async (path: string, headers?: object) => {
  if (shoudlShowApiLogs) {
    logger(`DELETE ${baseUrl}` + path)
  }

  let token = await checkToken()

  const config = {
    method: "delete",
    url: baseUrl + path,
    headers: {
      Authorization: "Bearer " + token,
    },
  }

  if (headers) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios(config)
}
