import { useLocation } from "react-router-dom"
import { Compose } from "../../services/utils/compose"
import { MissionsContext, MissionsController } from "../../controllers/missions"
import { ActionsContext, ActionsController } from "../../controllers/actions"
import { ReactNode, useContext, useState } from "react"
import Loading from "../public/loading"
import { MainContext } from "../../controllers/main"
import NavBarDesktop from "../../components/global/desktop/NavBarDesktop"
import FooterDesktop from "../../components/global/desktop/FooterDesktop"
import AppRoutes from "../../services/config/routes"
import { JourneysContext, JourneysController } from "../../controllers/journeys"
import { TagsContext, TagsController } from "../../controllers/tags"
import Error from "../public/error"
import { UsersContext, UsersController } from "../../controllers/users"
import {
  ChallengeContext,
  ChallengeController,
} from "../../controllers/challenge"
import {
  FootprintContext,
  FootprintController,
} from "../../controllers/footprint"
import TutorialAlertDesktop from "../../components/onboarding/desktop/TutorialAlertDesktop"
import NavBarDesktopTutorial from "../../components/global/desktop/NavBarDesktopTutorial"
import TutorialAlertMobile from "../../components/onboarding/mobile/TutorialAlertMobile"
import { NewsContext, NewsController } from "../../controllers/news"
import CheckInMissionCompletedFeedback from "../../components/feedbacks/common/CheckInMissionCompletedFeedback"
import { checkInAreas } from "../../services/config/checkInData"
import Alert from "../../components/global/common/Alert"
import { t } from "i18next"

const Main = () => {
  // all controllers
  const controllers = [
    UsersController,
    TagsController,
    ChallengeController,
    MissionsController,
    ActionsController,
    JourneysController,
    FootprintController,
    NewsController,
  ]

  return (
    <Compose components={controllers}>
      <Container />
    </Compose>
  )
}

const Container = () => {
  const { isMobile, viewTutorial } = useContext(MainContext)

  // check that every controller has finished loading data
  const {
    actionsLoading,
    historyLoading,
    detailedHistoryLoading,
    actionsError,
    historyError,
    detailedHistoryError,
  } = useContext(ActionsContext)
  const {
    journeysLoading,
    journeysError,
    dailyEpisodeLoading,
    dailyEpisodeError,
  } = useContext(JourneysContext)
  const {
    categoriesLoading,
    categoriesError,
    mainCategoriesLoading,
    mainCategoriesError,
  } = useContext(TagsContext)
  const {
    missionsLoading,
    missionsError,
    missionsHistoryLoading,
    missionsHistoryError,
    completedMissionsCountLoading,
    completedMissionsCountError,
  } = useContext(MissionsContext)
  const { leaderboardLoading, leaderboardError } = useContext(UsersContext)
  const { challengeLoading, challengeError, savingsLoading, savingsError } =
    useContext(ChallengeContext)
  const {
    userFootprintLoading,
    userFootprintError,
    formsListLoading,
    formsListError,
    userFormsListLoading,
    userFormsListError,
  } = useContext(FootprintContext)
  const { newsLoading, newsError } = useContext(NewsContext)

  const loading =
    actionsLoading ||
    historyLoading ||
    detailedHistoryLoading ||
    journeysLoading ||
    categoriesLoading ||
    mainCategoriesLoading ||
    missionsLoading ||
    dailyEpisodeLoading ||
    leaderboardLoading ||
    challengeLoading ||
    missionsHistoryLoading ||
    userFootprintLoading ||
    formsListLoading ||
    userFormsListLoading ||
    savingsLoading ||
    completedMissionsCountLoading ||
    newsLoading

  const error =
    actionsError ||
    historyError ||
    detailedHistoryError ||
    journeysError ||
    categoriesError ||
    mainCategoriesError ||
    missionsError ||
    dailyEpisodeError ||
    leaderboardError ||
    challengeError ||
    missionsHistoryError ||
    userFootprintError ||
    formsListError ||
    userFormsListError ||
    savingsError ||
    completedMissionsCountError ||
    newsError

  // challenge alert logic and component
  const [challengeAlertOpen, setChallengeAlertOpen] = useState<boolean>(
    localStorage.getItem("showChallengeEndAlert") ? false : true
  )

  const challengeAlert = (
    <Alert
      open={challengeAlertOpen && !viewTutorial}
      title="Challenge conclusa"
      description='La Challenge di Voci di Quartiere si è conclusa. Seguici su <a href="https://www.torinocambia.it/prg/voci-di-quartiere" target="_blank">torinocambia.it</a>'
      primaryActionLabel={t("i_understand")}
      primaryActionOnClick={() => {
        setChallengeAlertOpen(false)
        localStorage.setItem("showChallengeEndAlert", "false")
      }}
      isDescriptionHtml
    />
  )

  if (error) {
    return (
      <Error
        modules={[
          { name: "Authentication", error: false },
          { name: "Categories", error: categoriesError },
          { name: "Main categories", error: mainCategoriesError },
          { name: "Actions", error: actionsError },
          { name: "History", error: historyError },
          { name: "Daily history", error: detailedHistoryError },
          { name: "Journey", error: journeysError },
          { name: "Daily episode", error: dailyEpisodeError },
          { name: "Missions", error: missionsError },
          { name: "Missions history", error: missionsHistoryError },
          { name: "Completed missions", error: completedMissionsCountError },
          { name: "Leaderboard", error: leaderboardError },
          { name: "Challenge", error: challengeError },
          { name: "Challenge savings", error: savingsError },
          { name: "Footprint", error: userFootprintError },
          { name: "Footprint forms", error: formsListError },
          { name: "User footprint forms", error: userFormsListError },
          { name: "News", error: newsError },
        ]}
      />
    )
  }

  if (loading) {
    return <Loading />
  }

  return isMobile ? (
    <ContainerMobile challengeAlert={challengeAlert} />
  ) : (
    <ContainerDesktop challengeAlert={challengeAlert} />
  )
}

const ContainerDesktop = ({
  challengeAlert,
}: {
  challengeAlert: ReactNode
}) => {
  const location = useLocation()
  const {
    windowHeight,
    viewTutorial,
    checkInIdParam,
    checkInCompletedFeedbackOpen,
    setCheckInCompletedFeedbackOpen,
  } = useContext(MainContext)

  return (
    <div
      id="main-container"
      style={{
        width: "100%",
        height: windowHeight,
        maxHeight: windowHeight,
        position: "relative",
        overflowY: "scroll",
      }}
    >
      {window.location.pathname.includes("/journey/") ||
      window.location.pathname.includes(
        "/dailyepisode/"
      ) ? null : viewTutorial ? (
        <NavBarDesktopTutorial />
      ) : (
        <NavBarDesktop />
      )}
      <main
        id="main-content"
        style={{
          height:
            window.location.pathname.includes("/journey/") ||
            window.location.pathname.includes("/dailyepisode/")
              ? "100%"
              : "auto",
        }}
        aria-label={t("main_content")}
      >
        <TutorialAlertDesktop />
        {challengeAlert}
        <AppRoutes location={location} />
        <CheckInMissionCompletedFeedback
          open={checkInCompletedFeedbackOpen}
          setOpen={setCheckInCompletedFeedbackOpen}
          areaName={
            checkInIdParam.current &&
            checkInAreas.some((area) => area.id === checkInIdParam.current)
              ? checkInAreas.find((area) => area.id === checkInIdParam.current)!
                  .title
              : ""
          }
          fromQr
        />
      </main>
      {window.location.pathname.includes("/journey/") ||
      window.location.pathname.includes("/dailyepisode") ? null : (
        <FooterDesktop />
      )}
    </div>
  )
}

const ContainerMobile = ({ challengeAlert }: { challengeAlert: ReactNode }) => {
  const location = useLocation()
  const {
    windowHeight,
    checkInIdParam,
    checkInCompletedFeedbackOpen,
    setCheckInCompletedFeedbackOpen,
  } = useContext(MainContext)

  return (
    <main
      id="main-container"
      style={{
        width: "100%",
        height: windowHeight,
        maxHeight: windowHeight,
        position: "relative",
        overflowY: "scroll",
      }}
      aria-label={t("main_content")}
    >
      <TutorialAlertMobile />
      {challengeAlert}
      <AppRoutes location={location} />
      <CheckInMissionCompletedFeedback
        open={checkInCompletedFeedbackOpen}
        setOpen={setCheckInCompletedFeedbackOpen}
        areaName={
          checkInIdParam.current &&
          checkInAreas.some((area) => area.id === checkInIdParam.current)
            ? checkInAreas.find((area) => area.id === checkInIdParam.current)!
                .title
            : ""
        }
        fromQr
      />
    </main>
  )
}

export default Main
