import { useContext, useEffect, useMemo, useRef, useState } from "react"
import FeedbackContainer from "../../feedbacks/common/FeedbackContainer"
import { ButtonBase, Stack } from "@mui/material"
import { MainContext } from "../../../controllers/main"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import Text from "../../global/common/Text"
import Button from "../../global/common/Button"
import { t } from "i18next"
import chevronRightBigPrimaryIcon from "../../../assets/icons/chevron-right-big-primary.svg"
import chevronLeftBigPrimaryIcon from "../../../assets/icons/chevron-left-big-primary.svg"
import {
  getWindowScrollY,
  scrollWindow,
  scrollWindowToTop,
} from "../../../services/utils/utils"
import { Trans } from "react-i18next"
import checkWhiteIcon from "../../../assets/icons/check-white.svg"
import {
  desktopMaxWidth,
  desktopPadding,
} from "../../../services/config/constants"
import closeIcon from "../../../assets/icons/close.png"
import infoIconPrimary from "../../../assets/icons/info-primary.svg"

const TutorialAlertDesktop = () => {
  const {
    viewTutorial,
    setViewTutorial,
    currentTutorialPage,
    setCurrentTutorialPage,
    user,
    setUserFirstAccess,
  } = useContext(MainContext)

  // loading
  const [loading, setLoading] = useState<boolean>(false)

  // close message to quit tutorial
  const [closeMessageVisible, setCloseMessageVisible] = useState<boolean>(false)

  // do not show tutorial again
  const [doNotShowAgain, setDoNotShowAgain] = useState<boolean>(false)

  // can click continue button
  const canClick = useRef<boolean>(true)

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  // array of elements to change style of
  const elements = useRef<HTMLElement[]>([])

  useEffect(() => {
    elements.current = [
      document.getElementsByClassName("mission-card")[0] as HTMLElement,
      document.getElementsByClassName("mission-card")[2] as HTMLElement,
      document.getElementsByClassName(
        "mission-card-completion-badge"
      )[2] as HTMLElement,
      document.getElementsByClassName("act")[0] as HTMLElement,
      document.getElementsByClassName("learn")[0] as HTMLElement,
      ...(document.getElementsByClassName("checkin")[0]
        ? [document.getElementsByClassName("checkin")[0] as HTMLElement]
        : []),
      document.getElementById("navbar-performance-button") as HTMLElement,
    ]

    setCloseMessageVisible(false)
    setCurrentTutorialPage(0)
  }, [viewTutorial])

  // set every element zIndex to 0
  const cleanZIndexes = () => {
    elements.current.forEach((item) => {
      if (item.id === "navbar-performance-button") {
        item.style.zIndex = "99"
      } else {
        item.style.zIndex = ""
      }
    })
  }

  // on click functions
  const onClickFunctions = [
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // get first mission card position and change zIndex
      const missionCard = elements.current[0]
      const missionCardTop =
        missionCard.getBoundingClientRect().top + getWindowScrollY()
      const missionCardRight = missionCard.getBoundingClientRect().right
      missionCard.style.zIndex = "150"

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      tutorialContainer.style.left = missionCardRight + 30 + "px"

      // scroll window
      scrollWindow(missionCardTop - 270, "smooth")

      // change current page
      setCurrentTutorialPage(1)
    },
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // get first mission card position
      const missionCard = elements.current[0]
      const missionCardTop =
        missionCard.getBoundingClientRect().top + getWindowScrollY()
      const missionCardRight = missionCard.getBoundingClientRect().right

      // change third mission card zIndex
      const thirdMissionCard = elements.current[1]
      thirdMissionCard.style.zIndex = "150"

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      tutorialContainer.style.left = missionCardRight + 30 + "px"

      // scroll window
      scrollWindow(missionCardTop - 270, "smooth")

      // change current page
      setCurrentTutorialPage(2)
    },
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // get third mission card position
      const missionCard = elements.current[1]
      const missionCardTop =
        missionCard.getBoundingClientRect().top + getWindowScrollY()
      const missionCardLeft = missionCard.getBoundingClientRect().left

      // get mission card completion badge (the circle under the card) and change its zIndex
      const completionBadge = elements.current[2]
      completionBadge.style.zIndex = "150"

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      tutorialContainer.style.left = missionCardLeft + "px"

      // scroll window
      scrollWindow(missionCardTop - 270, "smooth")

      // change current page
      setCurrentTutorialPage(3)
    },
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // get act mission card position and change zIndex
      const missionCard = elements.current[3]
      const missionCardTop =
        missionCard.getBoundingClientRect().top + getWindowScrollY()
      const missionCardLeft = missionCard.getBoundingClientRect().left
      missionCard.style.zIndex = "150"

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      if (missionCardLeft < window.innerWidth / 2) {
        tutorialContainer.style.left = missionCardLeft + 360 + "px"
      } else {
        tutorialContainer.style.left = missionCardLeft - 360 + "px"
      }

      // scroll window
      scrollWindow(missionCardTop - 270, "smooth")

      // change current page
      setCurrentTutorialPage(4)
    },
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // get learn mission card position and change zIndex
      const missionCard = elements.current[4]
      const missionCardTop =
        missionCard.getBoundingClientRect().top + getWindowScrollY()
      const missionCardLeft = missionCard.getBoundingClientRect().left
      missionCard.style.zIndex = "150"

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      if (missionCardLeft < window.innerWidth / 2) {
        tutorialContainer.style.left = missionCardLeft + 360 + "px"
      } else {
        tutorialContainer.style.left = missionCardLeft - 360 + "px"
      }

      // scroll window
      scrollWindow(missionCardTop - 270, "smooth")

      // change current page
      setCurrentTutorialPage(5)
    },
    ...(document.getElementsByClassName("checkin")[0]
      ? [
          () => {
            // clean all zIndexes
            cleanZIndexes()

            // get checkin mission card position and change zIndex
            const missionCard = elements.current[5]
            const missionCardTop =
              missionCard.getBoundingClientRect().top + getWindowScrollY()
            const missionCardLeft = missionCard.getBoundingClientRect().left
            missionCard.style.zIndex = "150"

            // move tutorial container
            const tutorialContainer =
              document.getElementById("tutorial-container")!
            if (missionCardLeft < window.innerWidth / 2) {
              tutorialContainer.style.left = missionCardLeft + 360 + "px"
            } else {
              tutorialContainer.style.left = missionCardLeft - 360 + "px"
            }

            // scroll window
            scrollWindow(missionCardTop - 270, "smooth")

            // change current page
            setCurrentTutorialPage(6)
          },
        ]
      : []),
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // get performance button position and change zIndex
      const performanceButton =
        elements.current[document.getElementsByClassName("checkin")[0] ? 6 : 5]
      const performanceButtonRight =
        performanceButton.getBoundingClientRect().right
      performanceButton.style.zIndex = "150"

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      tutorialContainer.style.left = performanceButtonRight - 330 + "px"

      // scroll window
      scrollWindowToTop("smooth")

      // change current page
      setCurrentTutorialPage(
        document.getElementsByClassName("checkin")[0] ? 7 : 6
      )
    },
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // move tutorial container to the center
      const tutorialContainer = document.getElementById("tutorial-container")!
      tutorialContainer.style.left = ""

      // scroll window
      scrollWindowToTop("smooth")

      // change current page
      setCurrentTutorialPage(
        document.getElementsByClassName("checkin")[0] ? 8 : 7
      )
    },
  ]

  // tutorial slides
  const slides = [
    <Stack alignItems="center" style={{ gap: 28 }}>
      <Title textAlign="center">{t("onboarding_welcome")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="tour_description">
          Siamo molto felici che tu sia qui con noi!
          <br />
          Scopri come aiutare Electrolux a{" "}
          <strong style={{ fontWeight: 500 }}>completare missioni</strong> e
          raggiungere l’obiettivo della challenge.
        </Trans>
      </Text>
      <Button
        title={t("start_tour")}
        width="100%"
        onClick={onClickFunctions[currentTutorialPage]}
      >
        {t("start_tour")}
      </Button>
      {user?.firstAccess ? (
        <ButtonBase
          disableRipple
          style={{ width: "100%", height: 24 }}
          onClick={() => {
            setDoNotShowAgain((current) => !current)
          }}
          aria-live="assertive"
          role="checkbox"
          aria-checked={doNotShowAgain ? "true" : "false"}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <div
              style={{
                width: 26,
                height: 26,
                backgroundColor: doNotShowAgain
                  ? colors.primaryDark
                  : colors.backgroundWhite,
                border: "1.5px solid " + colors.primaryDark,
                borderRadius: "100%",
                transition: "150ms",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={checkWhiteIcon} style={{ width: 14 }} alt="" />
            </div>
            <Title fontSize={14} lineHeight="16px" color={colors.primaryDark}>
              {t("do_not_show_again")}
            </Title>
          </Stack>
        </ButtonBase>
      ) : null}
    </Stack>,
    <Stack alignItems="center" style={{ gap: 28 }}>
      <Title textAlign="center">{t("what_are_missions_extended")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="what_are_missions_description">
          <strong style={{ fontWeight: 500 }}>
            Semplici attività a tema sostenibilità
          </strong>{" "}
          da completare con una certa frequenza (giornaliera, settimanale,
          speciale) che ti permettono di{" "}
          <strong style={{ fontWeight: 500 }}>ottenere punti</strong> e
          contribuire alla challenge.
          <br />
          <br />
          Esistono 3 tipologie di missioni:{" "}
          <strong style={{ fontWeight: 500 }}>
            Agisci, Impara & Scansiona.
          </strong>
        </Trans>
      </Text>
    </Stack>,
    <Stack alignItems="center" style={{ gap: 28 }}>
      <Title textAlign="center">{t("how_to_complete_missions")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="how_to_complete_missions_description_1">
          <strong style={{ fontWeight: 500 }}>Clicca sulla card</strong> per
          accedere al contenuto della missione. Alcune missioni richiedono di
          compiere attività più volte.
        </Trans>
      </Text>
    </Stack>,
    <Stack alignItems="center" style={{ gap: 28 }}>
      <Title textAlign="center">{t("how_to_complete_missions")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="how_to_complete_missions_description_2">
          Controlla il <strong style={{ fontWeight: 500 }}>contatore</strong>{" "}
          delle singole missioni per scoprire quanto manca al{" "}
          <strong style={{ fontWeight: 500 }}>completamento.</strong>
        </Trans>
      </Text>
    </Stack>,
    <Stack alignItems="center" style={{ gap: 28 }}>
      <Title textAlign="center">{t("act")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="act_missions_description">
          Migliora il tuo stile di vita,{" "}
          <strong style={{ fontWeight: 500 }}>esplora</strong> le azioni
          suggerite e <strong style={{ fontWeight: 500 }}>registra</strong>{" "}
          quelle che hai fatto.
        </Trans>
      </Text>
    </Stack>,
    <Stack alignItems="center" style={{ gap: 28 }}>
      <Title textAlign="center">{t("learn")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="learn_missions_description">
          <strong style={{ fontWeight: 500 }}>Leggi gli episodi</strong>{" "}
          consigliati e{" "}
          <strong style={{ fontWeight: 500 }}>completa i quiz</strong> sui temi
          della sostenibilità
        </Trans>
      </Text>
    </Stack>,
    ...(document.getElementsByClassName("checkin")[0]
      ? [
          <Stack alignItems="center" style={{ gap: 28 }}>
            <Title textAlign="center">{t("checkin")}</Title>
            <Text
              fontSize={18}
              lineHeight="24px"
              fontWeight={300}
              textAlign="center"
            >
              <Trans i18nKey="checkin_missions_description">
                Partecipa agli eventi, inquadra i{" "}
                <strong style={{ fontWeight: 500 }}>QR Code</strong> e
                scansionali per ottenere punti.
              </Trans>
            </Text>
          </Stack>,
        ]
      : []),
    <Stack alignItems="center" style={{ gap: 28 }}>
      <Title textAlign="center">{t("points_and_performance")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        {t("points_and_performance_description")}
      </Text>
    </Stack>,
    <Stack alignItems="center" style={{ gap: 28 }}>
      <Title textAlign="center">{t("tour_completed")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="tour_completed_description">
          Ora sai tutto!
          <br />
          Se hai dubbi, clicca sull'icona{" "}
          <strong style={{ fontWeight: 500 }}>informazioni</strong> o accedi a
          questo tutorial dal menù.
        </Trans>
      </Text>
    </Stack>,
  ]

  // control buttons disabled
  const previousSlideButtonDisabled = useMemo(() => {
    if (currentTutorialPage <= 1) {
      return true
    }

    return false
  }, [currentTutorialPage])

  const nextSlideButtonDisabled = useMemo(() => {
    if (currentTutorialPage >= slides.length - 1) {
      return true
    }

    return false
  }, [currentTutorialPage])

  // focus correct controls when current page changes
  useEffect(() => {
    const controls = document.getElementById("tutorial-controls")
    if (controls) {
      if (24 * (currentTutorialPage - 1) - controls.scrollLeft === 48) {
        controls.scrollTo({
          left: controls.scrollLeft + 24,
          behavior: "smooth",
        })
      } else if (24 * (currentTutorialPage - 1) - controls.scrollLeft === 0) {
        controls.scrollTo({
          left: controls.scrollLeft - 24,
          behavior: "smooth",
        })
      }
    }
  }, [currentTutorialPage])

  return (
    <FeedbackContainer
      open={viewTutorial}
      setOpen={setViewTutorial}
      withCloseButton={false}
      withPadding={false}
      withBackground={false}
      forTutorial
    >
      {/* close button */}
      {!closeMessageVisible && currentTutorialPage !== slides.length - 1 ? (
        <Stack
          alignItems="center"
          style={{
            width: "100%",
            height: 36,
            paddingInline: desktopPadding,
            position: "fixed",
            top: 15,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              maxWidth: desktopMaxWidth,
              position: "relative",
            }}
          >
            <ButtonBase
              style={{
                width: 36,
                height: 36,
                borderRadius: "100%",
                position: "absolute",
                right: 0,
                backgroundColor: colors.backgroundWhite,
                boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.08)",
              }}
              title={t("close")}
              onClick={() => {
                // clean zIndexes and make close message appear
                cleanZIndexes()
                setCloseMessageVisible(true)

                // move tutorial container to the center
                const tutorialContainer =
                  document.getElementById("tutorial-container")!
                tutorialContainer.style.left = ""
              }}
            >
              <img src={closeIcon} style={{ width: 10, height: 10 }} alt="" />
            </ButtonBase>
          </div>
        </Stack>
      ) : null}
      {/* tutorial content */}
      <Stack
        id="tutorial-container"
        alignItems="center"
        style={{
          width: 330,
          height: "auto",
          backgroundColor: colors.backgroundWhite,
          borderRadius: 15,
          paddingTop: closeMessageVisible ? 28 : 70,
          paddingBottom: 28,
          paddingInline: 26,
          position: "absolute",
          transition: "300ms",
        }}
      >
        {!closeMessageVisible && (
          <img
            src={user?.profileImage}
            style={{
              width: 80,
              height: 80,
              objectFit: "cover",
              objectPosition: "center",
              position: "absolute",
              top: -40,
              borderRadius: "100%",
              boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
            }}
            alt=""
          />
        )}
        {closeMessageVisible ? (
          <Stack alignItems="center" style={{ gap: 14 }}>
            <Title textAlign="center">{t("close_tour")}</Title>
            <Text
              fontSize={18}
              lineHeight="24px"
              fontWeight={300}
              textAlign="center"
            >
              <Trans i18nKey="close_tour_description">
                Se hai dubbi, clicca sull'
                <strong style={{ fontWeight: 600 }}>
                  icona informazioni
                </strong>{" "}
                o accedi a questo tutorial dal menù.
              </Trans>
            </Text>
            <div
              className="center"
              style={{
                width: 30,
                height: 30,
                borderRadius: 5,
                border: "1px solid " + colors.primaryDark,
                marginTop: 10,
              }}
            >
              <img src={infoIconPrimary} style={{ width: 16 }} alt="" />
            </div>
            <Button
              title={t("confirm")}
              width="100%"
              style={{ marginTop: 10 }}
              loading={loading}
              onClick={async () => {
                if (user?.firstAccess && doNotShowAgain) {
                  setLoading(true)
                  await setUserFirstAccess()
                }

                setViewTutorial(false)
              }}
            >
              {t("confirm")}
            </Button>
            <Button
              outlined
              title={t("cancel")}
              width="100%"
              disabled={loading}
              onClick={() => {
                setCloseMessageVisible(false)
                if (currentTutorialPage > 0) {
                  onClickFunctions[currentTutorialPage - 1]()
                }
              }}
            >
              {t("cancel")}
            </Button>
          </Stack>
        ) : (
          slides[currentTutorialPage]
        )}
      </Stack>
      {/* act now button */}
      <Button
        disabled={
          currentTutorialPage !== slides.length - 1 || closeMessageVisible
        }
        loading={loading}
        title={t("lets_start_singular")}
        style={{
          width: 330,
          position: "fixed",
          bottom: 34,
          opacity: closeMessageVisible
            ? 0
            : currentTutorialPage === slides.length - 1
            ? 1
            : 0,
          transition: "150ms",
        }}
        onClick={async () => {
          if (user?.firstAccess && doNotShowAgain) {
            setLoading(true)
            await setUserFirstAccess()
          }

          setViewTutorial(false)
        }}
      >
        {t("lets_start_singular")}
      </Button>
      {/* tutorial controls */}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          width: "100%",
          height: 51,
          gap: 35,
          position: "fixed",
          bottom:
            currentTutorialPage === 0 || closeMessageVisible
              ? -51
              : currentTutorialPage === slides.length - 1
              ? 120
              : 34,
          opacity: currentTutorialPage === 0 ? 0 : 1,
          transition: "150ms",
        }}
        role="group"
        aria-label={t("carousel_controls")}
      >
        <ButtonBase
          style={{
            width: 51,
            height: 51,
            borderRadius: 30,
            backgroundColor: colors.backgroundWhite,
          }}
          disabled={previousSlideButtonDisabled || loading}
          onClick={() => {
            if (canClick.current) {
              onClickFunctions[currentTutorialPage - 2]()

              canClick.current = false
              const timeoutId = setTimeout(() => {
                canClick.current = true
                clearTimeout(timeoutId)
              }, 200)
            }
          }}
          aria-label={t("previous_slide")}
          aria-disabled={previousSlideButtonDisabled || loading}
          onFocusVisible={() => {
            screenReaderEnabled.current = true
          }}
          onBlur={() => {
            screenReaderEnabled.current = false
          }}
        >
          <img
            src={chevronLeftBigPrimaryIcon}
            style={{
              height: 51,
              transition: "150ms",
              opacity: previousSlideButtonDisabled ? 0.4 : 1,
            }}
            alt=""
          />
        </ButtonBase>
        <Stack
          id="tutorial-controls"
          direction="row"
          alignItems="center"
          style={{ maxWidth: 70, height: 17, gap: 9, overflow: "hidden" }}
        >
          {slides.slice(1).map((item, index) => (
            <ButtonBase
              key={index}
              disabled={currentTutorialPage === index + 1 || loading}
              style={{
                width: currentTutorialPage === index + 1 ? 22 : 15,
                minWidth: currentTutorialPage === index + 1 ? 22 : 15,
                height: currentTutorialPage === index + 1 ? 17 : 11.6,
                backgroundColor:
                  currentTutorialPage === index + 1
                    ? colors.primaryDark
                    : colors.disabled,
                borderRadius: 5,
                transition: "200ms",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                const container = document.getElementById(
                  "onboarding-container"
                )
                container?.scrollTo({
                  left: 2 * window.innerWidth,
                  behavior: screenReaderEnabled.current ? "auto" : "smooth",
                })
              }}
              aria-label={t("go_to_slide_count_of_total", {
                count: 3,
                total: 3,
              })}
              aria-current={
                currentTutorialPage === index + 1 ? "true" : "false"
              }
              onFocusVisible={() => {
                screenReaderEnabled.current = true
              }}
              onBlur={() => {
                screenReaderEnabled.current = false
              }}
            >
              <Text
                fontSize={16}
                fontWeight={700}
                color={colors.textWhite}
                style={{
                  opacity: currentTutorialPage === index + 1 ? 1 : 0,
                  transition: "200ms",
                }}
              >
                {index + 1}
              </Text>
            </ButtonBase>
          ))}
        </Stack>
        <ButtonBase
          style={{
            width: 51,
            height: 51,
            borderRadius: 30,
            backgroundColor: colors.backgroundWhite,
          }}
          disabled={nextSlideButtonDisabled || loading}
          onClick={() => {
            if (canClick.current) {
              onClickFunctions[currentTutorialPage]()

              canClick.current = false
              const timeoutId = setTimeout(() => {
                canClick.current = true
                clearTimeout(timeoutId)
              }, 200)
            }
          }}
          aria-label={t("next_slide")}
          aria-disabled={nextSlideButtonDisabled || loading}
          onFocusVisible={() => {
            screenReaderEnabled.current = true
          }}
          onBlur={() => {
            screenReaderEnabled.current = false
          }}
        >
          <img
            src={chevronRightBigPrimaryIcon}
            style={{
              height: 51,
              transition: "150ms",
              opacity: nextSlideButtonDisabled ? 0.4 : 1,
            }}
            alt=""
          />
        </ButtonBase>
      </Stack>
    </FeedbackContainer>
  )
}

export default TutorialAlertDesktop
