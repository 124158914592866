import { Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../controllers/main"
import AmplifySignInMobile from "./AmplifySignInMobile"

const AmplifySignInDesktop = ({
  loading,
  setLoading,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  emailError,
  setEmailError,
  consents,
  setConsents,
  code,
  setCode,
  codeErrorAlertOpen,
  setCodeErrorAlertOpen,
  callConfirmCode,
  signInAlertOpen,
}: {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  firstName: string
  setFirstName: Dispatch<SetStateAction<string>>
  lastName: string
  setLastName: Dispatch<SetStateAction<string>>
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  emailError: boolean
  setEmailError: Dispatch<SetStateAction<boolean>>
  consents: boolean[]
  setConsents: Dispatch<SetStateAction<boolean[]>>
  code: string
  setCode: Dispatch<SetStateAction<string>>
  codeErrorAlertOpen: boolean
  setCodeErrorAlertOpen: Dispatch<SetStateAction<boolean>>
  callConfirmCode: () => void
  signInAlertOpen: boolean
}) => {
  const { windowHeight } = useContext(MainContext)

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      style={{
        width: "100%",
        height: windowHeight,
        backgroundColor: "#252525",
      }}
    >
      <AmplifySignInMobile
        loading={loading}
        setLoading={setLoading}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
        emailError={emailError}
        setEmailError={setEmailError}
        consents={consents}
        setConsents={setConsents}
        code={code}
        setCode={setCode}
        codeErrorAlertOpen={codeErrorAlertOpen}
        setCodeErrorAlertOpen={setCodeErrorAlertOpen}
        callConfirmCode={callConfirmCode}
        signInAlertOpen={signInAlertOpen}
        height="100%"
        continueButtonWidth={330}
      />
    </Stack>
  )
}

export default AmplifySignInDesktop
