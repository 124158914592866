import { ButtonBase, Stack } from "@mui/material"
import {
  CSSProperties,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react"
import Text from "../../global/common/Text"
import { colors } from "../../../services/config/colors"
import { useTranslation } from "react-i18next"
import Title from "../../global/common/Title"
import { MissionsContext } from "../../../controllers/missions"
import AvatarInfoButton from "../../global/desktop/AvatarInfoButton"

const MissionsTabs = ({
  tabs,
  selectedTab,
  setSelectedTab,
  infoButtonOnClick,
  style,
}: {
  tabs: string[]
  selectedTab: number
  setSelectedTab: Dispatch<SetStateAction<number>>
  infoButtonOnClick: () => void
  style?: CSSProperties
}) => {
  const { t } = useTranslation()
  const { missions } = useContext(MissionsContext)

  // focus tab content (accessibility)
  const focusTabContent = () => {
    const container = document.getElementById("missions-desktop-container")!
    container.tabIndex = 0
    container.focus()
    setTimeout(() => container.removeAttribute("tabindex"), 500)
  }

  // refs for all tabs
  const tabRefs = useRef<HTMLButtonElement[]>([])

  // focus tab helper
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const focusTab = (index: number) => {
    const tab = tabRefs.current[index]
    if (tab) {
      tab.focus()
    }
  }

  // onKeyDown handler for tab elements
  const onKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    const count = tabs.length
    const nextTab = () => focusTab((selectedIndex + 1) % count)
    const prevTab = () => focusTab((selectedIndex - 1 + count) % count)
    const firstTab = () => focusTab(0)
    const lastTab = () => focusTab(count - 1)

    const keyMap = {
      ArrowRight: nextTab,
      ArrowLeft: prevTab,
      Home: firstTab,
      End: lastTab,
    }

    if (
      event.key === "ArrowRight" ||
      event.key === "ArrowLeft" ||
      event.key === "Home" ||
      event.key === "End"
    ) {
      const action = keyMap[event.key]
      if (action) {
        event.preventDefault()
        action()
      }
    }
  }

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  return (
    <Stack
      direction="row"
      style={{
        width: "100%",
        height: 92,
        position: "relative",
        ...style,
      }}
    >
      {/* tabs buttons */}
      <Stack direction="row" style={{ height: 40, gap: 10 }}>
        {tabs.map((tab, index) => (
          <ButtonBase
            key={index}
            disableRipple
            style={{
              width: "auto",
              height: "100%",
              borderRadius: 5,
              paddingInline: 12,
              backgroundColor:
                selectedTab === index ? colors.primary : colors.background,
              transition: "200ms",
            }}
            onClick={() => {
              setSelectedTab(index)

              if (screenReaderEnabled.current) {
                focusTabContent()
              }
            }}
            aria-label={tab}
            role="tab"
            aria-selected={selectedTab === index ? "true" : "false"}
            ref={(element) => (tabRefs.current[index] = element!)}
            onKeyDown={onKeyDown}
            onFocus={() => setSelectedIndex(index)}
            onFocusVisible={() => {
              screenReaderEnabled.current = true
            }}
            onBlur={() => {
              screenReaderEnabled.current = false
            }}
          >
            <Text
              fontSize={16}
              fontWeight={600}
              color={selectedTab === index ? colors.text : colors.text}
              style={{ transition: "200ms" }}
            >
              {tab}
            </Text>
          </ButtonBase>
        ))}
      </Stack>
      {/* available missions counter */}
      <Title
        fontSize={20}
        fontWeight={600}
        style={{ position: "absolute", left: 0, bottom: 0 }}
      >
        {missions.filter((mission) => !mission.satisfied).length}{" "}
        {missions.filter((mission) => !mission.satisfied).length === 1
          ? t("available_singular")
          : t("available_plural")}
      </Title>
      {/* info button */}
      <AvatarInfoButton
        title={t("what_are_missions_extended")}
        onClick={infoButtonOnClick}
        style={{ position: "absolute", right: 0 }}
      />
    </Stack>
  )
}

export default MissionsTabs
