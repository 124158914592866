import { useContext } from "react"
import { MainContext } from "./controllers/main"
import Error from "./views/public/error"
import Loading from "./views/public/loading"
import Onboarding from "./views/public/onboarding"
import Main from "./views/private/main"
import AvatarSelection from "./views/public/avatarSelection"
import AmplifySignIn from "./views/public/amplifySignIn"
import GroupSelection from "./views/public/groupSelection"

const App = () => {
  const {
    loading,
    signUpError,
    userError,
    groupsError,
    viewOnboarding,
    viewAmplifySignIn,
    viewGroupSelection,
    viewAvatarSelection,
  } = useContext(MainContext)

  if (signUpError || userError) {
    return (
      <Error
        modules={[
          { name: "Authentication", error: signUpError },
          { name: "User", error: userError },
          { name: "Groups", error: groupsError },
        ]}
      />
    )
  }

  if (loading) {
    return <Loading />
  }

  if (viewOnboarding) {
    return <Onboarding />
  }

  if (viewAmplifySignIn) {
    return <AmplifySignIn />
  }

  if (viewGroupSelection) {
    return <GroupSelection />
  }

  if (viewAvatarSelection) {
    return <AvatarSelection />
  }

  return <Main />
}

export default App
