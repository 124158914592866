import { useContext, useEffect, useMemo, useState } from "react"
import { MainContext } from "../../controllers/main"
import { useNavigate } from "react-router-dom"
import { registerEvent, registerPageEvent } from "../../services/utils/utils"
import { MissionsContext } from "../../controllers/missions"
import SurveyMobile from "../../components/survey/SurveyMobile"
import SurveyDesktop from "../../components/survey/SurveyDesktop"
import MetaPixelEvent from "../../components/global/common/MetaPixelEvent"

const Survey = () => {
  const navigate = useNavigate()
  const { isMobile, currentMission, setCurrentMission } =
    useContext(MainContext)
  const { updateAll } = useContext(MissionsContext)

  // redirect to landing if currentMission isn't present
  useEffect(() => {
    if (!currentMission) {
      navigate("/")
    }
  }, [])

  // register google analytics event
  useEffect(() => {
    registerPageEvent("survey")
  }, [])

  // current form to complete
  const currentFormId = useMemo(() => {
    if (currentMission) {
      return currentMission.missionTypeId
    }

    return ""
  }, [currentMission])

  // mission completed feedback
  const [missionFeedbackOpen, setMissionFeedbackOpen] = useState<boolean>(false)

  // on typeform submit function
  const onTypeformSubmit = () => {
    registerEvent("survey_completed", {
      id: currentFormId,
    })

    // update current mission and show feedback
    setCurrentMission({
      ...currentMission!,
      current: 1,
    })
    setMissionFeedbackOpen(true)

    // update all
    updateAll(true)
  }

  return isMobile ? (
    <>
      <MetaPixelEvent page="Survey" />
      <SurveyMobile
        currentFormId={currentFormId}
        onTypeformSubmit={onTypeformSubmit}
        missionFeedbackOpen={missionFeedbackOpen}
      />
    </>
  ) : (
    <>
      <MetaPixelEvent page="Survey" />
      <SurveyDesktop
        currentFormId={currentFormId}
        onTypeformSubmit={onTypeformSubmit}
        missionFeedbackOpen={missionFeedbackOpen}
      />
    </>
  )
}

export default Survey
